import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { EmpresaSeleccionada } from 'src/models/empresaSeleccionada';
import { Usuario } from 'src/models/usuarios';
import { UiService } from '../api/ui.service';
import * as moment from 'moment';
import { Permisos } from 'src/models/marcador';
import { Router } from '@angular/router';
import { Preferences } from '@capacitor/preferences';
const  Storage  = Preferences;

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  token: string = null;
  usuario: Usuario = new Usuario();
  empresas: EmpresaSeleccionada[] = [];
  empresaSeleccionada: EmpresaSeleccionada = new EmpresaSeleccionada();
  color: string;
  sonido:string = 'cerdo.wav';
  permisos:Permisos=new Permisos();
  

  constructor(private uiServe:UiService,private router:Router) { }

  capitalize(word: string) {
    return word[0].toUpperCase() + word.slice(1);
  }

  minsToHHmm(mins: number): string {
    let HH: number = Math.trunc(mins / 60);
    let mm: number = mins % 60;
    return `${HH > 0 ? (HH < 10 ? `0${HH} h` : `${HH} h`) : ""} ${
      mm < 10 ? `0${mm}` : mm
    } min`;
  }

  minsToHHmmAlert(mins: any): string {
    let HH: number = Math.trunc(mins / 60);
    let mm: number = mins % 60;
    return `${HH > 0 ? `${HH} h y ` : ""} ${mm} min`;
  }

  guardarColor(color: any) {
    Storage.set({ key: 'color', value: color });
  }

  async cargarColor() {
    if (await Storage.get({ key: 'color' })) {
      this.color = (await Storage.get({ key: 'color' })).value;
    } else {
      this.color = 'primary';
    }
  }

  dinamicListYears(year){
    let years = [];
    for (let i = 0; i < 10; i++){
      if(i <1){
        let minYear = year - (i+1);
        let maxYear= year + (i+1);
        years.push(minYear,maxYear);
      } 
    }
    years.push(year);
    return years.sort().reverse();
  }

  comprobarUsuarioBaja(){
    if(!!this.usuario.fechaBaja) this.router.navigateByUrl(`/login`)
  }

  horasFichajeValidas(fichajes:any[],entrada: Date, salida: Date): Promise<boolean> {
    return new Promise(async (resolve) => {
 
      for (let i = 0; i < fichajes.length; i++) {
        let fichajeRem: { id: number, entrada: any, salida: Date } = fichajes[i];
        
        fichajeRem.entrada = new Date(fichajeRem.entrada);
        fichajeRem.salida =new Date(fichajeRem.salida);

       if(fichajeRem.salida.getFullYear() == 1970)fichajeRem.salida = null
        if (fichajeRem.salida == null && moment(entrada).isAfter(moment(fichajeRem.entrada))) {
        
          resolve(true);
        }
        if (moment(entrada).isAfter(moment(fichajeRem.entrada)) && moment(salida).isAfter(moment(fichajeRem.entrada))) {
      
           resolve(true);
         }

        if (moment(entrada).isAfter(moment(fichajeRem.entrada)) && moment(salida).isAfter(moment(fichajeRem.salida))) {

          resolve(true);
        }
      }
      resolve(false);
    });

  }
}
