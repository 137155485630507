import * as moment from "moment";

export interface Mes {
    mesNum: number,
    mesLetra: string,
    semanas: Semana[]
}
export class HorarioCab {
    titulo: string;
    id?: number;
    activo?: boolean;
    ejercicio?: number;
    idEmpresa?: number;
    idCentro?: number;
    nombre?: string;
    usuarios?: number;
    duracion?: number;
}

export interface FiltroHorarioEmpleado {
    idEmpleado: number;
    idCentro: number;
    fechaIni: Date;
    fechaFin: Date;
    idHorario: 0;
}


export interface Semana {
    semanaNum: number;
    dias: DiaCalendario[];
}

export class InfoDiaCalendario {
    fecha: string;
    data: any;

    constructor(fecha: string, data?: any) {
        this.fecha = moment(fecha).set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }).toISOString();
        this.data = data || {};
    }
}

export class DiaCalendario {
    fecha: string;
    data: DataDiaCalendario[];

    constructor(fecha?: string, data?: DataDiaCalendario[]) {
        this.fecha = moment(fecha).set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }).toISOString();
        this.data = data || [];
    }
}

export class DataDiaCalendario {
    idData: number;
    data: any;

    constructor() {
        this.idData = 0;
        this.data = [];
    }
}

export interface IndicesDia {
    dia: { fecha: string, data: any },
    indexMes: number,
    indexSemana: number,
    indexDia: number
}

export interface TipoAusencia {
    id: number;
    nombre: string;
    color: string;
    abreviatura: string;
    remunerada: boolean;
    idEmpresa: number;
    fechaAlta: string;
}

export class DetalleCalendario {
    color: string;
    estadoComunicacion: number;
    fecha: Date;
    horaFin: any
    horaInicio: any
    idCentro: number;
    idEmpleado: number;
    idHorario: number;
    nombre: string;
    tipodDeDia: string;

    constructor(){
        this.color=null;
        this.estadoComunicacion=null;
        this.fecha=null;
        this.horaFin=null;
        this.horaInicio=null;
        this.idCentro=null;
        this.idEmpleado=null;
        this.idHorario=null;
        this.nombre=null;
        this.tipodDeDia=null;
    }
}

export class DetallePermiso {
    id: number;
    fechaInicio: string;
    fechaFin: string;
    horaInicio?: any;
    horaFin?: any;
    idTipoNoTrabajo: number;
    idEmpleado: number;
    idEmpresa: number;
    duracion: number;
    diaCompleto: boolean;
    estadoComunicacion: number;
    idCentro: number;
    motivoSolicitud: string;
    motivoRechazoAceptacion: string;
    usuarioPermiso?: any;
    fechaSolicitud: string;
    ejercicio: number;
    fechaAceptadas?: any;
    fechaCanceladas?: any;
    fechaRechazadas?: any;
    idEmpleadoAcepta?: any;
    idEmpleadoRechaza?: any;
    idEmpleadoCancela?: any;
    nombreEmpleado: string;
    dias: string[];
    color: string;

    constructor(){
        this.id=null;
        this.fechaInicio=null;
        this.fechaFin=null;
        this.horaInicio=null;
        this.horaFin=null;
        this.idTipoNoTrabajo=null;
        this.idEmpleado=null;
        this.idEmpresa=null;
        this.duracion=null;
        this.diaCompleto=null;
        this.estadoComunicacion=null;
        this.idCentro=null;
        this.motivoSolicitud=null;
        this.motivoRechazoAceptacion=null;
        this.usuarioPermiso=null;
        this.fechaSolicitud=null;
        this.ejercicio=null;
        this.fechaAceptadas=null;
        this.fechaCanceladas=null;
        this.fechaRechazadas=null;
        this.idEmpleadoAcepta=null;
        this.idEmpleadoRechaza=null;
        this.idEmpleadoCancela=null;
        this.nombreEmpleado=null;
        this.dias=[];
        this.color=null;
    }
}