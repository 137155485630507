import { NgModule } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser'
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { PopinfoComponent } from './popinfo/popinfo.component';
import { AccionesHorarioComponent } from './acciones-horario/acciones-horario.component';
import { DetalleHorarioComponent } from './detalle-horario/detalle-horario.component';
import { FiltroHorarioComponent } from './filtro-horario/filtro-horario.component';
import {FiltroCambioturnoComponent} from './filtro-cambioturno/filtro-cambioturno.component'
import { CommonModule, DatePipe } from '@angular/common';
import { DetallePermisosComponent } from './detalle-permisos/detalle-permisos.component';
import { ModalSolicitudComponent } from './modal-solicitud/modal-solicitud.component';
import { DetalleNotiComponent } from './detalle-noti/detalle-noti.component';
import { AgmCoreModule } from '@agm/core';
import { MapaComponent } from './mapa/mapa.component';
import { FormsModule } from '@angular/forms';
import { DetalleDiaComponent } from './detalle-dia/detalle-dia.component';
import { ResumenValidacionComponent } from './resumen-validacion/resumen-validacion.component';
import { HorasValidadasComponent } from './horas-validadas/horas-validadas.component';
import { DocumentosComponent } from './documentos/documentos.component';
import { PipesModuleModule } from '../pipes/pipes-module.module';
import { AdjuntosComponent } from './adjuntos/adjuntos.component';
import { ImgComponent } from './img/img.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ModalTeletrabajocancelarComponent } from './modal-teletrabajocancelar/modal-teletrabajocancelar.component';



@NgModule({
  
  declarations: [
    PopinfoComponent,
    AccionesHorarioComponent,
    DetalleHorarioComponent,
    FiltroHorarioComponent,
    DetallePermisosComponent,
    ModalSolicitudComponent,
    FiltroCambioturnoComponent,
    DetalleNotiComponent,
    MapaComponent,
    DetalleDiaComponent,
    ResumenValidacionComponent,
    HorasValidadasComponent,
    DocumentosComponent,
    AdjuntosComponent,
    ImgComponent,
    ModalTeletrabajocancelarComponent
  ],
  exports: [
    PopinfoComponent,
    AccionesHorarioComponent,
    DetalleHorarioComponent,
    FiltroHorarioComponent,
    DetallePermisosComponent,
    ModalSolicitudComponent,
    FiltroCambioturnoComponent,
    DetalleNotiComponent,
    MapaComponent,
    DetalleDiaComponent,
    ResumenValidacionComponent,
    HorasValidadasComponent,
    DocumentosComponent,
    AdjuntosComponent,
    ImgComponent,
    ModalTeletrabajocancelarComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    BrowserModule,
    FormsModule,
    PipesModuleModule,
    PdfViewerModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDbC4R5K929l7dtNnj5ZoP-V2voLqCk5ww'
    })
  ],
  providers:[DatePipe]
})
export class ComponentsModule { }