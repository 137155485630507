import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
 
// Init for the web
import "@capacitor-community/firebase-analytics";
import { Plugins } from "@capacitor/core";
import { GlobalService } from './global.service';
import { Platform } from '@ionic/angular';
const { FirebaseAnalytics, Device } = Plugins;
 
@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  analyticsEnabled = true;
 
  constructor( private router: Router,private globalServe:GlobalService, private platform:Platform) {
  //  this.initFb();
    this.router.events.pipe(
      filter((e: RouterEvent) => e instanceof NavigationEnd),
    ).subscribe((e: RouterEvent) => {
      this.setScreenName(e.url)
    });
  }
  
 
  async initFb() {
    if ((await Device.getInfo()).platform == 'web') {
      FirebaseAnalytics.initializeFirebase(environment.firebase);
      this.setUser();
      this.setProperty();
      this.logEvent();
    }
    this.setUser();
    this.setProperty();
    this.logEvent();
  }
 
  setUser() {
    // Use Firebase Auth uid
    if (this.globalServe.token != null){
    }
    FirebaseAnalytics.setUserId({
      userId: this.globalServe.usuario.id
    });
  }
 
  setProperty() {
    if (this.platform.is('mobileweb') || this.platform.is('pwa')){
      FirebaseAnalytics.setUserProperty({
        name: "framework",
        value: "webapp",
      });
    } else if (this.platform.is('android')){
      FirebaseAnalytics.setUserProperty({
        name: "framework",
        value: "android",
      });
    }
  
  }
 
  logEvent() {
    FirebaseAnalytics.logEvent({
      name: "login",
      params: {
        method: "email"
      }
    });
  }
 

 
  toggleAnalytics() {
    this.analyticsEnabled = !this.analyticsEnabled;
    FirebaseAnalytics.setCollectionEnabled({
      enabled: this.analyticsEnabled,
    });    
  }

  setScreenName(screenName: string) {
    FirebaseAnalytics.setScreenName({ screenName, nameOverride: null })
      .then(() => console.log(`${screenName} tracked`))
      .catch((error) => console.error(error));
  }
  
}
