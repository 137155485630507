import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/api/api.service';
import { UiService } from 'src/app/api/ui.service';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-modal-teletrabajocancelar',
  templateUrl: './modal-teletrabajocancelar.component.html',
  styleUrls: ['./modal-teletrabajocancelar.component.scss'],
})
export class ModalTeletrabajocancelarComponent implements OnInit {
  @Input('solicitud') solicitud:any = null;
  solicitudNew:any = null
  desde:Date;
  hasta:Date;
  constructor(private apiService:ApiService, public globalService:GlobalService,public uiServe:UiService,private modalCtrl:ModalController,private datePipe:DatePipe) { }

  ngOnInit() {
    this.solicitudNew = {...this.solicitud};
  }

  cerrar(){
    this.modalCtrl.dismiss();
  }

  confirmar(){
    let dato = {
      idEmpleado:this.globalService.usuario.id,
      desde: this.datePipe.transform(this.desde,'yyyyMMdd'),
      hasta: this.datePipe.transform(this.hasta,'yyyyMMdd'),
    }
    this.apiService.cancelarTeletrabajo(dato).subscribe((resp:any)=>{
      if(resp.ok){
        this.uiServe.alertaInformativa(resp.msg);
        this.modalCtrl.dismiss({ok:true})
      }
    })
  }

}
