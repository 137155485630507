// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //para depurar

    urlArchivos: 'https://back.nivin.app/',  
    // url: 'https://apinivin.azurewebsites.net/v1',
   url: 'https://backpruebasnivin.devtresbits.es',
  //  urlArchivos: 'https://192.168.0.11:3000',

  // urlArchivos: 'http://localhost:3001/v1/' ,
  // url: 'https://back.nivin.app/v1/', 

  firebase: {
    apiKey: "AIzaSyDxA3Y-egXyV3zYD1hZGJGIUiYcwMbwRKI", 
    authDomain: "gesdocumentalnivin.firebaseapp.com",
    projectId: "gesdocumentalnivin",
    storageBucket: "gesdocumentalnivin.appspot.com",
    messagingSenderId: "43727171757",
    appId: "1:43727171757:web:3321e94c2e3e1d074fd624"
  }
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
