import { Component, HostListener, OnInit } from "@angular/core";
import { ModalController, Platform, PopoverController } from "@ionic/angular";
import { UiService } from "src/app/api/ui.service";
import { AltaMasivaPage } from 'src/app/pages/usuarios/alta-masiva/alta-masiva.page';
import { BackButtonService } from 'src/app/api/back-button.service';
import { GlobalService } from "src/app/services/global.service";
import { PerfilAdminPage } from "src/app/pages/usuarios/perfil-admin/perfil-admin.page";
import { ApiService } from "src/app/api/api.service";

@Component({
  selector: "app-popinfo",
  templateUrl: "./popinfo.component.html",
  styleUrls: ["./popinfo.component.scss"],
})
export class PopinfoComponent implements OnInit {

  anotherModalOpen: boolean = false;

  constructor(private globalService: GlobalService,
    private popoverCtrl: PopoverController,
    public uiServe: UiService,
    public modalCtrl: ModalController,
    private apiService: ApiService,
    private backButtonServe: BackButtonService,
    private platform: Platform
  ) { }

  ngOnInit() {
    const modalState = {
      modal: true,
      desc: 'fake state for our modal'
    };
    history.pushState(modalState, null);
  }

  ionViewDidEnter() {
    this.backButtonServe.subscription = this.platform.backButton.subscribeWithPriority(5, () => {
      this.popoverCtrl.dismiss();
      this.backButtonServe.subscription.unsubscribe();
    });
  }

  async addUsuario() {
    this.uiServe.modal = await this.modalCtrl.create({
      component: PerfilAdminPage,
      cssClass: "full-screen",
      componentProps: {
        isPage: false,
      },
    });
    this.uiServe.modal.onDidDismiss().then(() => {
      setTimeout(() => {
        this.anotherModalOpen = false;
      }, 500);
      this.getUsuarios(this.globalService.empresaSeleccionada.id);
    });
    this.anotherModalOpen = true;
    this.uiServe.modal.present();
  }

  async getUsuarios(idEmpresa: number) {
    let allUsers = (await this.apiService.getUsuariosEmpresa()).usuarios;
    this.apiService.usuariosEmpresaPendientesValidar = (await this.apiService.getUsuariosEmpresaPendientesValidar()).usuarios;
    this.apiService.usuariosEmpresa = [];
    this.apiService.usuariosNoActivos = [];
    allUsers.map((usuario) => {
      if (usuario.activo) {
        this.apiService.usuariosEmpresa.push(usuario);
      } else {
        this.apiService.usuariosNoActivos.push(usuario);
      }
    });
    this.apiService.usuariosEmpresaParaFiltrar = this.apiService.usuariosEmpresa;
    this.apiService.usuariosEmpresaPendientesValidarParaFiltrar = this.apiService.usuariosEmpresaPendientesValidar;
    this.apiService.usuariosNoActivosParaFiltrar = this.apiService.usuariosNoActivos;

  }

  ngOnDestroy() {
    if (window.history.state.modal) {
      history.back();
    }
  }

  @HostListener('window:popstate', ['$event'])
  cerrar() {
    if (!this.anotherModalOpen) {
      this.popoverCtrl.dismiss();
    }
  }

  async abrirModal() {

    this.backButtonServe.subscription.unsubscribe();
    this.uiServe.modal = await this.modalCtrl.create({
      component: AltaMasivaPage,
      cssClass: 'modalAlert',
      backdropDismiss: false,
    });

    this.uiServe.modal.onDidDismiss().then(() => {
      setTimeout(() => {
        this.anotherModalOpen = false;
      }, 500);
    });
    this.anotherModalOpen = true;
    this.uiServe.modal.present();
  }

}

