import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { Capacitor, Plugins } from "@capacitor/core";
import { SeleccionEmpresaPage } from "./seleccion-empresa/seleccion-empresa.page";
import {AlertController,LoadingController,ModalController,Platform,} from "@ionic/angular";
import { GlobalService } from "../services/global.service";
import { PushService } from "../api/push.service";
import { UiService } from "../api/ui.service";
import { ErrorService } from "../services/error.service";
import { AlarmasService } from "../pages/alarmas/alarmas.service";
import { EmpresaSeleccionada } from "src/models/empresaSeleccionada";
import { Usuario } from "src/models/usuarios";
import { DatePipe } from "@angular/common";
import { ApiService } from "../api/api.service";
import { Preferences } from '@capacitor/preferences';
const Storage = Preferences
import { Device } from '@capacitor/device'
import { App } from '@capacitor/app';
@Injectable({
  providedIn: "root",
})
export class LoginService {
  apiRest: String = environment.url;
  token: string;
  plataformas: string = "";
  version: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    private modalCtrl: ModalController,
    private globalService: GlobalService,
    private pushService: PushService,
    private platform: Platform,
    private uiServe: UiService,
    private errorService: ErrorService,
    private loadingController: LoadingController,
    private alarmasService: AlarmasService,
    private apiService:ApiService,
    private alertController: AlertController,
    private datePipe:DatePipe
  ) {
    this.plataformas = this.platform.platforms().toString();
  }

  async ponerEncabezados() {
    return new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.globalService.token,
    });
  }

  async login(email: string,pass: string): Promise<{ok: boolean;access_token: string;usuario: any;UPV: boolean;empresas: any[];mensaje?: string;}> {
    let body;
    console.log('Login', (Capacitor.isNative && !this.platform.is("pwa") && !this.platform.is("mobileweb")));
    
    if (Capacitor.isNativePlatform() && !this.platform.is("pwa") && !this.platform.is("mobileweb")) {
      const idOneSignal = await this.pushService.getUserIdOneSignal().catch();
      //const datosDispositivo: any = await this.pushService.recogerModeloDispositivo(idOneSignal); 
      console.log('Entra');
      

      body = {
        idOneSignal: idOneSignal,
        idSesion: idOneSignal,
        //device: datosDispositivo.device_model,
        plataformas: this.plataformas,
        nativo: Capacitor.isNativePlatform(),
        email: email,
        pass: pass,
      };
    } else {
      body = { email: email, pass: pass };
    }

    const resp:any = await this.http.post(`${this.apiRest}/login`, body).toPromise().catch(async (error)=>{
      return await this.errorService.getError(error)
    })
    if(!!resp) return resp    
  }

  async validar(email: string,passNueva: string): Promise<{ok: boolean;access_token: string;usuario: any;UPV: boolean;empresas: any[];}> {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${this.apiRest}/empresas/Validar`,{ email: email, pass: passNueva },{ headers: await this.ponerEncabezados() }).subscribe((resp: any) => {resolve(resp);},
          (error) => {
            this.errorService.getError(error).then((tryAgain: boolean) => {
              reject(tryAgain);
            });
          });
    });
  }

  async getAlarmasParaEnviar() {
    //await this.alarmasService.eliminarAlarmas();
    this.alarmasService.obtenerAlarmas().then(async (data) => {
      let jornada = JSON.parse((await Storage.get({ key: "nivin_jornada" })).value);
      if (!!jornada) {
        if (!jornada.jornadaFinalizada) {
          const fecha = new Date();
          const dia = fecha.getDay();
          data.alarmas[dia - 1].alarmas.splice(
          data.alarmas[dia - 1].alarmas.indexOf(
          data.alarmas[dia - 1].alarmas.find((key) => key.recordatorio == "Entrada")),1);
        }
      }
      const alarmas = this.alarmasService.ordenarArray(data);
      const alarmasActivas = alarmas.filter((key) => key.activa == true);
      await this.alarmasService.crearAlarmasDeTodaLaSemana(alarmasActivas);
    });
  }

  async abrirModalSeleccionarEmpresa(loginInfo: any) {
    this.uiServe.modal = await this.modalCtrl.create({
      component: SeleccionEmpresaPage,
      cssClass: "modalAlert",
      componentProps: {
        empresas: loginInfo.empresas,
      },
      backdropDismiss: false,
    });
    this.uiServe.modal.onDidDismiss().then((data) => {
      if (data.data) {
        this.getTokenUsuarioEmpresa(data.data.empresa.id).then(() => {
          this.router.navigateByUrl(this.router.url.includes('cambio')?"pages/home":"pages/home/cambio");
        });
      } else {
        this.uiServe.alertaInformativa("Tienes que seleccionar la empresa a la que quieres acceder.");
      }
    });
    this.uiServe.modal.present();
  }

  setToken(access_token: string): Promise<boolean> {
    return new Promise(async (resolve) => {
      Storage.set({ key: "token", value: "Bearer " + access_token });
      this.globalService.token = "Bearer " + access_token;
      resolve(true);
    });
  }

  async comprobarVersion() {
    if (this.platform.is("android")) {
      this.version =  (await App.getInfo()).version;
      
      this.apiService.comprobarVersion(this.version).then(async (resp: any) => {
        if (resp.obligadoActualizar == true) {
          const alert = await this.alertController.create({
            cssClass: "my-custom-class",
            header: "Actualización pendiente",
            message: `Hay una nueva version de la app, para continuar usándola necesita actualizar`,
            buttons: [
              {
                text: "Cancelar",
                role: "cancel",
                cssClass: "secondary",
                handler: () => {
                  this.alertController.dismiss();
                },
              },
              {
                text: "Actualizar",
                handler: () => {
                  window.open(`https://play.google.com/store/apps/details?id=com.nivin.com`);
                  navigator["app"].exitApp();
                },
              },
            ],
          });
          await alert.present();
        }
      });
    }
  }

  async getTareas(id){
    let dato = {
      idEmpleados: [id],
      fechaInicio: this.datePipe.transform(new Date(Date.now()), "yyyy-MM-dd"),
      fechaFin: this.datePipe.transform(new Date(Date.now()), "yyyy-MM-dd"),
    };
    this.apiService.getTareasEmpleados(dato).subscribe((resp: any) => {
      this.uiServe.tareas = resp.empleados;
    },error=>this.uiServe.tareas=[]);
  }

  async setStorageAndGlobal(usuario: any,empresas: any[],empresa: any,jornadaActual: any,jornadaPorFinalizar: any, jornadasPendientes:any,permisos?: any,): Promise<boolean> {
    return new Promise(async (resolve) => {
      if (empresa.color == null) {
        empresa.color = "primary";
      }
      Storage.set({
        key: "empresaSeleccionada",
        value: JSON.stringify(empresa),
      });
      if (!!permisos) {
        Storage.set({ key: "nivin_permisos", value: JSON.stringify(permisos) });
        this.globalService.permisos = permisos;
      }
      this.globalService.empresaSeleccionada = empresa;
      this.uiServe.tituloTiempo = this.globalService.empresaSeleccionada.trabajarConTiempoAsignado? "previsto": "total";
      this.uiServe.colorAplicacion = empresa.color;
      this.uiServe.secondColor(empresa.color);
      this.uiServe.setearEstiloMenu(this.uiServe.menu, this.uiServe.items);
      Storage.set({ key: "usuario", value: JSON.stringify(usuario) });
      this.globalService.usuario = usuario;
      // if (Capacitor.isNative && !this.platform.is("pwa") && !this.platform.is("mobileweb")) {
      //   if (this.globalService.usuario.idOneSignal != (await this.pushService.getUserIdOneSignal())) {
      //     this.uiServe.alertaInformativa("Se ha iniciado sesión desde otro dispositivo, vuelva a iniciar sesión");
      //     return this.router.navigateByUrl("/login");
      //   }
      // }
      Storage.set({ key: "empresas", value: JSON.stringify(empresas) });
      this.globalService.empresas = empresas;
      if (jornadaActual.ok) {
        Storage.set({key: "nivin_jornada",value: JSON.stringify(jornadaActual.jornada),});
        Storage.set({key: "nivin_ultimoFichaje",value: JSON.stringify(jornadaActual.ultimoFichaje),});
      } else {
        Storage.remove({ key: "nivin_jornada" });
        Storage.remove({ key: "nivin_ultimoFichaje" });
      }
      if (jornadaPorFinalizar.ok) {
        Storage.set({key: "nivin_jornadaPorFinalizar",value: JSON.stringify(jornadaPorFinalizar.jornada),});
        Storage.set({key: "nivin_ultimoFichajePorFinalizar",value: JSON.stringify(jornadaPorFinalizar.ultimoFichaje),});
      } else {
        Storage.remove({ key: "nivin_jornadaPorFinalizar" });
        Storage.remove({ key: "nivin_ultimoFichajePorFinalizar" });
      }      
      await Storage.set({key: "nivin_jornadasPendientes",value: JSON.stringify(jornadasPendientes)})
      resolve(true);
    });
  }

  async comprobarTokenStorage(): Promise<boolean> {
    this.globalService.token = (await Storage.get({ key: "token" })).value;
    if (this.globalService.token != null) {
      const loading = await this.loadingController.create({
        cssClass: "loadingObteniendoJornadas",
        message: "Cargando...",
        backdropDismiss: false,
      });
      await loading.present();
      let renovado = await this.renovarInfoConToken();
      if (renovado.ok == false && renovado.error) {
        if (renovado.error.status == 401) {
          renovado = await this.renovarInfoConToken();
        }
      }
      loading.dismiss();
      return renovado.ok;
    } else {
      Storage.clear();
      this.router.navigateByUrl("/login");
      return false;
    }
  }

  async renovarInfoConToken(): Promise<{ ok: boolean; error?: any }> {
    let body: any = {};

    
    if(!!this.globalService && this.globalService.token > ''){
      console.log('ENTRA1');
      
      return new Promise(async (resolve, reject) => {
        if (Capacitor.isNativePlatform() && !this.platform.is("pwa") && !this.platform.is("mobileweb")) {
          console.log('ENTRA2');
          body.idSesion = await this.pushService.getUserIdOneSignal().catch();
          body.nativo = true
          console.log('ENTRA3', body);
        }
        this.http.post(`${this.apiRest}/renovarInfoConToken`, body, { headers: {"Content-Type": "application/json",Authorization: this.globalService.token,},})
        .subscribe(async (resp: any) => {
              if (resp.ok == true) {
                Storage.get({ key: "nivin_permisos" }).then((data: any) => {
                  this.globalService.permisos = JSON.parse(data.value);
                  if (this.globalService.permisos.queVer.includes("Solicitudes")) {
                    this.apiService.getSolicitudesPorResponsable(resp.usuario.id,0,0,40,"TODO","").then((resp: any) => {
                      if (resp.ok) this.apiService.totalSolicitudesPendientes =resp.solicitudes.length;
                    });
                    this.apiService.getSolicitudesPorResponsable(resp.usuario.id,0,0,40,"INC","").then((resp: any) => {
                        if (resp.ok)this.apiService.totalIncidenciasPendientes =resp.solicitudes.length;
                    });
                  }
                });
                await this.setToken(resp.access_token);
                Storage.get({ key: "empresaSeleccionada" }).then((dataStorage) => {resp.empresas.forEach(async(empresa) => {
                      if (empresa.id === JSON.parse(dataStorage.value).id) {
                        this.setStorageAndGlobal(resp.usuario,resp.empresas,empresa,resp.infoJornadaActual,resp.infoJornadaPorFinalizar, resp.jornadasPendientes);
                        this.globalService.comprobarUsuarioBaja();
                        this.uiServe.colorAplicacion = empresa.color;
                        this.uiServe.secondColor(empresa.color);
                        this.uiServe.setearEstiloMenu(this.uiServe.menu,this.uiServe.items);
                      }
                      if(empresa.permiteTareas){
                        await this.getTareas(resp.usuario.id);
                      }
                      if (empresa.alarmasActivas == true) {
                    //    this.getAlarmasParaEnviar();
                      } else {
                        //this.alarmasService.eliminarAlarmas();
                      }
                    });
                  });
                if (Capacitor.isNativePlatform() && !this.platform.is("pwa") && !this.platform.is("mobileweb")) {
                  const idOneSignal = await this.pushService.getUserIdOneSignal().catch();
                  let datos = await Device.getInfo();
                  const version = (await App.getInfo()).version
                  body = {
                    idOneSignal: idOneSignal,
                    idDispositivo: idOneSignal,
                    device: datos.model,
                    version: version,
                    plataforma: datos.operatingSystem,
                    idUsuarioEmail: resp.usuario.id,
                    fechaUltimoAcceso: new Date(Date.now()),
                  };
                  this.http.post(`${this.apiRest}/registerDevice`, body, {headers: {"Content-Type": "application/json",Authorization: this.globalService.token,}})
                  .subscribe((resp: any) => {},(error) => {
                        this.errorService.getError(error).then((tryAgain: boolean) => {
                          reject(tryAgain);
                        });
                      });
                }
                await this.comprobarVersion();
                resolve({ ok: true });
              } else {
                this.uiServe.alertaInformativa(resp.mensaje);
                Storage.clear();
                this.globalService.empresaSeleccionada =new EmpresaSeleccionada();
                this.globalService.usuario = new Usuario();
                this.globalService.usuario.urlImagenPerfil = null;
                this.globalService.empresas = [];
                this.globalService.token = null;
                this.uiServe.colorAplicacion = "primary";
                this.uiServe.colorAplicacionSecond = "secondary";
                this.router.navigateByUrl("login");
                resolve({ ok: false });
              }
            },
            (error) => {
              this.errorService.getError(error).then(async (tryAgain: boolean) => {
                  if (tryAgain) {
                    resolve({ ok: false, error });
                  } else {
                    resolve({ ok: false });
                  }
              });
            });
      });    
    }
  }

  async getTokenUsuarioEmpresa(idEmpresa: number): Promise<boolean> {
    let body: any = null;
    console.log('Capacitor',Capacitor, 'Platform', this.platform.is("pwa"),this.platform.is("mobileweb"), idEmpresa);
    
    if (Capacitor.isNativePlatform()) {
      const idOneSignal = await this.pushService.getUserIdOneSignal();
      //const datosDispositivo: any = await this.pushService.recogerModeloDispositivo(idOneSignal);
      if(idOneSignal > '') {
        body = {
          idOneSignal: idOneSignal,
          idSesion: idOneSignal,
          //device: datosDispositivo.device_model,
          plataformas: this.plataformas,
          nativo: Capacitor.isNativePlatform(),
        };
      }
    }
    console.log('2', body, `${this.apiRest}/renovarTokenEmpresa/${idEmpresa}`);

    const resp:any = await this.http.post(`${this.apiRest}/renovarTokenEmpresa/${idEmpresa}`, body, {headers: {"Content-Type": "application/json",Authorization: this.globalService.token,}}).toPromise().catch(
      (error) => {
        this.errorService.getError(error).then(async (tryAgain: boolean) => {
            if (tryAgain) {
              this.getTokenUsuarioEmpresa(idEmpresa).then(() => {
                this.router.navigateByUrl("pages/home");
              });
            } else {
              return false;
            }
        });
      })


      if (resp.ok) {
        await this.setToken(resp.access_token);
        resp.empresas.forEach((empresa) => {
          if (empresa.id === idEmpresa) {
            this.setStorageAndGlobal(resp.usuario,resp.empresas,empresa,resp.infoJornadaActual,resp.infoJornadaPorFinalizar,resp.jornadasPendientes,resp.permisos);
            this.globalService.comprobarUsuarioBaja();
            this.uiServe.colorAplicacion = empresa.color;
            this.uiServe.secondColor(empresa.color);
            this.uiServe.setearEstiloMenu(this.uiServe.menu,this.uiServe.items);
            if (this.globalService.permisos.queVer.includes("Solicitudes")) {
              this.apiService
                .getSolicitudesPorResponsable(resp.usuario.id,0,0,100,"TODO","")
                .then((resp: any) => {
                  this.apiService.totalSolicitudesPendientes =resp.solicitudes.length;
                });
              this.apiService
                .getSolicitudesPorResponsable(resp.usuario.id,0,0,40,"INC","").then((resp: any) => {
                  if (resp.ok) this.apiService.totalIncidenciasPendientes = resp.solicitudes.length;
                });
            }
          }
        });
        return true;
      } else {
        if(!!resp && !!resp.msg)this.uiServe.alertaInformativa(resp.msg);
        if(!!resp && !!resp.mensaje)this.uiServe.alertaInformativa(resp.mensaje);
        
        return false;
      }
    }   
}
