import { Component, OnInit } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-adjuntos',
  templateUrl: './adjuntos.component.html',
  styleUrls: ['./adjuntos.component.scss'],
})
export class AdjuntosComponent implements OnInit {
  constructor(
    private popoverCtrl: PopoverController,
    public modalCtrl: ModalController,
  ) { }

  ngOnInit() {
    const modalState = {
      modal: true,
      desc: 'fake state for our modal'
    };
    history.pushState(modalState, null);
  }

  abrir(param: string) {
    this.popoverCtrl.dismiss({ abrir: param });
  }
}
