import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GlobalService } from './global.service';
import { finalize } from 'rxjs/operators';
//Firebase//
import { AngularFireStorage } from '@angular/fire/storage';
import { Capacitor } from '@capacitor/core';

export type rutaType = {rutaRelativa:string,rutaCompleta:string, nombreInterno:string, existeDocOrigen:boolean} 

export interface FileDataFirebase{
  idOrigen?: number;
  idEmpresa?:number;
  idEmpleado?:number;
  tipo?:string;
  idTipoDocumento?:string;
  idUsuarioCreaDocumento?:number;
  extension?: string;
  nombre?: string;
  fechaSubida?:Date;
  peso?: number;
  idCarpeta?: number;
  ruta?:string;
  fisico?: true;
  nombreInterno: string;
  rutaRelativa:string;        
}
export class FileUpload {
  key: string;
  name: string;
  url: string;
  file: File;

  constructor(file: File) {
    this.file = file;
  }
}
export class Documento {
  constructor(        
          public id?:                     number,
          public idCarpeta?:              number,
          public tipo?:                   string,
          public idOrigen?:               number | null,
          public peso?:                   number,
          public nombre?:                 string,
          public extension?:              string,
          public base?:                   null,
          public idEmpleado?:             number | null,
          public idEmpresa?:              number,
          public fechaSubida?:            string,
          public ruta?:                   null | string,
          public fisico?:                 number | null,
          public nombreInterno?:          null | string,
          public rutaRelativa?:           null | string,
          public idUsuarioCreaDocumento?: number | null,
          public idTipoDocumento?:        number | null,
          public firmado?:                number | null,
      
  ){}

}

@Injectable({
  providedIn: 'root'
})
export class DocumentosService {
  apiRest: string = environment.url+'/';
  constructor(private httpClient: HttpClient,
    private storage: AngularFireStorage,  
    private globalService: GlobalService) { }

  ponerEncabezados() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.globalService.token
    });
  }

  ponerEncabezadosFiles() {
    return new HttpHeaders({      
      Authorization: this.globalService.token
    });
  }

  getDirectorioPorOrden(orden, idMaestro?) {
    if(!idMaestro) idMaestro = 0
    return this.httpClient.get(`${this.apiRest}documentos/getDirectorioPorOrden/false/${orden}/${idMaestro}`, { headers: this.ponerEncabezados() });
  }
  getDocumentosDirectorioSimple(datos: any) {
    return this.httpClient.post(`${this.apiRest}documentos/getDocumentosDirectorioSimple/false`, datos, { headers: this.ponerEncabezados() });
  }
  getDocumentosDirectorioSimpleYOrigen(datos: any) {
    return this.httpClient.post(`${this.apiRest}documentos/getDocumentosDirectorioSimpleYOrigen/false`, datos, { headers: this.ponerEncabezados() });
  }
  getDocumento(id) {
    return this.httpClient.get(`${this.apiRest}documentos/getDocumento/false/${id}`, { headers: this.ponerEncabezados() });
  }
  setDocumento(formData: any) {
    
    return this.httpClient.post(`${this.apiRest}documentos/setDocumento/false`, formData, { headers: this.ponerEncabezadosFiles() });
  }
  mostrarOrigenDatos(tipo,id) {
    return this.httpClient.get(`${this.apiRest}documentos/mostrarOrigenDatos/false/${tipo}/${id}`, { headers: this.ponerEncabezados() });
  }
  getDirectorioPorNombre(nombre) {
    return this.httpClient.get(`${this.apiRest}documentos/getDirectorioPorNombre/false/${nombre}`, { headers: this.ponerEncabezados() });
  }
  getModelo() {
    return this.httpClient.get(`${this.apiRest}documentos/getModelo`, { headers: this.ponerEncabezados() });
  }
  eliminarDoc(id) {
    if(id > 1000000) id = id - 1000000;    
    this.eliminarFirebase(id)  
    return this.httpClient.get(`${this.apiRest}documentos/eliminarDoc/false/${id}`, { headers: this.ponerEncabezados() });
  }


  ///////////////NUEVO FIREBASE/////////////////////
  getPublicUrl(id) {
    return this.httpClient.get(`${this.apiRest}documentos/getPublicUrl/${id}`, { headers: this.ponerEncabezados() });
  }
  setDocumentoFirebase(data){
    return this.httpClient.post(`${this.apiRest}documentos/setDocumentoFirebase/true`, data, { headers: this.ponerEncabezados() });
  } 
  buscaDocumentoOrigen(idCarpeta, idOrigen) {
    return this.httpClient.get(`${this.apiRest}documentos/buscaDocumentoOrigen/${idCarpeta}/${idOrigen}`, { headers: this.ponerEncabezados() });
  }
  getRutaFirebase(data) { 
    return this.httpClient.post(`${this.apiRest}documentos/getRutaFirebase`, data, { headers: this.ponerEncabezados() });
  }
 
  insertarTicket2(infoTicket: any){
    return this.httpClient.post(`${this.apiRest}tickets/insertarTicketNuevo`, infoTicket, { headers: this.ponerEncabezados() });
  }
  insertarTicket3(infoTicket: any){
    return this.httpClient.post(`${this.apiRest}tickets/insertarTicketMundoMarino`, infoTicket, { headers: this.ponerEncabezados() });
  }

  async newSubirDoc(carpeta,fichero, ficheroNombre,idOrigen,idTipoDocumento){
    console.log('Fichero',fichero);
    
    const rutaT:rutaType = await this.generaPathFirebase(idOrigen, carpeta.id, !!Capacitor.isNativePlatform() ? fichero.type.type : fichero.type)    
    const urlFichero:string = await this.subirAfirebase(new FileUpload(fichero),rutaT)      
    if(!urlFichero) return {ok:false, msg:`No se pudo subir el documento, intentelo de nuevo mas tarde`}

    return new Promise(async(resolve)=>{

      const data:FileDataFirebase = {
        tipo:carpeta.nombreCarpeta.toUpperCase(), 
        idOrigen:idOrigen,  
        idEmpresa:this.globalService.empresaSeleccionada.id, 
        idEmpleado: this.globalService.usuario.id, 
        extension:  !!Capacitor.isNativePlatform() ? fichero.type.type : fichero.type,
        nombre: !!ficheroNombre ? ficheroNombre : !!Capacitor.isNativePlatform() ? 'takePhoto' : fichero.name, 
        idTipoDocumento:idTipoDocumento,
        idCarpeta: carpeta.id,
        fechaSubida: new Date(),
        nombreInterno: rutaT.nombreInterno,
        rutaRelativa: rutaT.rutaRelativa,
        ruta: urlFichero,        
        fisico:true,
        peso: fichero.size,    
      }
      resolve(await this.setDocumentoFirebase(data).toPromise())     
    })
  }
  async generaPathFirebase(idOrigen, idCarpeta, extension):Promise<rutaType>{
    //Antes de subir el documento tenemos que ver si es un documento de idOrigen y si lo es, si ya tiene documento, en ese caso se reemplaza    
    
    const {documento:documentoOrigen} = idOrigen > 0 ? <{ok:boolean, documento:Documento, error?}>await this.buscaDocumentoOrigen(idCarpeta,idOrigen).toPromise() : {documento:null}      
    if(!!documentoOrigen){
      return {rutaRelativa: documentoOrigen.rutaRelativa,rutaCompleta:`${documentoOrigen.rutaRelativa}/${documentoOrigen.nombreInterno}`, nombreInterno:documentoOrigen.nombreInterno,existeDocOrigen:true} 
    }else{
      const ruta= await this.getRutaFirebase({idCarpeta:idCarpeta, extension}).toPromise() as rutaType            
      return {rutaRelativa: ruta.rutaRelativa,rutaCompleta:`${ruta.rutaRelativa}/${ruta.nombreInterno}`, nombreInterno:ruta.nombreInterno,existeDocOrigen:false} 
    }
  }
  //Tarea para subir archivo
  async subirAfirebase(fileUpload: FileUpload, ruta:rutaType){    
    let urlFichero:string=''
    try {      
      const storageRef = this.storage.ref(ruta.rutaCompleta);
      const uploadTask = this.storage.upload(ruta.rutaCompleta, fileUpload.file);
  
      urlFichero = await new Promise(async (resolve)=>{
        await uploadTask.snapshotChanges().pipe(
          finalize(async() =>  resolve(await storageRef.getDownloadURL().toPromise()))
        ).toPromise();
      })
      return urlFichero
    } catch (error) {
      return urlFichero
    }
  }

  async eliminarFirebase(id){
    if(id > 1000000) id = id - 1000000
    const {documento} = await this.getDocumentoPromesa(id)   

    try {
      const storageRef = this.storage.ref(`${documento.rutaRelativa}/${documento.nombreInterno}`).delete();      
      return true;
    } catch (error) {
      return false;
    }   
  }

  async getDocumentoPromesa(id):Promise<{documento:Documento }>{    
    if(id > 1000000) id = id - 1000000
    const {documento} =  <{ok:boolean, documento:Documento}>await this.getDocumento(id).toPromise()     
    return{documento }
  }

  async getUrlDescargaFirebase(id):Promise<{ruta:string }>{
    if(id > 1000000) id = id - 1000000
    const {documento} =  <{ok:boolean, documento:Documento}>await this.getDocumento(id).toPromise() 
    return{ruta:documento.ruta }
  }

}


