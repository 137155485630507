import { Component, Input, OnInit } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { ModalController } from '@ionic/angular';
import { UiService } from 'src/app/api/ui.service';
import { GlobalService } from 'src/app/services/global.service';
import { Preferences } from '@capacitor/preferences';
const  Storage  = Preferences;

@Component({
  selector: 'app-seleccion-empresa',
  templateUrl: './seleccion-empresa.page.html',
  styleUrls: ['./seleccion-empresa.page.scss'],
})
export class SeleccionEmpresaPage implements OnInit {

  @Input() empresas = [];
  idEmpresaSeleccionada: any = null;

  constructor(private modalController: ModalController,
    public uiServe: UiService) { }

  ngOnInit() { }

  selectEmpresa() {
    if (this.idEmpresaSeleccionada != null) {
      this.empresas.forEach(empresa => {
        if (empresa.id == this.idEmpresaSeleccionada) {
          this.modalController.dismiss({ empresa: empresa });
        }
      });
    }
  }

}
