export class EmpresaSeleccionada {

    id: number;
    nombre: string;
    cif: string;
    requerirUbicacion: boolean;
    contactoUsuarios: boolean;
    proyectosActivados: boolean;
    idUsuarioEmpresa: number;
    rol: string;
    recursosHumanos: boolean;
    jefeDepartamento: boolean;
    alarmasActivas: boolean;
    cargo: string;
    idDepartamento: number;
    nombreDepartamento: string;
    telefono: string;
    porDefecto: boolean;
    activo: boolean;
    coordenadas: string;
    color: string;
    colorObligatorio: boolean;
    trabajarConTiempoAsignado: boolean;
    trabajarConGastos: boolean;
    recordatoriosAutomaticos: boolean;
    tiempoAntelacionRecoAuto: number;
    idCentro: number;
    nombreUsuario:string;
    diasMaximoModFichajes:number;
    urlSincronizacionERP:string;
    permiteTareas:boolean;
    periodoCompensacion:number;
    ajusteSemanal:any;
    permiteGestionDocumental:boolean;
    permiteHorarios:boolean;
    permiteFichajes:boolean;
    diaMaxVacaciones:number;
    mesMaxVacaciones:number;

    constructor() {
        this.nombre = "";
        this.trabajarConGastos = null;
        this.permiteHorarios=true;
        this.permiteFichajes=true;
    }

}