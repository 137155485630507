export class Usuario {

    id: number;
    dni: string;
    numSegSocial: string;
    email: string;
    telefono: string;
    nombre: string;
    idDispositivoActivo: string;
    idOneSignal: string;
    fechaAlta: Date;
    fechaUltimaModificacion: Date;
    activo: boolean;
    saltarTutorial: boolean;
    politicasAceptadas: boolean;
    urlImagenPerfil: string;
    fechaBaja:any;
}