import { Injectable } from '@angular/core';
import { CanActivate, CanLoad } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '../login/login.service';
import { GlobalService } from '../services/global.service';
import { Plugins, } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
const  Storage  = Preferences;
@Injectable({
  providedIn: 'root'
})
export class UsuarioGuard implements CanActivate {

  constructor(private loginService: LoginService, private globalService: GlobalService) { }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.loginService.comprobarTokenStorage();
    /* return new Promise(async (resolve, reject) => {
      Storage.get({ key: 'token' }).then((data:any)=> {
        this.globalService.token = data.value;
  
        if (this.globalService.token != null) {
          resolve(true); 
        }else{
          resolve(false);
        }
      });
    }); */
  }

}