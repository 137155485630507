import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import { ModalController } from '@ionic/angular';
import * as moment from 'moment';
import { ApiService } from 'src/app/api/api.service';
import { UiService } from 'src/app/api/ui.service';


@Component({
  selector: 'app-mapa',
  templateUrl: './mapa.component.html',
  styleUrls: ['./mapa.component.scss'],
})
export class MapaComponent implements OnInit {
  @Input('tarea') tarea:any = null;
  @Input('tareas') tareas:any[] = [];
  @Input('editar') editar:boolean = false;
  latitud:number =null;
  longitud:number = null;
  mostrarMapa:boolean=false;
  minHora:string;
  constructor(public uiServe:UiService,private modalCtrl:ModalController,private apiService:ApiService,private datePipe:DatePipe) { }

  ngOnInit() {
    if(this.editar){
      this.tarea.inicioEmp =!!this.tarea.inicioEmp? new Date(this.tarea.inicioEmp).toISOString():!!this.tarea.inicioEmp;
      this.tarea.finEmp = !!this.tarea.finEmp?new Date(this.tarea.finEmp).toISOString():this.tarea.finEmp;
      this.minHora = this.datePipe.transform(this.tarea.inicioEmp,'HH:mm')
    }
    this.latitud = parseFloat(this.tarea.coordenadasTarea.split(',')[0]);
    this.longitud= parseFloat(this.tarea.coordenadasTarea.split(',')[1]);
    setTimeout(() => {
      this.mostrarMapa= true;
    }, 300);
  }
  cerrar(){
    this.modalCtrl.dismiss();
  }
  cambiaHora(){
    this.minHora = this.datePipe.transform(this.tarea.inicioEmp, 'HH:mm');
    if (this.datePipe.transform(this.tarea.inicioEmp, 'HH:mm') > this.datePipe.transform(this.tarea.finEmp, 'HH:mm'))this.tarea.finEmp = this.tarea.inicioEmp;
  }

  editarHoras(){
    this.apiService.modTareaEmpleado({id:this.tarea.id,finEmp:this.tarea.finEmp,inicioEmp:this.tarea.inicioEmp,fecha:moment(this.tarea.fecha).endOf('day').toDate()}).subscribe(
      (resp: any) => {
        if (!resp.ok) {
          this.uiServe.alertaInformativa(!!resp.msg?resp.msg:'No se ha podido realizar la acción')
        }else{
          this.uiServe.alertaInformativa(!!resp.msg?resp.msg:'Acción realizada con exito');
          this.cerrar();
        }
      },
      (error) => (this.uiServe.alertaInformativa('Ocurrío un error, consulte con su administrador'))
    );
  }



}
