import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ChangeDetectorRef, Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { ErrorService } from '../services/error.service';
import { GlobalService } from '../services/global.service';
import { DataDiaCalendario, DiaCalendario, IndicesDia, InfoDiaCalendario, Mes } from '../pages/horario/calendario.model';
import * as moment from 'moment';
import { Solicitud } from '../pages/solicitudes/solicitudes-models';
import { Plugins } from '@capacitor/core';
import { Network } from '@capacitor/network';
import { Usuario } from 'src/models/usuarios';
import { Observable, Subject } from 'rxjs';
import { UiService } from './ui.service';
import { Preferences } from '@capacitor/preferences';

const  Storage  = Preferences;

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  apiRest: string = environment.url;
  // variables crono
  archivosRest: string = environment.urlArchivos;
  jornada: any = null;
  ultimoFichaje: any = null;
  fechaJornada: Date = new Date();
  // variables solicitudes
  totalSolicitudesPendientes: number = 0;
  totalIncidenciasPendientes: number = 0;
  // variables bloque usuarios
  usuariosEmpresa: any[] = [];
  usuariosEmpresaPendientesValidar: any[] = [];
  usuariosNoActivos: any[] = [];
  usuariosEmpresaParaFiltrar: any[] = [];
  usuariosEmpresaPendientesValidarParaFiltrar: any[] = [];
  usuariosNoActivosParaFiltrar: any[] = [];
  connected: boolean =true;
  conected$ = new Subject<boolean>();
  constructor(private http: HttpClient,
    private errorService: ErrorService,
    private globalService: GlobalService,
    private platform: Platform,
    private uiServe:UiService) {
      this.refresToken();
     }

    async refresToken(){
      if (!this.globalService.token){ 
      this.globalService.token =(await Storage.get({ key: "token" })).value
      }
      
    }
     ponerEncabezados() {
      return  new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.globalService.token
      });
    }

    async ponerEncabezado1(){
      return  new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization:(await Storage.get({ key: "token" })).value
      });
    }

    async comprobarRed(changeRef: ChangeDetectorRef) {
      await Network.getStatus().then(async (status) => {
        this.connected = status.connected;
        changeRef.detectChanges();
      });
      Network.addListener('networkStatusChange', async (status) => {
        this.connected = status.connected;
        this.conected$.next(this.connected);
        changeRef.detectChanges();
      });
    }
    getConected$(): Observable<boolean> {
      return this.conected$.asObservable();
    }

    avisarNoConexionInternet(){
      if(!this.connected){
        return false
      } else {
        return true;
      }
    }

    /***************************************************************************************
                                         BLOQUE CRONO
     **************************************************************************************/
     obtenerJornadaPorId(idJornada: number): Promise<{ ok: boolean, jornada: any, ultimoFichaje }> {
    //  
      return new Promise(async (resolve, reject) => {
        if(!this.avisarNoConexionInternet)return this.uiServe.alertaInformativa(`No tiene conexión a internet, intentelo más tarde`);
        this.http.get(`${this.apiRest}/jornadas/obtenerJornadaPorIdJornada/${idJornada}`, { headers: await this.ponerEncabezados() })
          .subscribe((resp: any) => {
            resolve(resp);
          }, (error) => {
            this.errorService.getError(error).then((tryAgain: boolean) => {
              reject(tryAgain);
            });
          });
      })
    }
  
    getFichajes(idJornada: number): Promise<{ ok: boolean, fichajes: any[] }> {
      return new Promise(async (resolve, reject) => {
        if(!this.avisarNoConexionInternet)return this.uiServe.alertaInformativa(`No tiene conexión a internet, intentelo más tarde`);
        this.http.get(`${this.apiRest}/fichajes/obtenerFichajes/${idJornada}/false`, { headers: await this.ponerEncabezados() })
          .subscribe((resp: any) => {
            resolve(resp);
          }, (error) => {
            this.errorService.getError(error).then((tryAgain: boolean) => {
              reject(tryAgain);
            });
          });
      })
    }
  
    getFichajesRemunerados(idJornada: number): Promise<{ ok: boolean, fichajes: any[] }> {
      return new Promise(async (resolve, reject) => {
        if(!this.avisarNoConexionInternet)return this.uiServe.alertaInformativa(`No tiene conexión a internet, intentelo más tarde`);
        this.http.get(`${this.apiRest}/fichajes/obtenerFichajes/${idJornada}/remunerados/false`, { headers: await this.ponerEncabezados() })
          .subscribe((resp: any) => {
            resolve(resp);
          }, (error) => {
            this.errorService.getError(error).then((tryAgain: boolean) => {
              reject(tryAgain);
            });
          });
      })
    }
  
    obtenerIncidenciasPorIdJornada(idJornada: number): Promise<{ ok: boolean, incidencias: any[] }> {
      return new Promise(async (resolve, reject) => {
        if(!this.avisarNoConexionInternet)return this.uiServe.alertaInformativa(`No tiene conexión a internet, intentelo más tarde`);
        this.http.get(`${this.apiRest}/incidencias/obtenerIncidenciasPorIdJornada/${idJornada}`, { headers: await this.ponerEncabezados() })
          .subscribe((resp: any) => {
            resolve(resp);
          }, (error) => {
            this.errorService.getError(error).then((tryAgain: boolean) => {
              reject(tryAgain);
            });
          });
      })
    }

    deleteTempJornadasSinFichar(jornadasPendientes:any[]){
      return this.http.post(`${this.apiRest}/jornadas/deleteTempJornadasSinFichar`, jornadasPendientes, { headers: this.ponerEncabezados() })
    }
  
    reiniciarJornada(idJornada: number) {
      return new Promise(async (resolve, reject) => {
        if(!this.avisarNoConexionInternet)return this.uiServe.alertaInformativa(`No tiene conexión a internet, intentelo más tarde`);
        this.http.get(`${this.apiRest}/jornadas/eliminarJornada/${idJornada}`, { headers: await this.ponerEncabezados() })
          .subscribe((resp: any) => {
            resolve(resp);
          }, (error) => {
            this.errorService.getError(error).then((tryAgain: boolean) => {
              reject(tryAgain);
            });
          });
      })
    }
  
    obtenerInfoJornadaPorFecha(fecha: string): Promise<{ ok: boolean, jornada: any }> {
      return new Promise(async (resolve, reject) => {
        if(!this.avisarNoConexionInternet)return this.uiServe.alertaInformativa(`No tiene conexión a internet, intentelo más tarde`);
        this.http.get(`${this.apiRest}/jornadas/obtenerIdJornadaPorFecha/${fecha}`, { headers: await this.ponerEncabezados() })
          .subscribe((resp: any) => {
            resolve(resp);
          }, (error) => {
            this.errorService.getError(error).then((tryAgain: boolean) => {
              reject(tryAgain);
            });
          });
      })
    }
  
    obtenerMinMaxHora(idFichaje: number): Promise<{ ok: boolean, min: Date, max: Date }> {
      return new Promise(async (resolve, reject) => {
        if(!this.avisarNoConexionInternet)return this.uiServe.alertaInformativa(`No tiene conexión a internet, intentelo más tarde`);
        this.http.get(`${this.apiRest}/fichajes/obtenerMinMaxHora/${idFichaje}`, { headers: await this.ponerEncabezados() })
          .subscribe((resp: any) => {
            resolve(resp);
          }, (error) => {
            this.errorService.getError(error).then((tryAgain: boolean) => {
              reject(tryAgain);
            });
          });
      })
    }
  
    modificarFichaje(infoFichaje: any, jornadaActualBool: boolean): Promise<{ ok: boolean, ultimoFichaje: any, jornada: any, msg: string,codigo?:number,fichajeObligatorio?:any }> {
      return new Promise(async (resolve, reject) => {
        if(!this.avisarNoConexionInternet)return this.uiServe.alertaInformativa(`No tiene conexión a internet, intentelo más tarde`);
        this.http.post(`${this.apiRest}/fichajes/modificarFichaje/${jornadaActualBool}`, infoFichaje, { headers: await this.ponerEncabezados() })
          .subscribe((resp: any) => {
            resolve(resp);
          }, (error) => {
            this.errorService.getError(error).then((tryAgain: boolean) => {
              reject(tryAgain);
            });
          });
      })
    }
  
    eliminarFichaje(idFichaje: number, jornadaActualBool: boolean): Promise<{ ok: boolean, ultimoFichaje: any, jornada: any, msg?:string }> {
      return new Promise(async (resolve, reject) => {
        if(!this.avisarNoConexionInternet)return this.uiServe.alertaInformativa(`No tiene conexión a internet, intentelo más tarde`);
        this.http.get(`${this.apiRest}/fichajes/eliminarFichaje/${idFichaje}/${jornadaActualBool}`, { headers: await this.ponerEncabezados() })
          .subscribe((resp: any) => {
            resolve(resp);
          }, (error) => {
            this.errorService.getError(error).then((tryAgain: boolean) => {
              reject(tryAgain);
            });
          });
      })
    }
  
    getNombreCsvJornada(idJornada: number): Promise<{ ok: boolean, nombreCsv: string }> {
      return new Promise(async (resolve, reject) => {
        if(!this.avisarNoConexionInternet)return this.uiServe.alertaInformativa(`No tiene conexión a internet, intentelo más tarde`);
        this.http.get(`${this.apiRest}/jornadas/nombreCsvJornada/${idJornada}`, { headers: await this.ponerEncabezados() })
          .subscribe((resp: any) => {
            resolve(resp);
          }, (error) => {
            this.errorService.getError(error).then((tryAgain: boolean) => {
              reject(tryAgain);
            });
          });
      })
    }

        /***************************************************************************************
                                BLOQUE DEPARTAMENTOS,CLASIFICACIONES Y SECCIONES
        **************************************************************************************/
   getClasificaciones() {
    return new Promise(async (resolve, reject) => {
      if(!this.avisarNoConexionInternet)return this.uiServe.alertaInformativa(`No tiene conexión a internet, intentelo más tarde`);
      this.http.get(`${this.apiRest}/clasificacion-empleados/obtenerClasificaciones`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  getSecciones() {
    return new Promise(async (resolve, reject) => {
      if(!this.avisarNoConexionInternet)return this.uiServe.alertaInformativa(`No tiene conexión a internet, intentelo más tarde`);
      this.http.get(`${this.apiRest}/secciones/obtenerSecciones`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }
  getDepartamentos(idEmpresa: number): Promise<{ ok: boolean, departamentos: any[] }> {
    return new Promise(async (resolve, reject) => {
      //if(!this.avisarNoConexionInternet){
      //  console.log('hola');
      //  reject(this.uiServe.alertaInformativa(`No tiene conexión a internet, intentelo más tarde`))
      //}
      this.http.get(`${this.apiRest}/departamentos/obtenerDepartamentos/false`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  getCentros(): Promise<{ ok: boolean, departamentos: any[] }> {
    return new Promise(async (resolve, reject) => {
      if(!this.avisarNoConexionInternet)return this.uiServe.alertaInformativa(`No tiene conexión a internet, intentelo más tarde`);
      this.http.get(`${this.apiRest}/centros/obtenerCentros/false`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  getResponsableEmpresa(year): Promise<{ ok: boolean, departamentos: any[] }> {
    
    
    return new Promise(async (resolve, reject) => {
      if(!this.avisarNoConexionInternet){
        return this.uiServe.alertaInformativa(`No tiene conexión a internet, intentelo más tarde`);}
      this.http.get(`${this.apiRest}/usuarios/getResponsableEmpresa/${year}`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  getDepartamento(idDepartamento: number): Promise<{ ok: boolean, departamento: any }> {
    return new Promise(async (resolve, reject) => {
      if(!this.avisarNoConexionInternet)return this.uiServe.alertaInformativa(`No tiene conexión a internet, intentelo más tarde`);
      this.http.get(`${this.apiRest}/departamentos/obtenerDepartamento/${idDepartamento}`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  crearDepartamento(departamento: any): Promise<{ ok: boolean, departamento: any }> {
    return new Promise(async (resolve, reject) => {
      if(!this.avisarNoConexionInternet)return this.uiServe.alertaInformativa(`No tiene conexión a internet, intentelo más tarde`);
      this.http.post(`${this.apiRest}/departamentos/crearDepartamento`, departamento, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  modificarDepartamento(departamento: any): Promise<{ ok: boolean, departamento: any }> {
    return new Promise(async (resolve, reject) => {
      if(!this.avisarNoConexionInternet)return this.uiServe.alertaInformativa(`No tiene conexión a internet, intentelo más tarde`);
      this.http.post(`${this.apiRest}/departamentos/modificarDepartamento`, departamento, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  eliminarDepartamento(idDepartamento: number): Promise<{ ok: boolean, departamento: any }> {
    return new Promise(async (resolve, reject) => {
      if(!this.avisarNoConexionInternet)return this.uiServe.alertaInformativa(`No tiene conexión a internet, intentelo más tarde`);
      this.http.get(`${this.apiRest}/departamentos/eliminarDepartamento/${idDepartamento}`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

        /***************************************************************************************
                                              BLOQUE EMPRESA
        **************************************************************************************/
  getEmpresa(): Promise<{ ok: boolean, empresa: any, urlLogoEmpresa: string | boolean }> {
    return new Promise(async (resolve, reject) => {
      if(!this.avisarNoConexionInternet)return this.uiServe.alertaInformativa(`No tiene conexión a internet, intentelo más tarde`);
      this.http.get(`${this.apiRest}/empresas/obtenerEmpresaPorIdEmpresa`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  modificarEmpresa(infoEmpresa: any): Promise<{ ok: boolean, empresa: any }> {
    return new Promise(async (resolve, reject) => {
      if(!this.avisarNoConexionInternet)return this.uiServe.alertaInformativa(`No tiene conexión a internet, intentelo más tarde`);
      this.http.post(`${this.apiRest}/empresas/ModificarEmpresa`, infoEmpresa, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  getLogoEmpresa(): Promise<{ ok: boolean, urlImagenPerfil: string }> {
    return new Promise(async (resolve, reject) => {
      if(!this.avisarNoConexionInternet)return this.uiServe.alertaInformativa(`No tiene conexión a internet, intentelo más tarde`);
      this.http.get(`${this.apiRest}/empresas/obtenerLogoEmpresa`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  subirLogoEmpresa(file: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let formData = new FormData();
      let xhr = new XMLHttpRequest();

      formData.append("file", file, file.name);
      let scope = this;
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 201) {
            resolve(JSON.parse(xhr.response));
          } else {
            scope.errorService.getError(xhr.status);
            reject(xhr.response);
          }
        }
      };

      let url = `${this.apiRest}/empresas/subirLogoEmpresa`;

      xhr.open("POST", url, true);
      xhr.setRequestHeader("Authorization", this.globalService.token);
      xhr.send(formData);
    });
  }

  cambiarRequerirUbicacion(): Promise<{ ok: boolean }> {
    return new Promise(async (resolve, reject) => {
      if(!this.avisarNoConexionInternet)return this.uiServe.alertaInformativa(`No tiene conexión a internet, intentelo más tarde`);
      this.http.get(`${this.apiRest}/empresas/cambiarRequerirUbicacion`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  cambiarContactoUsuarios(): Promise<{ ok: boolean }> {
    return new Promise(async (resolve, reject) => {
      if(!this.avisarNoConexionInternet)return this.uiServe.alertaInformativa(`No tiene conexión a internet, intentelo más tarde`);
      this.http.get(`${this.apiRest}/empresas/cambiarContactoUsuarios`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  cambiarActivarProyectos(): Promise<{ ok: boolean }> {
    return new Promise(async (resolve, reject) => {
      if(!this.avisarNoConexionInternet)return this.uiServe.alertaInformativa(`No tiene conexión a internet, intentelo más tarde`);
      this.http.get(`${this.apiRest}/empresas/cambiarActivarProyectos`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  cambiarTrabajarConTiempoAsignado(): Promise<{ ok: boolean }> {
    return new Promise(async (resolve, reject) => {
      if(!this.avisarNoConexionInternet)return this.uiServe.alertaInformativa(`No tiene conexión a internet, intentelo más tarde`);
      this.http.get(`${this.apiRest}/empresas/cambiarTrabajarConTiempoAsignado`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  cambiarTrabajarConGastos(): Promise<{ ok: boolean }> {
    return new Promise(async (resolve, reject) => {
      if(!this.avisarNoConexionInternet)return this.uiServe.alertaInformativa(`No tiene conexión a internet, intentelo más tarde`);
      this.http.get(`${this.apiRest}/empresas/cambiarTrabajarConGastos`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  cambiarColorObligatorio(): Promise<{ ok: boolean, color?: string }> {
    return new Promise(async (resolve, reject) => {
      if(!this.avisarNoConexionInternet)return this.uiServe.alertaInformativa(`No tiene conexión a internet, intentelo más tarde`);
      this.http.get(`${this.apiRest}/empresas/cambiarColorObligatorio`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  cambiarColorEmpresa(codigoColor: string): Promise<{ ok: boolean }> {
    return new Promise(async (resolve, reject) => {
      if(!this.avisarNoConexionInternet)return this.uiServe.alertaInformativa(`No tiene conexión a internet, intentelo más tarde`);
      this.http.get(`${this.apiRest}/empresas/cambiarColorEmpresa/${codigoColor}`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  geolocalizarDireccion(direccion: string): Promise<{}> {
    return new Promise((resolve, reject) => {
      const encabezado = new HttpHeaders();
      encabezado.set("Access-Control-Allow-Origin", "*");
      encabezado.set("Access-Control-Allow-Methods", "POST, GET, PUT, OPTIONS, DELETE");
      encabezado.set("Access-Control-Max-Age", "3600");
      encabezado.set("Access-Control-Allow-Headers", "x-requested-with, content-type");
      this.http
        .get(
          `https://consumermanagement.ainia.es/misphp/geo.php?address=${direccion}`,
          {
            headers: encabezado
          }
        )
        .subscribe((response: any) => {
          resolve(response);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    });
  }

  comprobarDistancia(origen?, destino?): Promise<{}> {
    return new Promise(async(resolve, reject) => {
      const encabezado = new HttpHeaders();
      encabezado.set("Access-Control-Allow-Origin", "*");
      encabezado.set("Access-Control-Allow-Methods", "POST, GET, PUT, OPTIONS, DELETE");
      encabezado.set("Access-Control-Max-Age", "3600");
      encabezado.set("Access-Control-Allow-Headers", "x-requested-with, content-type");
      this.http
        .get(`${this.apiRest}/incidencias/getDistancia/${origen}/${destino}`,{headers:await this.ponerEncabezados()})
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  modificarCoordenadas(lat: number, lng: number): Promise<{ ok: boolean, empresa: any }> {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${this.apiRest}/empresas/modificarCoordenadasEmpresa`, { lat: lat, lng: lng }, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

        /***************************************************************************************
                                              BLOQUE ESTADISTICAS
        **************************************************************************************/
  getEstadisticasDiariasUsuario(idEmpresa: number, idUsuarioEmail: number, fecha: string): Promise<{ ok: boolean, jornada: any }> {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${this.apiRest}/fichajes/estadisticasDiariasUsuario/false`, { idEmpresa, idUsuarioEmail, fecha }, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  getEstadisticasDiarias(idEmpresa: number, fecha: string, idUsuarioEmail?: number, idDepartamento?: number): Promise<{ ok: boolean, estadisticas: any[] }> {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${this.apiRest}/fichajes/estadisticasDiarias/false`, { idEmpresa, fecha, idUsuarioEmail, idDepartamento }, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  getEstadisticasSemanales(idEmpresa: number, year: number, semana: number, idUsuarioEmail?: number, idDepartamento?: number): Promise<{ ok: boolean, estadisticas: any }> {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${this.apiRest}/fichajes/estadisticasSemanales/false`, { idEmpresa, year, semana, idUsuarioEmail, idDepartamento }, { headers: await this.ponerEncabezados() }).subscribe((response: any) => {
        resolve(response);
      }, (error) => {
        this.errorService.getError(error).then((tryAgain: boolean) => {
          reject(tryAgain);
        });
      });
    });
  }

  getEstadisticasMensuales(idEmpresa: number, year: number, mes: number, idUsuarioEmail?: number, idDepartamento?: number): Promise<{ ok: boolean, estadisticas: any }> {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${this.apiRest}/fichajes/estadisticasMensuales/false`, { idEmpresa, year, mes, idUsuarioEmail, idDepartamento }, { headers: await this.ponerEncabezados() }).subscribe((response: any) => {
        resolve(response);
      }, (error) => {
        this.errorService.getError(error).then((tryAgain: boolean) => {
          reject(tryAgain);
        });
      });
    });
  }

  getEstadisticasAnuales(idEmpresa: number, year: number, idUsuarioEmail?: number, idDepartamento?: number): Promise<{ ok: boolean, estadisticas: any }> {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${this.apiRest}/fichajes/estadisticasAnuales/false`, { idEmpresa, year, idUsuarioEmail, idDepartamento }, { headers: await this.ponerEncabezados() }).subscribe((response: any) => {
        resolve(response);
      }, (error) => {
        this.errorService.getError(error).then((tryAgain: boolean) => {
          reject(tryAgain);
        });
      });
    });
  }

  // ESTATIDISTICAS TIRANDO DE HORARIOS
  getEstadisticas(dato): Promise<{ ok: boolean, estadisticas: any }> {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${this.apiRest}/jornadas/getTiempos`, dato, { headers: await this.ponerEncabezados() }).subscribe((response: any) => {
        resolve(response);
      }, (error) => {
        this.errorService.getError(error).then((tryAgain: boolean) => {
          reject(tryAgain);
        });
      });
    });
  }

  getEstadisticasApp(dato): Promise<{ ok: boolean, estadisticas: any }> {
    
    return new Promise(async (resolve, reject) => {
      this.http.post(`${this.apiRest}/jornadas/getTiemposApp`, dato, { headers: await this.ponerEncabezados() }).subscribe((response: any) => {
        resolve(response);
      }, (error) => {
        this.errorService.getError(error).then((tryAgain: boolean) => {
          reject(tryAgain);
        });
      });
    });
  }

  // crear Jornada 
  generarJornadaProgramada(dato): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${this.apiRest}/fichajes/generarJornadaProgramada`, dato, { headers: await this.ponerEncabezados() }).subscribe((response: any) => {
        resolve(response);
      }, (error) => {
        this.errorService.getError(error).then((tryAgain: boolean) => {
          reject(tryAgain);
        });
      });
    });
  }

  generarJornadaProgramadaSinHorario(dato): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${this.apiRest}/fichajes/crearJornadaAutomatizadaSinHorario`, dato, { headers: await this.ponerEncabezados() }).subscribe((response: any) => {
        resolve(response);
      }, (error) => {
        this.errorService.getError(error).then((tryAgain: boolean) => {
          reject(tryAgain);
        });
      });
    });
  }

        /***************************************************************************************
                                              BLOQUE AUSENCIAS
        **************************************************************************************/
  obtenerAusencias(dato){
      return this.http.post(`${this.apiRest}/ausencias/obtenerAusenciasEmpleados/false`,dato, {headers:  new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.globalService.token,
      })})
  }
  getTipoAusencias() {
      return this.http.get<{ ok: boolean, tipoAusencia: any[] }>(`${this.apiRest}/tipoNoTrabajos/obtenerTipoAusencias`,
       { headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.globalService.token,
      })});
  }


  getAvisoExceso() {
    return this.http.get(`${this.apiRest}/jornadas/getAvisoExceso`,
     { headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this.globalService.token,
    })});
}

vacacionesPermisosCalendario(datos){
  return this.http.post(`${this.apiRest}/ausencias/vacacionesPermisosCalendario`,datos, { headers:this.ponerEncabezados() })
}

getUrl(id) {
  // return this.httpClient.get(`${this.apiRest}documentos/getArchivoFisico/true/${id}`, { headers: this.ponerEncabezados() });
  return this.http.get(`${this.apiRest}/documentos/getUrl/true/${id}`, { headers:this.ponerEncabezados() , responseType:'blob' });
}

  getDirectoriosYDocsEmpresaComplete(datos){
    return this.http.post(`${this.apiRest}/documentos/getDirectoriosYDocsEmpresaComplete/true`,datos, {headers:  new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this.globalService.token,
    })})
  }

  getDirectorioPorId(id){
    return this.http.get(`${this.apiRest}/documentos/getDirectorioPorId/${id}`,
    { headers: new HttpHeaders({
     'Content-Type': 'application/json',
     Authorization: this.globalService.token,
   })});
  }


  getDiasAusencia(fechaInicio,fechaFin) {
      return this.http.get(`${this.apiRest}/ausencias/getDiasAusencia/${fechaInicio}/${fechaFin}`,
       { headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.globalService.token,
      })});
  }

  getAusenciasSistemaPorUsuario(id,year) {
      return this.http.get(`${this.apiRest}/ausencias/getAusenciasSistemaPorUsuario/${id}/${year}`,
       { headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.globalService.token,
      })});
  }

  cancelarDiaDeAusencias(dato:any) {
      return this.http.post(`${this.apiRest}/ausencias/cancelarDiaDeAusencias`,dato, {headers:  new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.globalService.token,
      })})
  }

        /***************************************************************************************
                                              BLOQUE FICHAJES
        **************************************************************************************/
  fichar(fichajeInfo: any) {
    return this.http.post(`${this.apiRest}/fichajes/fichar`, fichajeInfo, { headers: {'Content-Type': 'application/json',Authorization: this.globalService.token,} })
  }

  obligaFichar(fichajeInfo: any): Promise<{ ok: boolean, jornada: any, fichaje: any, alarma: any, idAlarmaCancelar: string, duracionMinima: number, msg: string }> {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${this.apiRest}/fichajes/obligaFichar`, fichajeInfo, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    });
  }

  addFichaje(fichajeInfo: any, jornadaActualBool: boolean): Promise<{ ok: boolean, jornada: any, idFichaje: number, msg: string }> {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${this.apiRest}/fichajes/addFichaje/${jornadaActualBool}`, fichajeInfo, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    });
  }

  comprobarNuevoInicioJornada(idJornada: number): Promise<{ sePuedeIniciarJornada: boolean }> {
    return new Promise(async (resolve, reject) => {
      this.http.get(`${this.apiRest}/jornadas/comprobarNuevoInicioJornada/${idJornada}`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  actualizarCoordsFichaje(infoCoords: any): Promise<{ ok: boolean }> {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${this.apiRest}/fichajes/actualizarCoordsFichaje`, infoCoords, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    });
  }

  getJornadaActualYComprobarSinFinalizar(): Promise<{ ok: boolean, jornadaActual: { ok: boolean, jornada: any, ultimoFichaje: any }, jornadaPorFinalizar: { ok: boolean, jornada: any, ultimoFichaje: any } }> {
    return new Promise(async (resolve, reject) => {
      this.http.get(`${this.apiRest}/jornadas/obtenerJornadaActualYComprobarSinFinalizar`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  obtenerJornadaActual(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.http.get(`${this.apiRest}/jornadas/obtenerJornadaHoy`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  getTipos(idEmpresa: number, idJornada: number,fecha?:string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.http
        .get(`${this.apiRest}/tiposFichaje/obtenerTiposDeFichajeDisponibles/${idJornada}/${fecha}`, {
          headers: await this.ponerEncabezados()
        })
        .subscribe((response: any) => {
          resolve(response);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    });
  }

  getAusenciaJornada(idEmpleado,idJornada: number): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.http
        .get(`${this.apiRest}/ausencias/getAusenciaXJornada/${idEmpleado}/${idJornada}`, {
          headers: await this.ponerEncabezados()
        })
        .subscribe((response: any) => {
          resolve(response);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    });
  }
  crearAusenciaJornada(idAusencia,idEmpleado,idJornada: number): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.http
        .get(`${this.apiRest}/ausencias/crearAusenciaDesdeFichaje/${idAusencia}/${idEmpleado}/${idJornada}`, {
          headers: await this.ponerEncabezados()
        })
        .subscribe((response: any) => {
          resolve(response);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    });
  }

  comprobarJornadaSinHorasAsigProyecto(idEmpresa: number, idUsuario: number): Promise<{ ok: boolean, jornadaSinHorasAsigProyecto: any }> {
    return new Promise(async (resolve, reject) => {
      this.http.get(`${this.apiRest}/jornadas/comprobarJornadaSinHorasAsigProyecto`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  setTutorialVisto(idUsuario: number): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.http
        .get(`${this.apiRest}/usuarios/setTutorialVisto`, {
          headers: await this.ponerEncabezados()
        })
        .subscribe((response: any) => {
          resolve(response);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    });
  }

  getSelectProyectosIdUsuarioEmpresa(idEmpresa: number, idUsuarioEmail: number): Promise<{ ok: boolean, proyectos: any[] }> {
    return new Promise(async (resolve, reject) => {
      this.http.get(`${this.apiRest}/proyectos/obtenerSelectProyectosIdUsuarioEmpresa`, { headers: await this.ponerEncabezados() }).subscribe((response: any) => {
        resolve(response);
      }, (error) => {
        this.errorService.getError(error).then((tryAgain: boolean) => {
          reject(tryAgain);
        });
      });
    });
  }

  setTiempoImpProyecto(idJornada: number, idEmpresa: number, idUsuarioEmail: number, idUsuarioEmpresa: number, horasProyecto: any[]): Promise<{ ok: boolean, proyectos: any[] }> {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${this.apiRest}/proyectos/setTiempoImpProyecto`, { idJornada: idJornada, idEmpresa: idEmpresa, idUsuarioEmail: idUsuarioEmail, idUsuarioEmpresa: idUsuarioEmpresa, horasProyecto: horasProyecto }, { headers: await this.ponerEncabezados() }).subscribe((response: any) => {
        resolve(response);
      }, (error) => {
        this.errorService.getError(error).then((tryAgain: boolean) => {
          reject(tryAgain);
        });
      });
    });
  }

  modTiempoSinProyecto(idJornada: number): Promise<{ ok: boolean, proyectos: any[] }> {
    return new Promise(async (resolve, reject) => {
      this.http.get(`${this.apiRest}/proyectos/modTiempoSinProyecto/${idJornada}`, { headers: await this.ponerEncabezados() }).subscribe((response: any) => {
        resolve(response);
      }, (error) => {
        this.errorService.getError(error).then((tryAgain: boolean) => {
          reject(tryAgain);
        });
      });
    });
  }

  getTiempoImputadoProyectos(idJornada: number): Promise<{ ok: boolean, proyectos: any[] }> {
    return new Promise(async (resolve, reject) => {
      this.http.get(`${this.apiRest}/proyectos/obtenerTiempoImputadoProyectos/${idJornada}`, { headers: await this.ponerEncabezados() }).subscribe((response: any) => {
        resolve(response);
      }, (error) => {
        this.errorService.getError(error).then((tryAgain: boolean) => {
          reject(tryAgain);
        });
      });
    });
  }

  

        /***************************************************************************************
                              BLOQUE HORARIO, CREACIÓN CALENDARIO DINAMICO Y TAREAS
        **************************************************************************************/
   obtenerHorario(dato){
    
    return this.http.post(`${this.apiRest}/horario/getHorarioEmpleado`,dato, {headers:  new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this.globalService.token,
    })})
  }

  obtenerHorarioApp(dato){
    return this.http.post(`${this.apiRest}/horario/getHorarioEmpleadoApp`,dato, {headers:  new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this.globalService.token,
    })})
  }
  // inicio tareas
  obtenerHorarioTarea(dato){
    return this.http.post(`${this.apiRest}/tareas/getOrganizadorTareas`,dato, {headers:  new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this.globalService.token,
    })})
  }


  estadisticasEmpleados(dato){
    return this.http.post(`${this.apiRest}/tareas/getTareasEmpleadosEstadisticas`,dato, {headers:  new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this.globalService.token,
    })})
  }

  estadisticasEmpleadoDia(dato){
  //  delete dato.empleados;
    return this.http.post(`${this.apiRest}/tareas/estadisticasEmpleados`,dato, {headers:  new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this.globalService.token,
    })})
  }

  validarTareas(dato){
    
    return this.http.post(`${this.apiRest}/tareas/validarTareas`,dato, {headers:  new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this.globalService.token,
    })})
  }


  getTareasEmpleados(dato){
    return this.http.post(`${this.apiRest}/tareas/getTareasEmpleados`,dato, {headers:  new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this.globalService.token,
    })})
  }

  modTareaEmpleado(dato){
    return this.http.post(`${this.apiRest}/tareas/modTareaEmpleado`,dato, {headers:  new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this.globalService.token,
    })})
  }

  // fin tareas

  // funciones para generar el calendario manualmente
  generarCalendarioAnual(fechaIni: Date, fechaFin: Date, diasParaMarcar?: DiaCalendario[]): Mes[] {
    moment.locale('es');
    let calendarioAnual: Mes[] = [];
    let allDaysOfYear: DiaCalendario[] = [];
    const mesInicio = moment().set({ year: moment(fechaIni).year(), month: moment(fechaIni).month() }).startOf('month');
    const mesFinal = moment().set({ year: moment(fechaFin).year(), month: moment(fechaFin).month() }).endOf('month');
    const numDias = mesFinal.diff(mesInicio, 'days');
    const diaInicio = moment().set({ year: moment(fechaIni).year(), month: moment(fechaIni).month() }).startOf('month').dayOfYear();
    for (let i = diaInicio; i <= diaInicio + numDias; i++) {
      allDaysOfYear.push(new DiaCalendario(moment().set({ year: moment(fechaFin).year() }).set('dayOfYear', i).toISOString(), [new DataDiaCalendario()]));
    }
    if (diasParaMarcar != undefined) {
      diasParaMarcar.forEach(dia => {
        allDaysOfYear.splice(allDaysOfYear.findIndex(dayOfYear => dayOfYear.fecha == dia.fecha), 1, dia);
      });
    }
    for (let i = 0; i < allDaysOfYear.length; i++) {
      if (i == 0) {
        calendarioAnual.push(
          {
            mesNum: moment(fechaIni).month(),
            mesLetra: moment().set('month', moment(fechaIni).month()).format('MMMM'),
            semanas: [
              {
                semanaNum: moment(allDaysOfYear[0].fecha).week(),
                dias: [
                  allDaysOfYear[0]
                ]
              }
            ]
          });
      } else {
        const diaAnterior = allDaysOfYear[i - 1];
        const diaAnteriorFecha = moment(allDaysOfYear[i - 1].fecha);
        const dia = allDaysOfYear[i];
        const diaFecha = moment(allDaysOfYear[i].fecha);
        if (diaAnteriorFecha.month() == diaFecha.month() && diaAnteriorFecha.week() == diaFecha.week()) {
          calendarioAnual[calendarioAnual.length - 1].semanas[calendarioAnual[calendarioAnual.length - 1].semanas.length - 1].dias.push(dia);
        } else if (diaAnteriorFecha.month() == diaFecha.month() && diaAnteriorFecha.week() != diaFecha.week()) {
          let diasVacios = moment(calendarioAnual[calendarioAnual.length - 1].semanas[calendarioAnual[calendarioAnual.length - 1].semanas.length - 1].dias[0].fecha).day() - 1;
          if (diasVacios == -1) diasVacios = 6;
          for (let n = 0; n < diasVacios; n++) {
            calendarioAnual[calendarioAnual.length - 1].semanas[calendarioAnual[calendarioAnual.length - 1].semanas.length - 1].dias.unshift(null);
          }
          calendarioAnual[calendarioAnual.length - 1].semanas.push({ semanaNum: moment(dia.fecha).week(), dias: [dia] });
        } else if (diaAnteriorFecha.month() != diaFecha.month()) {
          let diasVacios = moment(calendarioAnual[calendarioAnual.length - 1].semanas[calendarioAnual[calendarioAnual.length - 1].semanas.length - 1].dias[0].fecha).day() - 1;
          if (diasVacios == -1) diasVacios = 6;
          for (let n = 0; n < diasVacios; n++) {
            calendarioAnual[calendarioAnual.length - 1].semanas[calendarioAnual[calendarioAnual.length - 1].semanas.length - 1].dias.unshift(null);
          }
          calendarioAnual.push(
            {
              mesNum: diaFecha.month(),
              mesLetra: diaFecha.format('MMMM'),
              semanas: [
                {
                  semanaNum: moment(dia.fecha).week(),
                  dias: [
                    dia
                  ]
                }
              ]
            });
        }
      }
    }
    for (let calen of calendarioAnual){
      for(let semana of calen.semanas){
        for(let dia of semana.dias){
          if (semana.dias.length < 7){
            semana.dias.push(null)
          }
        }
      }
    }
    return calendarioAnual.slice();
  }

  actualizarCalendarioAnual(calendarioAnual: Mes[], funcion: { mode: 'overwrite' | 'add' | 'delete' | 'set', idData: number, property?: string, value?: any },
   diasParaMarcar?: InfoDiaCalendario[]): Mes[] {
    moment.locale('es');
    const marcadorDias: boolean = diasParaMarcar ? diasParaMarcar.length == 0 ? false : true : false;
    if (!marcadorDias) {
      diasParaMarcar = [];
      const { diaInicio, diaFinal } = this.getPrimerYultimoDia(calendarioAnual);
      const numDias = diaFinal.diff(diaInicio, 'days');
      for (let i = diaInicio.dayOfYear(); i <= diaInicio.dayOfYear() + numDias; i++) {
        diasParaMarcar.push(new DiaCalendario(moment().set({ year: diaInicio.year() }).set('dayOfYear', i).toISOString()));
      }
    }
    if (funcion.mode == 'overwrite' && funcion.idData != undefined) {
      for (let i = 0; i < diasParaMarcar.length; i++) {
        const { dia, indexMes, indexSemana, indexDia } = this.getIndicesDia(diasParaMarcar[i], calendarioAnual);
        let itemsToOverwrite = calendarioAnual[0].semanas[indexSemana].dias[indexDia].data.filter(data => data.idData == funcion.idData);
        if (itemsToOverwrite.length > 0) {
          for (const item of itemsToOverwrite) {
            calendarioAnual[0].semanas[indexSemana].dias[indexDia].data[calendarioAnual[0].semanas[indexSemana].dias[indexDia].data.indexOf(item)].data = dia.data;
          }
        } else {
          calendarioAnual[0].semanas[indexSemana].dias[indexDia].data.push({ idData: funcion.idData, data: dia.data });
        }
      }
    } else if (funcion.mode == 'delete' && funcion.idData != undefined) {
      if (marcadorDias) {
        for (let i = 0; i < diasParaMarcar.length; i++) {
          const { dia, indexMes, indexSemana, indexDia } = this.getIndicesDia(diasParaMarcar[i], calendarioAnual);
          let itemsToDelete = calendarioAnual[0].semanas[indexSemana].dias[indexDia].data.filter(data => data.idData == funcion.idData);
          for (const item of itemsToDelete) {
            calendarioAnual[0].semanas[indexSemana].dias[indexDia].data.splice(calendarioAnual[0].semanas[indexSemana].dias[indexDia].data.indexOf(item), 1);
          }
        }
      } 
    } else if (funcion.mode == 'add' && funcion.idData != undefined) {
      for (let i = 0; i < diasParaMarcar.length; i++) {
        const { dia, indexMes, indexSemana, indexDia } = this.getIndicesDia(diasParaMarcar[i], calendarioAnual);
        calendarioAnual[0].semanas[indexSemana].dias[indexDia].data.push({ idData: funcion.idData, data: dia.data });
      }
    } else if (funcion.mode == 'set' && funcion.idData != undefined && funcion.property != undefined && funcion.value != undefined) {
      for (let i = 0; i < diasParaMarcar.length; i++) {
        const { dia, indexMes, indexSemana, indexDia } = this.getIndicesDia(diasParaMarcar[i], calendarioAnual);
        let itemsToOverwrite = calendarioAnual[indexMes].semanas[indexSemana].dias[indexDia].data.filter(data => data.idData == funcion.idData);
        if (itemsToOverwrite.length > 0) {
          for (const item of itemsToOverwrite) {
            calendarioAnual[0].semanas[indexSemana].dias[indexDia].data[calendarioAnual[0].semanas[indexSemana].dias[indexDia].data.indexOf(item)].data[funcion.property] = funcion.value;
          }
        }
      }
    }
    return calendarioAnual.slice();
  }

  private getIndicesDia(dia: InfoDiaCalendario, calendarioAnual: Mes[]): IndicesDia {
    const indexMes = moment(dia.fecha).month();
    const indexSemana = calendarioAnual[0].semanas.findIndex(semana => semana.semanaNum == moment(dia.fecha).week());
    const indexDia = (moment(dia.fecha).day() - 1) == -1 ? 6 : moment(dia.fecha).day() - 1;
    return { dia, indexMes, indexSemana, indexDia };
  }

  private getPrimerYultimoDia(calendarioAnual: Mes[]): { diaInicio, diaFinal } {
    const diaInicio = moment(calendarioAnual[0].semanas[0].dias.find(dia => dia != null).fecha);
    const diaFinal = moment(calendarioAnual[calendarioAnual.length - 1].semanas[calendarioAnual[calendarioAnual.length - 1].semanas.length - 1].dias[calendarioAnual[calendarioAnual.length - 1].semanas[calendarioAnual[calendarioAnual.length - 1].semanas.length - 1].dias.length - 1].fecha);
    return { diaInicio, diaFinal };
  }

  dameDiaEmpleado(fecha:string,id:number){
    return this.http.get(`${this.apiRest}/horario/dameDiaEmpleado/${id}/${fecha}`, {headers:  new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this.globalService.token,
    })})
  }

          /***************************************************************************************
                                              BLOQUE INCIDENCIAS
        **************************************************************************************/
  getIncidencias(porValidar: boolean, idsDepartamento: number[], idsUsuarioEmail: number[], idsTipoIncidencia: number[], desde: string, hasta: string,): Promise<{ ok: boolean, incidencias: any[] }> {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${this.apiRest}/incidencias/obtenerIncidencias/false`, { porValidar, idsDepartamento, idsUsuarioEmail, idsTipoIncidencia, desde, hasta }, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  obtenerSelectsFiltrosIncidencias(): Promise<{ ok: boolean, usuarios: { id: number, nombre: string }[], departamentos: { id: number, nombre: string }[], tiposIncidencia: { id: number, nombre: string, seleccionada: boolean }[] }> {
    return new Promise(async (resolve, reject) => {
      this.http.get(`${this.apiRest}/incidencias/obtenerSelectsFiltrosIncidencias`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  validarIncidencia(idIncidencia: number): Promise<{ ok: boolean, msg: string }> {
    return new Promise(async (resolve, reject) => {
      this.http.get(`${this.apiRest}/incidencias/validarIncidencia/${idIncidencia}`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  validarVariasIncidencias(idsIncidencias: number[]): Promise<{ ok: boolean }> {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${this.apiRest}/incidencias/validarVariasIncidencias`, { idsIncidencias }, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  crearIncidenciaModificacionFichaje(infoIncidencia: any): Promise<{ ok: boolean, usuario: any }> {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${this.apiRest}/incidencias/crearIncidenciaModificacionFichaje`, infoIncidencia, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  finalizarJornadaConIncidencia(infoFichaje: any): Promise<{ ok: boolean, usuario: any,codigo?:number,fichajeObligatorio?:any,msg?:string }> {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${this.apiRest}/fichajes/finalizarJornadaConIncidencia`, infoFichaje, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  enviarNotificacionIncidencia(idJornada: number): Promise<{ ok: boolean, usuario: any }> {
    return new Promise(async (resolve, reject) => {
      this.http.get(`${this.apiRest}/incidencias/enviarNotificacionIncidencia/${idJornada}`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

        /***************************************************************************************
                                              BLOQUE NOTIFICACIONES
        **************************************************************************************/
  getNotificacionesEmpleado(num): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.http.get(`${this.apiRest}/usuarios/getNotificacionesEmpleados/${num}/NOTIFICACION`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }
        /***************************************************************************************
                                              BLOQUE PROLONGACIONES
        **************************************************************************************/


  getProrrogasEmpleado(id,ejercicio): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.http.get(`${this.apiRest}/prorrogasJornada/getProrrogasEmpleado/${id}/${ejercicio}`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  getProrrogasRangos(dato): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${this.apiRest}/prorrogasJornada/getProrrogasRangos`,dato, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  getProrrogasRangosNew(dato): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${this.apiRest}/prorrogasJornada/getProrrogasRangosNew`,dato, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  solicitaProrroga(dato): Promise<{ ok: boolean, proyectos: any[] }> {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${this.apiRest}/prorrogasJornada/solicitaProrroga`,dato, { headers: await this.ponerEncabezados() }).subscribe((response: any) => {
        resolve(response);
      }, (error) => {
        this.errorService.getError(error).then((tryAgain: boolean) => {
          reject(tryAgain);
        });
      });
    });
  }
  solicitaProrrogaPaquetes(dato): Promise<{ ok: boolean, proyectos: any[] }> {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${this.apiRest}/prorrogasJornada/solicitaProrrogaPaquetes`,dato, { headers: await this.ponerEncabezados() }).subscribe((response: any) => {
        resolve(response);
      }, (error) => {
        this.errorService.getError(error).then((tryAgain: boolean) => {
          reject(tryAgain);
        });
      });
    });
  }

  eliminarProrroga(dato): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${this.apiRest}/prorrogasJornada/eliminarProrroga`,dato, {headers: await this.ponerEncabezados()}).subscribe((response: any) => {
        resolve(response);
      }, (error) => {
        this.errorService.getError(error).then((tryAgain: boolean) => {
          reject(tryAgain);
        });
      });
    });
  }
  eliminarProrrogas(dato): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${this.apiRest}/prorrogasJornada/eliminarProrrogas`,dato, {headers: await this.ponerEncabezados()}).subscribe((response: any) => {
        resolve(response);
      }, (error) => {
        this.errorService.getError(error).then((tryAgain: boolean) => {
          reject(tryAgain);
        });
      });
    });
  }

        /***************************************************************************************
                                              BLOQUE PROYECTOS
        **************************************************************************************/
  getProyectosUsuario(idEmpresa: number, idUsuarioEmail: number): Promise<{ ok: boolean, proyectos: any[] }> {
    return new Promise(async (resolve, reject) => {
      this.http.get(`${this.apiRest}/proyectos/obtenerProyectosIdUsuarioEmail`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  getProyectosDepartamento(idDepartamento: number): Promise<{ ok: boolean, proyectos: any[] }> {
    return new Promise(async (resolve, reject) => {
      this.http.get(`${this.apiRest}/proyectos/obtenerProyectosDepartamento/${idDepartamento}`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  getProyectosEmpresa(idEmpresa: number): Promise<{ ok: boolean, proyectos: any[] }> {
    return new Promise(async (resolve, reject) => {
      this.http.get(`${this.apiRest}/proyectos/obtenerProyectosEmpresa`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  getProyectosJornada(idEmpresa: number, fecha: string, idUsuarioEmail?: number, idDepartamento?: number): Promise<{ ok: boolean, proyectos: any[] }> {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${this.apiRest}/proyectos/proyectosJornada`, { idEmpresa, fecha, idUsuarioEmail, idDepartamento }, { headers: await this.ponerEncabezados() }).subscribe((response: any) => {
        resolve(response);
      }, (error) => {
        this.errorService.getError(error).then((tryAgain: boolean) => {
          reject(tryAgain);
        });
      });
    });
  }

  getProyectosDia(idEmpresa: number, fecha: string, idUsuarioEmail?: number, idDepartamento?: number): Promise<{ ok: boolean, proyectos: any[] }> {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${this.apiRest}/proyectos/proyectosDia`, { idEmpresa, fecha, idUsuarioEmail, idDepartamento }, { headers: await this.ponerEncabezados() }).subscribe((response: any) => {
        resolve(response);
      }, (error) => {
        this.errorService.getError(error).then((tryAgain: boolean) => {
          reject(tryAgain);
        });
      });
    });
  }

  firmarProyectosDia(idEmpresa: number, fecha: string, idUsuarioEmail?: number, idDepartamento?: number, codigo?: string): Promise<{ ok: boolean, mensaje: string }> {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${this.apiRest}/proyectos/firmarproyectosDia`, { idEmpresa, fecha, idUsuarioEmail, idDepartamento, codigo }, { headers: await this.ponerEncabezados() }).subscribe((response: any) => {
        resolve(response);
      }, (error) => {
        this.errorService.getError(error).then((tryAgain: boolean) => {
          reject(tryAgain);
        });
      });
    });
  }
  firmarProyectoDia(idEmpresa: number, fecha: string, idUsuarioEmail?: number, idDepartamento?: number, idProyecto?: number, codigo?: string): Promise<{ ok: boolean, mensaje: string }> {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${this.apiRest}/proyectos/firmarproyectoDia`, { idEmpresa, fecha, idUsuarioEmail, idDepartamento, idProyecto, codigo }, { headers: await this.ponerEncabezados() }).subscribe((response: any) => {
        resolve(response);
      }, (error) => {
        this.errorService.getError(error).then((tryAgain: boolean) => {
          reject(tryAgain);
        });
      });
    });
  }

  getProyectosSemanales(idEmpresa: number, year: number, semana: number, idUsuarioEmail?: number, idDepartamento?: number): Promise<{ ok: boolean, proyectos: any[] }> {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${this.apiRest}/proyectos/proyectosSemanales`, { idEmpresa, year, semana, idUsuarioEmail, idDepartamento }, { headers: await this.ponerEncabezados() }).subscribe((response: any) => {
        resolve(response);
      }, (error) => {
        this.errorService.getError(error).then((tryAgain: boolean) => {
          reject(tryAgain);
        });
      });
    });
  }

  firmarProyectosSemana(idEmpresa: number, fecha: string, idUsuarioEmail?: number, idDepartamento?: number, year?: any, idSemana?: any, codigo?: string): Promise<{ ok: boolean, mensaje: string }> {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${this.apiRest}/proyectos/firmarproyectosSemana`, { idEmpresa, fecha, idUsuarioEmail, idDepartamento, year, idSemana, codigo }, { headers: await this.ponerEncabezados() }).subscribe((response: any) => {
        resolve(response);
      }, (error) => {
        this.errorService.getError(error).then((tryAgain: boolean) => {
          reject(tryAgain);
        });
      });
    });
  }
  firmarProyectoSemana(idEmpresa: number, fecha: string, idUsuarioEmail?: number, idDepartamento?: number, year?: any, idSemana?: any, idProyecto?: number, codigo?: string): Promise<{ ok: boolean, mensaje: string }> {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${this.apiRest}/proyectos/firmarproyectoSemana`, { idEmpresa, fecha, idUsuarioEmail, idDepartamento, year, idSemana, idProyecto, codigo }, { headers: await this.ponerEncabezados() }).subscribe((response: any) => {
        resolve(response);
      }, (error) => {
        this.errorService.getError(error).then((tryAgain: boolean) => {
          reject(tryAgain);
        });
      });
    });
  }

  getProyectosMensuales(idEmpresa: number, year: number, mes: number, idUsuarioEmail?: number, idDepartamento?: number): Promise<{ ok: boolean, proyectos: any[] }> {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${this.apiRest}/proyectos/proyectosMensuales`, { idEmpresa, year, mes, idUsuarioEmail, idDepartamento }, { headers: await this.ponerEncabezados() }).subscribe((response: any) => {
        resolve(response);
      }, (error) => {
        this.errorService.getError(error).then((tryAgain: boolean) => {
          reject(tryAgain);
        });
      });
    });
  }

  firmarProyectosMes(idEmpresa: number, fecha: string, idUsuarioEmail?: number, idDepartamento?: number, year?: any, mes?: any, codigo?: string): Promise<{ ok: boolean, mensaje: string }> {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${this.apiRest}/proyectos/firmarProyectosMes`, { idEmpresa, fecha, idUsuarioEmail, idDepartamento, year, mes, codigo }, { headers: await this.ponerEncabezados() }).subscribe((response: any) => {
        resolve(response);
      }, (error) => {
        this.errorService.getError(error).then((tryAgain: boolean) => {
          reject(tryAgain);
        });
      });
    });
  }
  firmarProyectoMes(idEmpresa: number, fecha: string, idUsuarioEmail?: number, idDepartamento?: number, year?: any, mes?: any, idProyecto?: number, codigo?: string): Promise<{ ok: boolean, mensaje: string }> {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${this.apiRest}/proyectos/firmarProyectoMes`, { idEmpresa, fecha, idUsuarioEmail, idDepartamento, year, mes, idProyecto, codigo }, { headers: await this.ponerEncabezados() }).subscribe((response: any) => {
        resolve(response);
      }, (error) => {
        this.errorService.getError(error).then((tryAgain: boolean) => {
          reject(tryAgain);
        });
      });
    });
  }

  getProyectosAnuales(idEmpresa: number, year: number, idUsuarioEmail?: number, idDepartamento?: number): Promise<{ ok: boolean, proyectos: any[] }> {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${this.apiRest}/proyectos/proyectosAnuales`, { idEmpresa, year, idUsuarioEmail, idDepartamento }, { headers: await this.ponerEncabezados() }).subscribe((response: any) => {
        resolve(response);
      }, (error) => {
        this.errorService.getError(error).then((tryAgain: boolean) => {
          reject(tryAgain);
        });
      });
    });
  }

  firmarProyectosAnyo(idEmpresa: number, fecha: string, idUsuarioEmail?: number, idDepartamento?: number, year?: any, codigo?: string): Promise<{ ok: boolean, mensaje: string }> {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${this.apiRest}/proyectos/firmarProyectosAnyo`, { idEmpresa, fecha, idUsuarioEmail, idDepartamento, year, codigo }, { headers: await this.ponerEncabezados() }).subscribe((response: any) => {
        resolve(response);
      }, (error) => {
        this.errorService.getError(error).then((tryAgain: boolean) => {
          reject(tryAgain);
        });
      });
    });
  }
  firmarProyectoAnyo(idEmpresa: number, fecha: string, idUsuarioEmail?: number, idDepartamento?: number, year?: any, idProyecto?: number, codigo?: string): Promise<{ ok: boolean, mensaje: string }> {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${this.apiRest}/proyectos/firmarProyectoAnyo`, { idEmpresa, fecha, idUsuarioEmail, idDepartamento, year, idProyecto, codigo }, { headers: await this.ponerEncabezados() }).subscribe((response: any) => {
        resolve(response);
      }, (error) => {
        this.errorService.getError(error).then((tryAgain: boolean) => {
          reject(tryAgain);
        });
      });
    });
  }

        /***************************************************************************************
                                              BLOQUE SOLICITUDES
        **************************************************************************************/
  getSolicitudesPorResponsable(idResponsable, estado, pagina, registros, tipo, empleado) {
    let idResp = this.globalService.permisos.rol == 'ADMIN' ? 0 : idResponsable
    let cadena = `${this.apiRest}/usuarios/getSolicitudesResponsable/${idResp}/${estado}/${pagina}/${registros}/${tipo}`;
    if (empleado != '') cadena += `/${empleado}`;
    return this.http.get<{ ok: boolean, solicitudes: Solicitud[], contador: number }>(cadena, { headers: this.ponerEncabezados() }).toPromise();
  }

  aceptarRechazarAusencia(datos) {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${this.apiRest}/ausencias/altaOmodAusencias`, datos, { headers: this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  aceptarRechazarProlongaciones(datos) {
    return this.http.post(`${this.apiRest}/prorrogasJornada/aceptaOrechazaProrrogas`, datos, { headers: this.ponerEncabezados() })
  }

  aceptarRechazarProlongaciones1(datos) {
    return this.http.post(`${this.apiRest}/prorrogasJornada/aceptarORechazarProrrogasXFecha`, datos, { headers: this.ponerEncabezados() })
  }

  validaIncidencias(datos) {
    return this.http.post(`${this.apiRest}/incidencias/validarVariasIncidencias`, datos, { headers: this.ponerEncabezados() })
  }

  obtenerIncidenciaCompletaXId(id) {
    return this.http.get(`${this.apiRest}/incidencias/obtenerIncidenciaCompletaXId/${id}`, { headers: this.ponerEncabezados() })
  }

  obtenerInfoCambioTurno(idSolicitud: number) {
    return this.http.get<{ ok: boolean, cambio: { colorTurno1: string, colorTurno2: string, horasTurno1: string, horasTurno2: string, nombreEmpleado1: string, nombreEmpleado2: string, turno1: string, turno2: string } }>
    (`${this.apiRest}/cambioturno-empleados/cambioDet/${idSolicitud}`, { headers: this.ponerEncabezados() }).toPromise();
  } 

  

        /***************************************************************************************
                                              BLOQUE TIPOS DE FICHAJES
        **************************************************************************************/
  getTiposFichajeEmpresa(): Promise<{ ok: boolean, tiposFichaje: any[] }> {
    return new Promise(async (resolve, reject) => {
      this.http.get(`${this.apiRest}/tiposFichaje/obtenerTiposFichajeEmpresa/false`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  getTiposFichajeEmpresaConDuracion(): Promise<{ ok: boolean, tiposFichajeConDuracion: any[] }> {
    return new Promise(async (resolve, reject) => {
      this.http.get(`${this.apiRest}/tiposFichaje/obtenerTiposFichajeEmpresaConDuracion/false`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  getTiposFichajeEmpresaConInternos(idEmpresa: number): Promise<{ ok: boolean, tiposFichaje: any[] }> {
    return new Promise(async (resolve, reject) => {
      this.http.get(`${this.apiRest}/tiposFichaje/obtenerTiposFichajeEmpresaConInternos/false`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  getTiposIncidenciaEmpresa(idEmpresa: number): Promise<{ ok: boolean, tiposIncidencia: any[] }> {
    return new Promise(async (resolve, reject) => {
      this.http.get(`${this.apiRest}/tiposIncidencia/obtenerTiposIncidenciaEmpresa`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  getTipoFichaje(idTipoFichaje: number): Promise<{ ok: boolean, tipoFichaje: any }> {
    return new Promise(async (resolve, reject) => {
      this.http.get(`${this.apiRest}/tiposFichaje/obtenerTipoFichaje/${idTipoFichaje}`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  crearTipoFichaje(tipoFichaje: any): Promise<{ ok: boolean, usuario: any }> {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${this.apiRest}/tiposFichaje/crearTipoFichaje`, tipoFichaje, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  crearTipoIncidencia(tipoIncidencia: any): Promise<{ ok: boolean, usuario: any }> {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${this.apiRest}/tiposIncidencia/crearTipoIncidencia`, tipoIncidencia, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  modificarTipoFichaje(tipoFichaje: any): Promise<{ ok: boolean, msg: any }> {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${this.apiRest}/tiposFichaje/modificarTipoFichaje`, tipoFichaje, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  modificarTipoIncidencia(tipoIncidencia: any): Promise<{ ok: boolean, usuario: any }> {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${this.apiRest}/tiposIncidencia/modificarTipoIncidencia`, tipoIncidencia, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  cambiarEstadoTipoFichaje(idTipoFichaje: number): Promise<{ ok: boolean }> {
    return new Promise(async (resolve, reject) => {
      this.http.get(`${this.apiRest}/tiposFichaje/cambiarEstadoTipoFichaje/${idTipoFichaje}`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  eliminarTipoFichaje(idTipoFichaje: number): Promise<{ ok: boolean, msg: string }> {
    return new Promise(async (resolve, reject) => {
      this.http.get(`${this.apiRest}/tiposFichaje/eliminarTipoFichaje/${idTipoFichaje}`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  cambiarEstadoTipoIncidencia(idTipoIncidencia: number): Promise<{ ok: boolean }> {
    return new Promise(async (resolve, reject) => {
      this.http.get(`${this.apiRest}/tiposIncidencia/cambiarEstadoTipoIncidencia/${idTipoIncidencia}`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  reordenarFichajes(tiposFichajeEmpresa: any[]): Promise<{ ok: boolean, tiposFichaje: any }> {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${this.apiRest}/tiposFichaje/reordenarTiposFichaje`, tiposFichajeEmpresa, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

    /***************************************************************************************
                          BLOQUE USUARIOS Y CAMBIOS TURNO
    **************************************************************************************/
  comprobarVersion(version: any) {
    return new Promise(async (resolve, reject) => {
      this.http.get(`${this.apiRest}/version-app/comprobarVersion/${version}`)
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  getCompruebaJefe(idDepartamento: any) {
    return new Promise(async (resolve, reject) => {
      this.http.get(`${this.apiRest}/departamentos/getJefeDepartamento/${idDepartamento}`,{ headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }




  aplicarAccionesMasivas(datos: any) {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${this.apiRest}/usuarios/aplicarAccionesMasivas`, datos, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  getUsuariosEmpresa(): Promise<{ ok: boolean, usuarios: any[] }> {
    return new Promise(async (resolve, reject) => {
      this.http.get(`${this.apiRest}/empresas/obtenerUsuariosEmpresa`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  getContactos(): Promise<{ ok: boolean, usuarios: any[] }> {
    return new Promise(async (resolve, reject) => {
      this.http.get(`${this.apiRest}/empresas/obtenerContactos`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  getUsuariosEmpresaPendientesValidar(): Promise<{ ok: boolean, usuarios: any[] }> {
    return new Promise(async (resolve, reject) => {
      this.http.get(`${this.apiRest}/empresas/obtenerUsuariosEmpresaPendientesValidar`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  getUsuarioParaAdmin(idUsuario, idCentro): Promise<{ ok: boolean, usuario: any }> {
    return new Promise(async (resolve, reject) => {
      this.http.get(`${this.apiRest}/usuarios/obtenerInfoCompletaUsuario/${idUsuario}`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  getImagenPerfilUsuario(idUsuario: number): Promise<{ ok: boolean, urlImagenPerfil: string }> {
    return new Promise(async (resolve, reject) => {
      this.http.get(`${this.apiRest}/usuarios/obtenerImagenPerfilUsuario`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  getUsuarioPendienteValidar(idUsuarioPV: number): Promise<{ ok: boolean, usuario: any }> {
    return new Promise(async (resolve, reject) => {
      this.http.get(`${this.apiRest}/usuarios/usuarioPendienteValidar/${idUsuarioPV}`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  registrarUsuario(usuarioEmpresa: any): Promise<{ ok: boolean, msg: string }> {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${this.apiRest}/usuarios/registrarUsuario`, usuarioEmpresa, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  modificarUsuario(usuario: any): Promise<{ ok: boolean, msg?: string }> {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${this.apiRest}/usuarios/modificarUsuario`, usuario, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  modificarUsuarioDesdeAdmin(usuarioEmpresa: any): Promise<{ ok: boolean, msg: string }> {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${this.apiRest}/usuarios/modificarUsuarioDesdeAdmin`, usuarioEmpresa, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  modificarUsuarioPendienteValidar(usuarioPendienteValidar: any): Promise<{ ok: boolean, msg: string }> {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${this.apiRest}/usuarios/modificarUsuarioPendienteValidar`, usuarioPendienteValidar, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  reenviarCorreoUsuarioPendienteValidar(idUsuarioPV: number): Promise<{ ok: boolean, usuario: any }> {
    return new Promise(async (resolve, reject) => {
      this.http.get(`${this.apiRest}/usuarios/reenviarCorreoUsuarioPendienteValidar/${idUsuarioPV}`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  eliminarUsuarioPendienteValidar(idUsuarioPV: number): Promise<{ ok: boolean, usuario: any }> {
    return new Promise(async (resolve, reject) => {
      this.http.get(`${this.apiRest}/usuarios/eliminarUsuarioPV/${idUsuarioPV}`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  subirImagenPerfil(file: any): Promise<{ ok: boolean, url: string, msg?: string }> {

    return new Promise(async (resolve, reject) => {
      let formData = new FormData();
      let xhr = new XMLHttpRequest();

      formData.append("file", file, file.name);
      let scope = this;
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 201) {
            resolve(JSON.parse(xhr.response));
          } else {
            scope.errorService.getError(xhr.status);
            reject(xhr.response);
          }
        }
      };

      let url = `${this.apiRest}/usuarios/subirImagenPerfilUsuario`;

      xhr.open("POST", url, true);
      xhr.setRequestHeader("Authorization", this.globalService.token);
      xhr.send(formData);
    });
  }

  cambiarEstadoUsuarioEmpresa(idUsuario: number): Promise<{ ok: boolean }> {
    return new Promise(async (resolve, reject) => {
      this.http.get(`${this.apiRest}/usuarios/cambiarEstadoUsuarioEmpresa/${idUsuario}`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  cambiarColorUsuario(idUsuario: number, codigoColor: string): Promise<{ ok: boolean }> {
    return new Promise(async (resolve, reject) => {
      this.http.get(`${this.apiRest}/usuarios/cambiarColorUsuario/${idUsuario}/${codigoColor}`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  eliminarUsuarioDeUnaEmpresa(idUsuario: number): Promise<{ ok: boolean }> {
    return new Promise(async (resolve, reject) => {
      this.http.get(`${this.apiRest}/usuarios/eliminarUsuarioDeUnaEmpresa/${idUsuario}`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  subirCSVAltaMasivaUsuarios(file: any): Promise<{ ok: boolean, usuDatosErroneos: any[], usuariosTotales: number, logs: { ok: boolean, numInserciones: number, usuPVInsertados: any[], usuPVNoInsertados: any[], usuEmpInsertados: any[], usuEmpNoInsertados: any[] } }> {
    return new Promise(async (resolve, reject) => {
      let formData = new FormData();
      let xhr = new XMLHttpRequest();

      formData.append("file", file, file.name);
      let scope = this;
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 201) {
            resolve(JSON.parse(xhr.response));
          } else {
            scope.errorService.getError(xhr.status);
            reject(xhr.response);
          }
        }
      };

      let url = `${this.apiRest}/usuarios/subirCsvUsuarios`;

      xhr.open("POST", url, true);
      xhr.setRequestHeader("Authorization", this.globalService.token);
      xhr.send(formData);
    });
  }

  // GESTION DEL CAMBIO DE TURNO 

  obtenerCambiosSolicitados() {
    return new Promise(async (resolve, reject) => {
      this.http.get(`${this.apiRest}/cambioturno-empleados/getSolicitudesCambio`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }
  obtenerHorarioCambioTurno(datos: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${this.apiRest}/cambioturno-empleados/obtenerHorarioCambioTurno`, datos, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  intercambiarTurnoEmpleados(datos: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${this.apiRest}/cambioturno-empleados/altaCambio`, datos, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  getDetalleCambio(id) {
    return new Promise(async (resolve, reject) => {
      this.http.get(`${this.apiRest}/cambioturno-empleados/getDetalleCambio/${id}`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

        /***************************************************************************************
                                              BLOQUE VACACIONES
        **************************************************************************************/
  getVacacionesEmpleados(datos: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${this.apiRest}/horario/getVacacionesEmpleados`, datos, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  altaOmodAusencias(datos: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${this.apiRest}/ausencias/altaOmodAusencias`, datos, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }
  eliminarAusencia(idAusencia,idCentro): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.http.get(`${this.apiRest}/ausencias/eliminarAusencias/false/${idAusencia}/${idCentro}`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }


  // TELETRABAJO
  solicitarTeletrabajo(dato){
   return this.http.post(`${this.apiRest}/teletrabajo/solicitarTeletrabajo/false`,dato,{headers:this.ponerEncabezados()});
  }

  aprobarSolicitudTeletrabajo(id,accion){
  return  this.http.get(`${this.apiRest}/teletrabajo/aprobarSolicitudTeletrabajo/false/${id}/${accion}`,{headers:this.ponerEncabezados()})
  }

  cancelarTeletrabajo(dato){
  return  this.http.post(`${this.apiRest}/teletrabajo/cancelarTeletrabajo/false`,dato,{headers:this.ponerEncabezados()})
  }

  getSolicitudes(dato){
    return this.http.post(`${this.apiRest}/teletrabajo/getSolicitudes/false`,dato,{headers:this.ponerEncabezados()})
  }

  getDetalle(id){
    return  this.http.get(`${this.apiRest}/teletrabajo/getDetalle/false/${id}`,{headers:this.ponerEncabezados()})
  }

  getSelectoresMarino(){
    // 
    return  this.http.get(`${this.apiRest}/tickets/getSelectores/mundoMarino`,{headers:this.ponerEncabezados()})
  }



  
}
