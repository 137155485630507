import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FiltroPipe } from './filtro.pipe';
import { FiltroEstrictoPipe } from './filtro-estricto.pipe';
import { FiltroTipoPipe } from './filtroTipo.pipe';
import { FiltroSolicitudes } from './filtro-solicitudes.pipe';
import { SanitazerPipe } from './sanitazer.pipe';



@NgModule({
  declarations: [FiltroPipe, FiltroEstrictoPipe, FiltroEstrictoPipe,FiltroTipoPipe,FiltroSolicitudes, SanitazerPipe],
  imports: [
    CommonModule
  ], exports:[FiltroPipe, FiltroEstrictoPipe,FiltroTipoPipe,FiltroSolicitudes, SanitazerPipe]
})
export class PipesModuleModule { }
