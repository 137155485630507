import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import es from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { OpenNativeSettings } from '@ionic-native/open-native-settings/ngx';

import { MatExpansionModule } from '@angular/material/expansion';

import { SocialSharing } from '@ionic-native/social-sharing/ngx';

registerLocaleData(es);


// localizacion





// sentry

import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";
import { ComponentsModule } from './components/components.module';
import { DatePicker } from '@ionic-native/date-picker/ngx';
import { PipesModuleModule } from './pipes/pipes-module.module';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { File } from '@ionic-native/file/ngx';
import { PdfViewerModule } from 'ng2-pdf-viewer';

// Firebase
import { AngularFireModule } from "@angular/fire";
import { AngularFireStorageModule } from '@angular/fire/storage';


Sentry.init({
  dsn: "https://d6b8b6a6d7bf4a72ba1340cdd8c685c7@o509626.ingest.sentry.io/5614488",
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ["localhost", "https://fichajes-e917e.web.app/"],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  tracesSampleRate: 1.0,
});

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    ComponentsModule,
    IonicModule.forRoot({ mode: "ios" }),
    AppRoutingModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    BrowserAnimationsModule,
    MatExpansionModule,
    PipesModuleModule,
    PdfViewerModule,
    AngularFireModule.initializeApp(environment.firebase),    
    AngularFireStorageModule
  ],
  providers: [    
    StatusBar,
    SplashScreen,
    DatePicker,
    OpenNativeSettings,
    SocialSharing,
    FileOpener,
    File,
    
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: 'es-ES' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
