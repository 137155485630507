import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtroEstricto'
})
export class FiltroEstrictoPipe implements PipeTransform {

  transform( arreglo: any[], id: number,columna: string ): any[] {
    if ( !id ) {
      return arreglo; 
    }
        
    return arreglo.filter( item => {
      if (+item[columna] == id){
        return item;
      }    
    });
  }

}
