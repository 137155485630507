export class Marcador {

    public lat: number;
    public lng: number;

    public titulo = 'Sin Título';
    public desc = 'Sin Descripción';

    constructor(lat: number, lng: number) {
        this.lat = lat;
        this.lng = lng;
    }

}

export class porcentajeAvisos{
  codigoUnicoAviso: number;
  porcentajeAviso:number;
  tiempoAtrabajar:number;
  tiempoDeAviso:number;
  tiempoTrabajado:number;
  unidadMedidaAviso:string;
  seteado:boolean;

  constructor(){
    this.codigoUnicoAviso=null
    this.porcentajeAviso=null
    this.tiempoAtrabajar=null
    this.tiempoDeAviso=null
    this.tiempoTrabajado=null
    this.unidadMedidaAviso=null
    this.seteado = null;
  }
}

export class filtroEstadisticas {
   fechaInicio: string;
   fechaFin: string;
   fecha?:string;
   idDepartamento?:number;
   idEmpleado?:any[];
   total:number;
   app:boolean;
  

      constructor(){
        this.fechaInicio=null;
        this.fechaFin=null;
        this.idDepartamento=null;
        this.idEmpleado=null;
        this.fecha=null;
        this.app=null;
      }
}

export class filtroEstadisticasTareas {
  fechaInicio: string;
  fechaFin: string;
  empleados:any[];
  total:number;
  tipoValidacion?:boolean;
     constructor(){
       this.fechaInicio=null;
       this.fechaFin=null;
       this.empleados=[];
       this.total=null;
     }
}

export class Permisos {
  acceso:boolean;
  ok:boolean;
  rol:string;
  queVer:any[];

  constructor(){
    this.acceso=false;
    this.ok=false;
    this.rol=null;
    this.queVer=[];
  }
}


export class EstadisticaSemanalDiaria {
  tiempoEfectivo: number;
  TiempoTotal: number;
  dia: string;
  diferencia: number;
  entrada: any
  fecha: Date;
  horasTurnos: number;
  idEmpleado: number;
  nombre: string;
  salida: any
  turno: string;
  idTurno:number;
  flexibilidadSemanal:number;
  tiempoEfectivoCalculado:number;

  constructor(){
    this.tiempoEfectivo=null;
    this.TiempoTotal=null;
    this.dia=null;
    this.diferencia=null;
    this.entrada=null;
    this.fecha=null;
    this.horasTurnos=null;
    this.idEmpleado=null;
    this.nombre=null;
    this.salida=null;
    this.turno=null;
    this.idTurno= null;
    this.flexibilidadSemanal=null;
    this.tiempoEfectivoCalculado=null;
  }
}

export class EstadisticaMensual {
  IdEmpleado: number;
  tiempoEfectivo: number;
  TiempoTotal: number;
  diferencia: number;
  horasTurnos: number;
  nombre: string;
  semanaNum: number;
  flexibilidadSemanal:number;
  tiempoEfectivoCalculado:number;
  horasTurnos1: number;

  constructor(){
    this.IdEmpleado=null;
    this.tiempoEfectivo=null;
    this.TiempoTotal=null;
    this.diferencia=null;
    this.horasTurnos=null;
    this.nombre=null;
    this.semanaNum=null;
    this.flexibilidadSemanal=null;
    this.tiempoEfectivoCalculado=null;
  }
}

export class EstadisticaAnual{
  IdEmpleado?: number;
  tiempoEfectivo?: number;
  TiempoTotal?: number;
  diferencia?: number;
  horasTurnos?: number;
  mes?: string;
  mesNum?: number;
  nombre?: string;
  flexibilidadSemanal:number;
  tiempoEfectivoCalculado:number;

  constructor(){
    this.IdEmpleado=null;
    this.tiempoEfectivo=null;
    this.TiempoTotal=null;
    this.diferencia=null;
    this.horasTurnos=null;
    this.mes=null;
    this.mesNum=null;
    this.nombre=null;
    this.flexibilidadSemanal=null;
    this.tiempoEfectivoCalculado=null;
  }

}

