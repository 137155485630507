import { Component, HostListener, OnInit } from '@angular/core';
import { AlertController, ModalController, Platform } from '@ionic/angular';
import { UiService } from 'src/app/api/ui.service';
import { BackButtonService } from 'src/app/api/back-button.service';
import { GlobalService } from 'src/app/services/global.service';
import { ApiService } from 'src/app/api/api.service';

@Component({
  selector: 'app-alta-masiva',
  templateUrl: './alta-masiva.page.html',
  styleUrls: ['./alta-masiva.page.scss'],
})
export class AltaMasivaPage implements OnInit {

  idEmpresa: number;
  file: any = null;
  usersFailed: any[] = [];
  anotherModalOpen: boolean = false;

  constructor(public uiServe: UiService,
    private modalController: ModalController,
    private apiService: ApiService,
    private platform: Platform,
    private backButtonServe: BackButtonService,
    private globalService: GlobalService,
    private alertController: AlertController
  ) { }

  async ngOnInit() {

    const modalState = {
      modal: true,
      desc: 'fake state for our modal'
    };
    history.pushState(modalState, null);

    this.idEmpresa = this.globalService.empresaSeleccionada.id;
  }

  ionViewDidEnter() {
    this.backButtonServe.subscription = this.platform.backButton.subscribeWithPriority(5, () => {
      this.modalController.dismiss();
      this.backButtonServe.subscription.unsubscribe();
    });
  }

  async filePlantilla(files: any) {

    if (files && files[0]) {

      this.file = files[0];
      //if (file.type.contains('image/')) {
      const reader = new FileReader()
      reader.readAsDataURL(this.file);
      //}
    }
  }

  subirCSVUsuarios() {
    this.apiService.subirCSVAltaMasivaUsuarios(this.file).then(async (data) => {
     
      this.getUsuarios();
      this.modalController.dismiss();

      let header: string = `Se han insertado ${data.logs.numInserciones} de ${data.usuariosTotales}`;

      if (data.usuDatosErroneos.length != 0) {
        let message: string = '';
        for (let i = 0; i < data.usuDatosErroneos.length; i++) {
          const infoFallo = data.usuDatosErroneos[i];
          message += `<hr><b>${infoFallo.usuario.nombre}</b> - ${infoFallo.error}`
        }

        const alert = await this.alertController.create({
          cssClass: 'my-custom-class',
          header: header,
          message: message,
          buttons: [
            {
              text: 'Volver',
              role: 'cancel',
              cssClass: `alert-button-${this.uiServe.colorAplicacionSecond}`,
              handler: () => { }
            }, {
              text: 'Confirmar',
              cssClass: `alert-button-${this.uiServe.colorAplicacion}`,
              handler: () => {
                this.alertController.dismiss();
              }
            }
          ]
        });
        await alert.present();

      } else if (data.usuDatosErroneos.length == 0 && data.logs.numInserciones != data.usuariosTotales) {

        const alert = await this.alertController.create({
          cssClass: 'my-custom-class',
          header: header,
          message: 'Los usuarios que no se han insertado ya están pendientes de validar o dados de alta en la empresa.',
          buttons: [
            {
              text: 'Volver',
              role: 'cancel',
              cssClass: `alert-button-${this.uiServe.colorAplicacionSecond}`,
              handler: () => { }
            }, {
              text: 'Confirmar',
              cssClass: `alert-button-${this.uiServe.colorAplicacion}`,
              handler: () => {
                this.alertController.dismiss();
              }
            }
          ]
        });

        await alert.present();

      }
    });
  }

  async getUsuarios() {
    Promise.all([this.apiService.getUsuariosEmpresa(),
    this.apiService.getUsuariosEmpresaPendientesValidar()]).then((values) => {

      this.apiService.usuariosEmpresa = [];
      this.apiService.usuariosNoActivos = [];

      values[0].usuarios.map((usuario) => {
        if (usuario.activo) {
          this.apiService.usuariosEmpresa.push(usuario);
        } else {
          this.apiService.usuariosNoActivos.push(usuario);
        }
      });

      this.apiService.usuariosEmpresaPendientesValidar = values[1].usuarios;

      this.apiService.usuariosEmpresaParaFiltrar = this.apiService.usuariosEmpresa.slice();
      this.apiService.usuariosEmpresaPendientesValidarParaFiltrar = this.apiService.usuariosEmpresaPendientesValidar.slice();
      this.apiService.usuariosNoActivosParaFiltrar = this.apiService.usuariosNoActivos.slice();

    }, (tryAgain) => {
     
      if (tryAgain) {
        this.getUsuarios();
      }
    });

  }

  ngOnDestroy() {
    if (window.history.state.modal) {
      history.back();
    }
  }

  @HostListener('window:popstate', ['$event'])
  closeModal() {
    if (!this.anotherModalOpen) {
      this.modalController.dismiss();
    }
  }

}
