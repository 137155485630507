import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-detalle-dia',
  templateUrl: './detalle-dia.component.html',
  styleUrls: ['./detalle-dia.component.scss'],
})
export class DetalleDiaComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
