import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { UiService } from 'src/app/api/ui.service';

@Component({
  selector: 'app-filtro-horario',
  templateUrl: './filtro-horario.component.html',
  styleUrls: ['./filtro-horario.component.scss'],
})
export class FiltroHorarioComponent implements OnInit {

  constructor(private popoverCtrl: PopoverController, public uiServe: UiService) { }

  ngOnInit() { }

  filtrar(param: string, color: string) {
    this.popoverCtrl.dismiss({ filtro: param, color });
  }

}
