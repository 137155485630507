import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtroTipo'
})
export class FiltroTipoPipe implements PipeTransform {

  transform( arreglo: any[], texto: string,columna: string ): any[] {
    if ( texto === '' ) {
      return arreglo; 
    }
    texto = texto.toUpperCase();  
    return arreglo.filter( item => {
      if (item[columna]==texto){
        return item;
      }    
    });
  }

}