import { Injectable } from '@angular/core';
import { BackButtonEmitter } from '@ionic/angular/providers/platform';
import {

  Platform,
} from "@ionic/angular";

@Injectable({
  providedIn: 'root'
})
export class BackButtonService {
  subscription: any;
  subscriptionModal: any;
  constructor(private platform:Platform) { }

  suscribirse(){
    this.subscription = this.platform.backButton.subscribe(
      () => {
        navigator["app"].exitApp();
      }
    );
  }
}
