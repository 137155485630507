import { Injectable } from "@angular/core";
import OneSignal from 'onesignal-cordova-plugin';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ModalController, NavController } from "@ionic/angular";
import { DetalleNotiComponent } from "../components/detalle-noti/detalle-noti.component";
import { UiService } from "./ui.service";

@Injectable({
  providedIn: "root",
})
export class PushService {
  userId: string;
  idOneSignalApp: string = "09a2fe5c-29d9-4fcb-bdb4-caed8de6cb11";

  constructor(
    ///private oneSignal: OneSignal,
    private http: HttpClient,
    private navCtrl: NavController,
    private modalCtrl: ModalController,
    private uiServe:UiService
    
  ) {}
  configuracionInicial(){
    try {      
      console.log('Pasa aKI');
      
      OneSignal.setAppId(this.idOneSignalApp);
      OneSignal.setNotificationOpenedHandler(function(jsonData) {
          console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
      });
    
      // Prompts the user for notification permissions.
      //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 7) to better communicate to your users what notifications they will get.
      OneSignal.promptForPushNotificationsWithUserResponse(function(accepted) {
          console.log("User accepted notifications: " + accepted);
      });
    } catch (error) {
      console.log('Error OneSignal', error);
      
    }
  }
  async getUserIdOneSignal() {
    try {      
      return new Promise((resolve, reject)=>{
  
        
        OneSignal.getDeviceState((stateChanges) => {
          console.log(
            "OneSignal getDeviceState: " + JSON.stringify(stateChanges)
          );
          if (stateChanges && stateChanges.hasNotificationPermission) {
            this.userId = stateChanges.userId;
            resolve (this.userId)
          } else {
            resolve('')
          }
        }); 
      })
    } catch (error) {
      console.log('Error', error);
      
    }
    
    
  }
  /*
  configuracionInicial() {
    this.oneSignal.startInit(this.idOneSignalApp, "700557615021");

    this.oneSignal.inFocusDisplaying(
      this.oneSignal.OSInFocusDisplayOption.InAppAlert
    );

    this.oneSignal.handleNotificationReceived().subscribe((noti) => {
      // do something when notification is received
    });

    this.oneSignal.handleNotificationOpened().subscribe(async (noti) => {
      this.uiServe.modal = await this.modalCtrl.create({
        component: DetalleNotiComponent,
        cssClass: "full-screen",
        componentProps:{
          notificacionSignal: noti
        }
      });

      this.uiServe.modal.present();    
      // do something when a notification is opened
    });

    this.oneSignal.endInit();
  }

  async getUserIdOneSignal() {
    // Obtener ID del suscriptor
    const info = await this.oneSignal.getIds();
    this.userId = info.userId;
    return info.userId;
  }
*/
  async recogerModeloDispositivo(id): Promise<any> {
    return this.http.get<any>(
      `https://onesignal.com/api/v1/players/${id}?app_id=${this.idOneSignalApp}`
    );
  }
}
