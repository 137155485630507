import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController, ModalController, PopoverController } from '@ionic/angular';

import { UiService } from 'src/app/api/ui.service';



@Component({
  selector: 'app-acciones-horario',
  templateUrl: './acciones-horario.component.html',
  styleUrls: ['./acciones-horario.component.scss'],
})
export class AccionesHorarioComponent implements OnInit {
  @Input('menu') menu:any = null;
  constructor(private popoverCtrl: PopoverController,
    public uiServe: UiService,
    public modalCtrl: ModalController,
    private menuController: MenuController,
    
    private router:Router) { }

  ngOnInit() {
  }

  addVacaciones(){
    this.router.navigateByUrl(`pages/vacaciones`);
    this.popoverCtrl.dismiss();
    this.menuController.close();
  }

  addPermisos(){
    this.router.navigateByUrl(`pages/excepciones`);
    this.popoverCtrl.dismiss();
    this.menuController.close();
  }

  addProlongaciones(){
    this.router.navigateByUrl(`pages/prorrogas`);
    this.popoverCtrl.dismiss();
    this.menuController.close();
  }

  addCambioTurno(){
    this.router.navigateByUrl(`pages/cambio-turno`);
    this.popoverCtrl.dismiss();
    this.menuController.close();
  }

}
