import { Component, Input, OnInit } from '@angular/core';

import { ModalController } from '@ionic/angular';
import { UiService } from 'src/app/api/ui.service';

@Component({
  selector: 'app-detalle-noti',
  templateUrl: './detalle-noti.component.html',
  styleUrls: ['./detalle-noti.component.scss'],
})
export class DetalleNotiComponent implements OnInit {
  @Input('notificacion') notificacion:any = null;
  @Input('notificacionSignal') notificacionSignal:any = null;
  constructor(private modalCtrl:ModalController, public uiServe:UiService) { }

  ngOnInit() {
  }


  cerrar(){
    this.modalCtrl.dismiss();
  }

  devolverNombre(nombre:string){
    if(nombre.includes('AUSENCIAS')){
      return 'PERMISO';
    }
    else if(nombre.includes('PRORROGA')){
      return 'PROLONGACIÓN';
    }
    else if(nombre.includes('CAMBIOTURNO')){
      return 'CAMBIO TURNO';
    }
    else if(nombre.includes('VACACIONES')){
      return 'VACACIONES';
    }
    else{
      return nombre;
    }
  }

}
