import { DatePipe } from '@angular/common';
import { Component, HostListener, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AlertController, NavController, Platform, LoadingController, ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/api/api.service';
import { BackButtonService } from 'src/app/api/back-button.service';
import { UiService } from 'src/app/api/ui.service';
import { GlobalService } from 'src/app/services/global.service';
import { environment } from 'src/environments/environment';
import { ModalDepartamentoPage } from '../../departamentos/modal-departamento/modal-departamento.page';


@Component({
  selector: 'app-perfil-admin',
  templateUrl: './perfil-admin.page.html',
  styleUrls: ['./perfil-admin.page.scss'],
})
export class PerfilAdminPage implements OnInit {

  urlArchivos: string = environment.urlArchivos;
  @Input() isPage: boolean;
  @Input() usuario: any = null;

  formNuevoMod: boolean = true;
  imagenPerfilMod: boolean = true;
  dni_nie_cif_check: any = true;
  nass_check: any = true;
  formOriginal: any;
  file: any = null;
  urlImagenPerfil: string | boolean = false;
  formNuevo: any;
  anotherModalOpen: boolean = false;
  departamentos: any[];
  anteriorIdDepartamento: any;
  anteriorNombreDepartamento: any;
  selectedTextDepartamento: string = 'Sin departamento';
  anteriorEstadoJefeDepartamento: any;
  activo: any;
  mostrarHacerFoto: boolean = false;
  perfilPropio: boolean;
  centrosUsuario: any[]=[];
  selectedTextCentro: string = '';
  deshabilitadoForm: boolean =false;

  formPerfil: FormGroup;
  responsables: any[]=[];
  selectedTextResponsable: string='';
  clasificaciones: any[]=[];
 
  secciones: any[]=[];
  selectedTextClasificacion: string ='';
  selectedTextSeccion: string = '';

  constructor(
    private datePipe:DatePipe,
    public uiServe: UiService,
    public globalService: GlobalService,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private navCtrl: NavController,
    private platform: Platform,
    private backButtonServe: BackButtonService,
    private apiService: ApiService,
    private loadingCtrl: LoadingController,
    //private apiService: apiService
    ) {
    if (this.platform.is('android') || this.platform.is('ios')) {
      this.mostrarHacerFoto = true;
    }
  }

  crearFormEmpleado() {
    if(!!this.usuario){
      this.deshabilitadoForm = this.globalService.empresaSeleccionada.urlSincronizacionERP == null ? false : true;
      if (this.deshabilitadoForm) {
        return new FormGroup({
          idUsuarioEmail: new FormControl(),
          idUsuarioEmpresa: new FormControl(),
          codigoERP: new FormControl({ value: '', disabled: true }),
          nombreUsuario: new FormControl({ value: '', disabled: true }, Validators.required),
          emailUsuario: new FormControl('', [Validators.required,Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]),
          dni: new FormControl(),
          numSegSocial: new FormControl(),
          idCentro: new FormControl(),
          telefono: new FormControl(),
          telefonoPers: new FormControl(),
          cargo: new FormControl(),
          rol: new FormControl('ESTANDAR'),
          horario: new FormControl(),
          idDepartamento: new FormControl(),
          urlImagenPerfil: new FormControl(false),
          puedoSerResponsable: new FormControl(false),
          recursosHumanos: new FormControl(false),
          responsableNotificaciones: new FormControl(),
          fechaAlta: new FormControl(null),
          fechaBaja: new FormControl(null),
          diasDeVacaciones: new FormControl(0),
          diasDeConcinia: new FormControl(0),
          diasDeExcesoComputo: new FormControl(0),
          idSeccion: new FormControl(null),
          idClasificacion: new FormControl(null),
          activo: new FormControl(null)
        });
      } else {
        return new FormGroup({
          idUsuarioEmail: new FormControl(),
          idUsuarioEmpresa: new FormControl(),
          codigoERP: new FormControl(),
          nombreUsuario: new FormControl('', Validators.required),
          emailUsuario: new FormControl('', [Validators.required,Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]),
          dni: new FormControl(),
          numSegSocial: new FormControl(),
          idCentro: new FormControl(),
          telefono: new FormControl(),
          telefonoPers: new FormControl(),
          cargo: new FormControl(),
          rol: new FormControl('ESTANDAR'),
          horario: new FormControl(),
          idDepartamento: new FormControl(),
          urlImagenPerfil: new FormControl(false),
          puedoSerResponsable: new FormControl(false),
          recursosHumanos: new FormControl(false),
          responsableNotificaciones: new FormControl(),
          fechaAlta: new FormControl(null),
          fechaBaja: new FormControl(null),
          diasDeVacaciones: new FormControl(0),
          diasDeConcinia: new FormControl(0),
          diasDeExcesoComputo: new FormControl(0),
          idSeccion: new FormControl(0),
          idClasificacion: new FormControl(0),
        });
      }
    }else {
      return new FormGroup({
        idUsuarioEmail: new FormControl(),
        idUsuarioEmpresa: new FormControl(),
        nombreUsuario: new FormControl("", Validators.required),
        emailUsuario: new FormControl("", [Validators.required,Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$"),]),
        dni: new FormControl(),
        numSegSocial: new FormControl("", [Validators.pattern("[0-9]+")]),
        idCentro: new FormControl(),
        telefono: new FormControl("", [Validators.pattern("[0-9]+")]),
        cargo: new FormControl(),
        rol: new FormControl("ESTANDAR"),
        idDepartamento: new FormControl(null, Validators.required),
        urlImagenPerfil: new FormControl(false),
        puedoSerResponsable: new FormControl(false),
        recursosHumanos: new FormControl(false),
        responsableNotificaciones: new FormControl(null),
        fechaAlta: new FormControl(null),
        fechaBaja: new FormControl(null),
        idSeccion: new FormControl(null),
        idClasificacion: new FormControl(null)
      });
    }
  }

  ngOnInit() {
    this.formPerfil = this.crearFormEmpleado();
    if (!this.isPage) {
      const modalState = {modal: true,desc: 'fake state for our modal'};
      history.pushState(modalState, null);
    }
    if (this.usuario != null) {
      this.formPerfil.patchValue(this.usuario);
      this.formPerfil.controls['idUsuarioEmail'].setValue(this.usuario.idEmpleado)
      this.formPerfil.controls['idUsuarioEmpresa'].setValue(this.usuario.idUsuarioEmpresa)
      this.formPerfil.controls['responsableNotificaciones'].setValue(this.usuario.idDelResponsable)
      this.formPerfil.controls['diasDeVacaciones'].setValue(this.usuario.diasDeVacacionesUsuario)
      this.formPerfil.controls['fechaAlta'].setValue(this.usuario.fechaAltaUsuario)
      this.formPerfil.controls['fechaBaja'].setValue(this.usuario.fechaBajaUsuario)
      this.formPerfil.controls['activo'].setValue(!this.usuario.fechaBajaUsuario?true:false)
      if (!this.usuario.idHorario) {
        this.usuario.idHorario = 0;
      }
    }
    this.formOriginal = this.formPerfil.value;
    this.detectarCambioEnForm();
    this.getDepartamentos();
    this.obtenerCentros();
    this.getResponsables();
    this.getSeccionesClasificaciones();
  }

  getResponsables() {
    this.apiService.getResponsableEmpresa(new Date().getFullYear()).then((c: any) => {
      if (c.ok) {
        this.responsables = c.responsables
        if (this.usuario != null) {
          let responsableEncontrado = this.responsables.find((responsable) => { return responsable.id == this.usuario.idDelResponsable });
          if (!!responsableEncontrado) {
            this.selectedTextResponsable = responsableEncontrado.nombre;
          }
        }
      }
    })
  }

  getSeccionesClasificaciones() {
    this.apiService.getClasificaciones().then((resp: any) => {
      if (resp.ok) this.clasificaciones = resp.clasificaciones;
      if (this.usuario != null) {
       let clasificacionEncontrada = this.clasificaciones.find((clasificacion) => { return clasificacion.id == this.usuario.idClasificacion });
        if (!!clasificacionEncontrada) {
          this.selectedTextClasificacion = clasificacionEncontrada.nombre;
        }
      }
    });
    this.apiService.getSecciones().then((resp: any) => {
      if (resp.ok) this.secciones = resp.secciones;
      if (this.usuario != null) {
        let seccionEncontrada = this.secciones.find((seccion) => { return seccion.id == this.usuario.idSeccion });
        if (!!seccionEncontrada) {
          this.selectedTextSeccion = seccionEncontrada.nombre;
        }
      }
    })
  }
  async checkNuevoCentro(event){
    this.selectedTextCentro = this.centrosUsuario.find(key=>key.id == event.detail.value).nombre;
  }
  async checkNuevoClasificacion(event){
    this.selectedTextClasificacion = this.clasificaciones.find(key=>key.id == event.detail.value).nombre;
  }
  async checkNuevoSeccion(event){
    this.selectedTextSeccion = this.secciones.find(key=>key.id == event.detail.value).nombre;
  }
  async checkNuevoResponsable(event){
    this.selectedTextResponsable = this.responsables.find(key=>key.id == event.detail.value).nombre;
  }
  obtenerCentros() {
    this.apiService.getCentros().then((res: any) => {
      if (res.ok) {
        this.centrosUsuario = res.objeto;
        if (this.usuario != null) {
          let centroEncontrado = this.centrosUsuario.find((centro) => { return centro.id == this.usuario.idCentro });
          if (!!centroEncontrado) {
            this.selectedTextCentro = centroEncontrado.nombre;
          }
        }
      }
    });
  }

  ionViewDidEnter() {
    if (this.isPage) {
      this.backButtonServe.subscription = this.platform.backButton.subscribeWithPriority(5, () => {
        if (!this.formNuevoMod || !this.imagenPerfilMod) {
          this.cerrarConAlerta();
        } else {
          this.navCtrl.navigateRoot('pages/home', { animated: true, animationDirection: 'back' });
          this.backButtonServe.subscription.unsubscribe();
        }
      });
    } else {
      this.backButtonServe.subscription = this.platform.backButton.subscribeWithPriority(5, () => {
        if (!this.formNuevoMod || !this.imagenPerfilMod) {
          this.cerrarConAlerta();
        } else {
          this.modalCtrl.dismiss();
          this.backButtonServe.subscription.unsubscribe();
        }
      });
    }
  }

  getDepartamentos() {
    this.apiService.getDepartamentos(this.globalService.empresaSeleccionada.id).then((data) => {
      this.departamentos = data.departamentos;
      if (this.usuario != null) {
        let departamentoEncontrado = this.departamentos.find((departamento) => { return departamento.id == this.usuario.idDepartamento });
        if (departamentoEncontrado) {
          this.selectedTextDepartamento = departamentoEncontrado.nombre;
        }
      }else {
        this.formPerfil.controls['idDepartamento'].setValue(this.departamentos[0].id)
      }
    }, (tryAgain) => {
      if (tryAgain) {
        this.getDepartamentos();
      }
    });
  }

  async modificarUsuarioDesdeAdmin(formPerfil: FormGroup) {
    if (formPerfil.value.dni != null) {
      let dni_nie_cif_info = this.ValidateSpanishID(formPerfil.value.dni);
      if (dni_nie_cif_info.valid && (dni_nie_cif_info.type.localeCompare('dni') || dni_nie_cif_info.type.localeCompare('nie'))) {
        this.dni_nie_cif_check = true;
      } else {
        this.dni_nie_cif_check = false;
      }
    } else {
      this.dni_nie_cif_check = true;
    }
    if (formPerfil.value.numSegSocial != null) {
      this.nass_check = this.validarNASS(formPerfil.value.numSegSocial);
    } else {
      this.nass_check = true;
    }
    if (!this.dni_nie_cif_check && this.nass_check) {
      this.uiServe.alertaInformativa(`El DNI/NIE: ${formPerfil.value.dni} y el numero de la seguridad social: ${formPerfil.value.numSegSocial} no son validos.`);
      return;
    } else if (!this.dni_nie_cif_check) {
      this.uiServe.alertaInformativa(`El DNI/NIE: ${formPerfil.value.dni} no es valido.`);
      return;
    } else if (!this.dni_nie_cif_check) {
      this.uiServe.alertaInformativa(`El numero de la seguridad social: ${formPerfil.value.numSegSocial} no es valido.`);
      return;
    }
    const loading = await this.loadingCtrl.create({
      cssClass: 'my-custom-class',
      message: 'Guardando...'
    });
    loading.present();
      if (this.usuario != null) {
        if (this.deshabilitadoForm) {
          this.formPerfil.value.nombre = this.usuario.nombreUsuario;
          this.formPerfil.value.codigoERP = this.usuario.codigoERP;
        }
        this.formPerfil.value.fechaBaja = this.usuario.fechaBajaUsuario
        this.formPerfil.value.fechaAlta = this.usuario.fechaAltaUsuario
        this.formPerfil.value.activo = true;
        this.apiService.modificarUsuarioDesdeAdmin(this.formPerfil.value).then((data) => {
          loading.dismiss();
          this.uiServe.alertaInformativa(data.msg);
          if (data.ok) {
            if (this.perfilPropio) {
              this.updateGlobalService();
            }
            this.formNuevoMod = true;
            this.formOriginal = this.formPerfil.value;
            this.imagenPerfilMod = true;
            this.dni_nie_cif_check = true;
            this.nass_check = true;
            this.file = null;
          }
        }, (tryAgain) => {
          if (tryAgain) {
            this.modificarUsuarioDesdeAdmin(formPerfil);
          } else {
            loading.dismiss();
          }
        });
      } else {
        this.formPerfil.value.email = this.formPerfil.value.emailUsuario;
        this.formPerfil.value.nombre = this.formPerfil.value.nombreUsuario;
        delete this.formPerfil.value.emailUsuario;
        delete this.formPerfil.value.nombreUsuario;
        this.apiService.registrarUsuario(this.formPerfil.value).then((data) => {
          loading.dismiss();
          this.uiServe.alertaInformativa(data.msg);
          if (data.ok) {
            this.formNuevoMod = true;
            this.formOriginal = this.formPerfil.value;
            this.imagenPerfilMod = true;
            this.dni_nie_cif_check = true;
            this.nass_check = true;
            this.file = null;
          }
        }, (tryAgain) => {
          if (tryAgain) {
            this.modificarUsuarioDesdeAdmin(formPerfil);
          } else {
            loading.dismiss();
          }
        });
    }
  }

  compruebaJefe(event) {
    setTimeout(() => {
      this.selectedTextDepartamento = this.departamentos.find(key=>key.id == event.detail.value).nombre;
      if ((+this.usuario.idDepartamento > 0) && (+this.usuario.jefeDepartamento == 1)) {
        this.apiService.getCompruebaJefe(event.detail.value).then((c: any) => {
          if (c.ok) {
            if (c.jefeDepartamento > 0 && c.jefeDepartamento != this.usuario.idEmpleado) {
              this.formPerfil.controls['responsableNotificaciones'].setValue(c.jefeDepartamento); 
              this.selectedTextResponsable = this.responsables.find(key=>key.id==c.jefeDepartamento).nombre;
              this.uiServe.alertaInformativa(`Este departamento tiene asignado otro responsable/jefe de departamento, se modificará al guardar los cambios`)
            }
          }
        })
      }
    }, 100);
  }

  cambiarFecha(event,param){
    if(param == 'fechaAlta'){
      this.usuario.fechaAltaUsuario = new Date(event.detail.value);
    }else {
      this.usuario.fechaBajaUsuario = new Date(event.detail.value);
    }
  }

  guardarAnteriorDepartamento() {
    this.anteriorIdDepartamento = this.formPerfil.value.idDepartamento;
    this.anteriorNombreDepartamento = this.selectedTextDepartamento;
    this.anteriorEstadoJefeDepartamento = this.formPerfil.value.jefeDepartamento;
  }

  async checkNuevoDepartamento(event: any) {
    if (event.detail.value === 'nuevo') {
      if (this.isPage) {
        this.backButtonServe.subscription.unsubscribe();
      } else {
        this.anotherModalOpen = true;
      }
      this.uiServe.modalOverModal = await this.modalCtrl.create({
        component: ModalDepartamentoPage,
        cssClass: "full-screen",
      });
      this.uiServe.modalOverModal.onDidDismiss().then((dataDismiss) => {
        setTimeout(() => {
          this.anotherModalOpen = false;
        }, 500);
        if (dataDismiss.data) {
          this.formPerfil.controls['idDepartamento'].setValue(dataDismiss.data.id.toString());
          this.usuario.idDepartamento = dataDismiss.data.id.toString();
          this.selectedTextDepartamento = dataDismiss.data.nombre;
          this.formPerfil.controls['jefeDepartamento'].enable();
          this.getDepartamentos();
        } else {
          this.formPerfil.controls['idDepartamento'].setValue(this.anteriorIdDepartamento);
          this.selectedTextDepartamento = this.anteriorNombreDepartamento;
          this.formPerfil.value.jefeDepartamento = this.anteriorEstadoJefeDepartamento;
        }
      });
      this.uiServe.modalOverModal.present();
    } else if (event.detail.value === '0') {
      this.selectedTextDepartamento = null;
      this.formPerfil.controls['jefeDepartamento'].setValue(false);
      this.formPerfil.controls['jefeDepartamento'].disable();
    } else {
      this.formPerfil.controls['jefeDepartamento'].enable();
      this.selectedTextDepartamento = null;
    }
  }

  async checkNuevaSede() {
    if (this.formPerfil.value.idDepartamento === 'nuevo') {
      this.uiServe.modalOverModal = await this.modalCtrl.create({
        component: ModalDepartamentoPage,
        cssClass: "full-screen",
      });
      this.uiServe.modalOverModal.onDidDismiss().then((dataDismiss) => {
        setTimeout(() => {
          this.anotherModalOpen = false;
        }, 500);
        this.formPerfil.value.idDepartamento = dataDismiss.data.id.toString();
        this.selectedTextDepartamento = dataDismiss.data.nombre;
        this.getDepartamentos();
      });
      this.uiServe.modalOverModal.present();
    }
  }

  async confirmCambiarEstadoUsuarioEmpresa(idUsuarioEmpresa: number) {
    let alta_baja: string[] = [];
    if (this.activo) {
      alta_baja.push("Baja");
      alta_baja.push("baja")
    } else {
      alta_baja.push("Alta");
      alta_baja.push("alta");
    }
    const alert = await this.alertCtrl.create({
      cssClass: "my-custom-class",
      header: alta_baja[0] + ' usuario',
      message: `Se enviará un correo al usuario con la información de su cuenta, ¿continuar?`,
      buttons: [
        {
          text: "Cancelar",
          role: "cancel",
          cssClass: `alert-button-${this.uiServe.colorAplicacionSecond}`,
          handler: () => {
            this.activo = !this.activo;
            this.alertCtrl.dismiss();
          },
        },
        {
          text: "Aceptar",
          cssClass: `alert-button-${this.uiServe.colorAplicacion}`,
          handler: async () => {
            this.cambiarEstadoUsuarioEmpresa(idUsuarioEmpresa, alta_baja);
          },
        },
      ],
    });
    await alert.present();
  }

  cambiarEstadoUsuarioEmpresa(idUsuarioEmpresa: number, alta_baja: string[]) {
    this.apiService.cambiarEstadoUsuarioEmpresa(idUsuarioEmpresa).then((data) => {
      if (data.ok) {
        this.uiServe.alertaInformativa("Usuario dado de " + alta_baja[1] + " correctamente");
      } else {
        this.uiServe.alertaInformativa("No se ha podido dar de " + alta_baja[1] + " este usuario. Inténtalo de nuevo más tarde.");
      }
    }, (tryAgain) => {
      if (tryAgain) {
        this.cambiarEstadoUsuarioEmpresa(idUsuarioEmpresa, alta_baja);
      }
    });
  }

  async confirmEliminarUsuarioDeUnaEmpresa(idUsuario: number) {
    const alert = await this.alertCtrl.create({
      cssClass: "my-custom-class",
      header: "¿Eliminar usuario?",
      message: `Estas a punto de eliminar un usuario, ¿Confirmas que quieres eliminarlo de tu empresa?`,
      buttons: [
        {
          text: "Cancelar",
          role: "cancel",
          cssClass: `alert-button-${this.uiServe.colorAplicacionSecond}`,
          handler: () => {
          },
        },
        {
          text: "Eliminar",
          cssClass: `alert-button-${this.uiServe.colorAplicacion}`,
          handler: async () => {
            this.eliminarUsuarioDeUnaEmpresa(idUsuario);
            this.modalCtrl.dismiss();
          },
        },
      ],
    });
    alert.present();
  }

  eliminarUsuarioDeUnaEmpresa(idUsuario: number) {
    this.apiService.eliminarUsuarioDeUnaEmpresa(idUsuario).then((data: any) => {
      if (data.ok) {
        this.uiServe.alertaInformativa('Usuarios eliminado correctamente');
      } else {
        this.uiServe.alertaInformativa(data.msg);
      }
    }, (tryAgain) => {

      if (tryAgain) {
        this.eliminarUsuarioDeUnaEmpresa(idUsuario);
      }
    });
  }

  async fileImagenPerfil(files: any) {
    if (files && files[0]) {
      const file = files[0];
      if (file.type.includes('image/')) {
        this.file = file;
        this.imagenPerfilMod = false;
        const reader = new FileReader()
        reader.onload = (event: any) => {
          this.urlImagenPerfil = event.target.result;
        }
        reader.readAsDataURL(file);
      } else {
        this.uiServe.alertaInformativa('Solo se admiten imágenes en formato JPG, JPEG, PNG o GIF');
      }
    }
  }

  detectarCambioEnForm() {
    this.formPerfil.valueChanges.subscribe(() => {
      this.formNuevo = this.formPerfil.value;
      for (const clave in this.formNuevo) {
        if (this.formNuevo[clave] != null) {
          this.formNuevo[clave].length == 0 ? this.formNuevo[clave] = null : this.formNuevo[clave]
          if (this.formNuevo['dni'] == null) {
            this.dni_nie_cif_check = true;
          }
          if (this.formNuevo['numSegSocial'] == null) {
            this.nass_check = true;
          }
        }
      }
      this.formNuevoMod = this.compareObjects(this.formNuevo, this.formOriginal);
      this.formNuevoMod ? this.dni_nie_cif_check = true : this.dni_nie_cif_check = this.dni_nie_cif_check;
    });
  }

  compareObjects(obj1: any, obj2: any) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  ngOnDestroy() {
    if (!this.isPage) {
      if (window.history.state.modal) {
        history.back();
      }
    }
  }

  @HostListener('window:popstate', ['$event'])
  cerrar() {
    if (!this.isPage) {
      if (!this.anotherModalOpen) {
        if (this.formNuevoMod && this.imagenPerfilMod) {
          this.modalCtrl.dismiss();
        } else {
          this.cerrarConAlerta();
        }
      }
    }
  }

  async cerrarConAlerta() {
    const alert = await this.alertCtrl.create({
      cssClass: 'my-custom-class',
      header: '¿Guardar cambios?',
      message: `Has realizado cambios en el perfil, ¿deseas guardarlos?`,
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            if (this.isPage) {
              this.navCtrl.navigateRoot('pages/home', { animated: true, animationDirection: 'back' });
              this.backButtonServe.subscription.unsubscribe();
            } else {
              this.modalCtrl.dismiss();
              this.backButtonServe.subscription.unsubscribe();
            }
          }
        }, {
          text: 'Sí',
          handler: () => {
            this.modificarUsuarioDesdeAdmin(this.formPerfil);
          }
        }
      ]
    });

    await alert.present();
  }

  async cancelarCambios() {
    const alert = await this.alertCtrl.create({
      cssClass: 'my-custom-class',
      header: '¿Cancelar cambios?',
      message: `¿Confirmas que quieres descartar los cambios?`,
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => { }
        }, {
          text: 'Sí',
          handler: () => {
            this.formPerfil.patchValue(this.formOriginal);
            if (this.usuario != null) {
              if (this.usuario.urlImagenPerfil) {
                this.urlImagenPerfil = this.urlArchivos + this.usuario.urlImagenPerfil;
              }
            }
            this.imagenPerfilMod = true;
            this.formNuevoMod = true;
            this.formOriginal = this.formPerfil.value;
            if (!this.isPage) {
              this.modalCtrl.dismiss();
            }
          }
        }
      ]
    });
    await alert.present();
  }

  updateGlobalService() {
    this.globalService.usuario.nombre = this.formPerfil.value.nombre;
    this.globalService.usuario.dni = this.formPerfil.value.dni;
    this.globalService.usuario.numSegSocial = this.formPerfil.value.numSegSocial;
    this.globalService.usuario.telefono = this.formPerfil.value.telefonoPers;
    this.globalService.empresaSeleccionada.telefono = this.formPerfil.value.telefono;
    this.globalService.empresaSeleccionada.idDepartamento = this.formPerfil.value.idDepartamento;
    this.globalService.empresaSeleccionada.nombreDepartamento = this.departamentos.find(departamento => departamento.id == this.formPerfil.value.idDepartamento).nombre;
    this.globalService.empresaSeleccionada.jefeDepartamento = this.formPerfil.value.jefeDepartamento;
    this.globalService.empresaSeleccionada.cargo = this.formPerfil.value.cargo;
    this.globalService.empresaSeleccionada.recursosHumanos = this.formPerfil.value.recursosHumanos;
  }

  colorObligatorio() {
    return this.uiServe.claseObligatoria(this.uiServe.colorAplicacion);
  }

  ValidateSpanishID = (function () {
    'use strict';
    var DNI_REGEX = /^(\d{8})([A-Z])$/;
    var CIF_REGEX = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/;
    var NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/;
    var ValidateSpanishID = function (str) {
      // Ensure upcase and remove whitespace
      str = str.toUpperCase().replace(/\s/g, '').replace('-', '');
      var valid = false;
      var type = spainIdType(str);
      switch (type) {
        case 'dni':
          valid = validDNI(str);
          break;
        case 'nie':
          valid = validNIE(str);
          break;
        case 'cif':
          valid = validCIF(str);
          break;
      }
      return {
        type: type,
        valid: valid
      };
    };

    var spainIdType = function (str) {
      if (str.match(DNI_REGEX)) {
        return 'dni';
      }
      if (str.match(CIF_REGEX)) {
        return 'cif';
      }
      if (str.match(NIE_REGEX)) {
        return 'nie';
      }
    };

    var validDNI = function (dni) {
      var dni_letters = "TRWAGMYFPDXBNJZSQVHLCKE";
      var letter = dni_letters.charAt(parseInt(dni, 10) % 23);
      return letter == dni.charAt(8);
    };

    var validNIE = function (nie) {
      // Change the initial letter for the corresponding number and validate as DNI
      var nie_prefix = nie.charAt(0);
      switch (nie_prefix) {
        case 'X': nie_prefix = 0; break;
        case 'Y': nie_prefix = 1; break;
        case 'Z': nie_prefix = 2; break;
      }
      return validDNI(nie_prefix + nie.substr(1));
    };

    var validCIF = function (cif) {
      var match = cif.match(CIF_REGEX);
      var letter = match[1],
      number = match[2],
      control = match[3];
      var even_sum = 0;
      var odd_sum = 0;
      var n;
      for (var i = 0; i < number.length; i++) {
        n = parseInt(number[i], 10);
        // Odd positions (Even index equals to odd position. i=0 equals first position)
        if (i % 2 === 0) {
          // Odd positions are multiplied first.
          n *= 2;
          // If the multiplication is bigger than 10 we need to adjust
          odd_sum += n < 10 ? n : n - 9;
          // Even positions
          // Just sum them
        } else {
          even_sum += n;
        }
      }

      // var control_digit = (10 - (even_sum + odd_sum).toString().substr(-1) );
      var control_digit = (10 - parseInt((even_sum + odd_sum).toString().substr(-1))) % 10;
      var control_letter = 'JABCDEFGHI'.substr(control_digit, 1);

      // Control must be a digit
      if (letter.match(/[ABEH]/)) {
        return control == control_digit;
        // Control must be a letter
      } else if (letter.match(/[KPQS]/)) {
        return control == control_letter;
        // Can be either
      } else {
        return control == control_digit || control == control_letter;
      }
    };

    return ValidateSpanishID;
  })();

  validarNASS(nass: string) {
    if (nass.length != 12) return false;
    let a = nass.substring(0, 2);
    let b = nass.substring(2, 10);
    let c = nass.substring(10, 12);
    let d: any;
    if (parseInt(b) < 10000000) {
      d = parseInt(b) + parseInt(a) * 10000000
    } else {
      d = a + b
    }
    if (parseInt(c) != d % 97) return false;
    return true;

  }

  getIniciales(usuarioNombre: string) {
    if (usuarioNombre == null) {
      return
    }
    const nombreSplitted = usuarioNombre.split(' ');
    let iniciales: string = "";
    let cont: number = 0;
    nombreSplitted.map((split) => {
      if (cont < 2) {
        iniciales += split.charAt(0).toUpperCase();
      }
      cont++;
    });
    return iniciales;
  }

  trimEmail() {
    if (this.formPerfil.value.email != null)
      this.formPerfil.controls['email'].setValue(this.formPerfil.value.email.trim());
  }

  blurTrim(event: any) {
    event.target.value = event.target.value.trim();
  }

  infoPuede() {
    this.uiServe.alertaInformativa("¿Este usuario podrá ser responsable de otros usuarios?.");
  }

  infoRRHH() {
    this.uiServe.alertaInformativa("Los usuarios marcados como RRHH tendrán acceso a todas las funciones relacionadas con los empleados, pudiendo gestionar sus fichas, horarios, solicitudes…");
  }

  infoRol() {
    this.uiServe.alertaInformativa("Los usuarios con rol <strong>Administrador</strong> tendrán acceso total, pudiendo editar usuarios, configuraciones, etc.<br><br>Los usuarios con rol <strong>Estándar</strong> no tendrán acceso al panel salvo que sean responsables de otros usuarios o Usuarios de RRHH.");
  }

  infoResp() {
    this.uiServe.alertaInformativa("Indique el usuario responsable de este usuario. Los usuarios responsables podrán aprobar o rechazar solicitudes y realizar otras gestiones de los usuarios a su cargo.");
  }

  async reenviarMail(){
    const alert = await this.alertCtrl.create({
      cssClass: 'my-custom-class',
      header: 'E-mail de acceso',
      message: `Este e-mail se envío por última vez el ${this.datePipe.transform(new Date(this.usuario.fechaEnvioApp),'dd/MM/yy')}.<br><br>¿Quiere volver a enviarlo?`,
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => { }
        }, {
          text: 'Sí',
          handler: () => {
            //haz magia
            this.enviarCorreoAcceso();
          }
        }
      ]
    });
    await alert.present();
  }

  enviarCorreoAcceso(){
    let objeto = {
      empleados: [this.usuario.idEmpleado],
      id: null,
      nombre: null,
      tipo: "CORREO",
    };
    this.apiService.aplicarAccionesMasivas(objeto).then(
      (c: any) => {
        if (c.ok) {
          this.uiServe.alertaInformativa(`Datos enviados con éxito`);
        } else {
          this.uiServe.alertaInformativa(`Ha ocurrido un error inesperado, por favor intentelo de nuevo mas tarde`);
        }
      },
      (error) => {
        this.uiServe.alertaInformativa(error.msg);
      }
    );
  }

  cambioActivo(event){
    if(event.detail.checked){
      this.usuario.fechaBajaUsuario = null;
      this.usuario.fechaAltaUsuario = new Date()
    } else {
      this.usuario.fechaBajaUsuario = new Date();
      this.usuario.fechaAltaUsuario = null;
    }
  }

}
