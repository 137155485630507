import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { DetallePermiso } from 'src/app/pages/horario/calendario.model';

@Component({
  selector: 'app-detalle-permisos',
  templateUrl: './detalle-permisos.component.html',
  styleUrls: ['./detalle-permisos.component.scss'],
})
export class DetallePermisosComponent implements OnInit {
  @Input('fecha') fecha:any = new Date();
  @Input('tipoAusencias') tipoAusencias:any[] = [];
  @Input('datos') datos: DetallePermiso = new DetallePermiso();
  constructor(private popoverCtrl: PopoverController,private datePipe:DatePipe) { }

  ngOnInit() {
    this.fecha=this.datePipe.transform(this.fecha,'dd/MM/yyyy');
  }

  devolverColorAusencia(){
  /*   if (dato.idData ==0) return;
    if (dato.data.idTipoAusencia ==0)return; */
    let color = this.tipoAusencias.find(key=> key.id == this.datos.idTipoNoTrabajo).color;
    
    return `${color}`;
    
  }
  devolverNombreAusencia(){
    /*   if (dato.idData ==0) return;
      if (dato.data.idTipoAusencia ==0)return; */
      let nombre = this.tipoAusencias.find(key=> key.id == this.datos.idTipoNoTrabajo).nombre;
      
      return `${nombre}`;
      
    }

    devolverRango(){
      return `${this.datePipe.transform(this.datos.fechaInicio,'dd/MM/yyyy')} - ${this.datePipe.transform(this.datos.fechaFin,'dd/MM/yyyy')}`;
    }


}
