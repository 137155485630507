import { DatePipe } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { ModalController, PopoverController } from "@ionic/angular";
import { UiService } from "src/app/api/ui.service";
import { DetalleCalendario } from "src/app/pages/horario/calendario.model";

@Component({
  selector: "app-detalle-horario",
  templateUrl: "./detalle-horario.component.html",
  styleUrls: ["./detalle-horario.component.scss"],
})
export class DetalleHorarioComponent implements OnInit {
  @Input("infoDia") infoDia: any = null;
  @Input("fecha") fecha: any = new Date();
  pintarFestivos: boolean = true;
  pintarVacaciones: boolean = true;
  pintarAusencias: boolean = true;
  pintarTurnos: any;
  constructor(
    private popoverCtrl: PopoverController,
    private datePipe: DatePipe,
    private modalCtrl:ModalController,
    public uiServe:UiService
  ) {}

  ngOnInit() {
    this.fecha = this.datePipe.transform(this.fecha, "dd/MM/yyyy");
  }

  cerrar() {
    this.modalCtrl.dismiss({ lol: "cosetes" });
  }

/*   devolverEstado() {
    if (this.datos.tipodDeDia == "T") return "";
    return this.datos.estadoComunicacion == 2
      ? "Solicitud aceptada"
      : this.datos.estadoComunicacion == 3
      ? "Solicitud rechazada"
      : "Solicitud Pendiente";
  } */

  minsToHHmm(mins: number): string {
    if (mins == 0) return '00 h 00 m';
    let negativo: boolean = false;
    if (mins < 0) { negativo = true; mins = mins * -1 };
    let HH: number = Math.trunc(mins / 60);
    let mm: number = mins % 60;
    return `${negativo ? '-' : ''}${HH < 10 ? `0${HH}` : HH} h ${mm < 10 ? `0${mm}` : mm} m`;
  }
}
