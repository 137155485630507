import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AlertController, LoadingController, ModalController, Platform, PopoverController, ToastController } from '@ionic/angular';
import { GlobalService } from 'src/app/services/global.service';


import { FileOpener } from '@ionic-native/file-opener/ngx';
import { File } from '@ionic-native/file/ngx';
import { Capacitor, Plugins } from '@capacitor/core';
import { DocumentosService } from 'src/app/services/documentos.service';
import { ignoreNextOnError } from '@sentry/browser/dist/helpers';
import { AdjuntosComponent } from '../adjuntos/adjuntos.component';
import { ImgComponent } from '../img/img.component';
import { CompressImageService } from 'src/app/services/compress-image.service';

@Component({
  selector: 'app-documentos',
  templateUrl: './documentos.component.html',
  styleUrls: ['./documentos.component.scss'],
})
export class DocumentosComponent implements OnInit,OnChanges {

  @Input() tipo:string;
  @Input() idOrigen:number;
  @Input() idCarpeta:number;
  @Input() carpeta:any;
  @Input() idEmpleado:number;
  
  titulo:string = 'Documentos'
  documentos:any[]=[]
  textoBuscar:string='';
  documentoSelect:any;
  fichero:any;
  loading:any;
  filtro:string = 'nombre'
  filtroFecha:boolean = false;
  tipoSelect:string;
  ficheroNombre:string='';
  input1:any;
  input2:any;

  constructor(
    private apiService:DocumentosService,
    public globalService:GlobalService,
    private alertController:AlertController,
    public modal:ModalController,    
    public modalAux:ModalController,   
    private popoverCtrl:PopoverController,
    private opener: FileOpener,
    private file: File,
    private platform:Platform,
    private toast:ToastController,
    private compressImage: CompressImageService,
    private loadingController:LoadingController) { }

  ngOnChanges(changes: SimpleChanges): void {    
  }

  ngOnInit() {} 

  ionViewDidEnter() {
    if(!!this.tipo){
      this.titulo = this.tipo      
        this.getCarpetaPorNombre()      
    }else{
      this.getDocumentosLigeros();
    } 
  }

  getCarpetaPorNombre(){
    this.apiService.getDirectorioPorNombre(this.tipo).subscribe((c:any)=>{
      if(c.ok){
        this.carpeta = c.directorio        
        if(!!this.carpeta && this.carpeta.permisoLectura){
          if(!!this.idOrigen && this.idOrigen > 0){ 
            this.getDocumentosLigerosOrigen()
          }else{
            this.getDocumentosLigeros();
          }
        } 
      }
    })
  }


  onSearchChange(event){
    this.textoBuscar = event.detail.value;
  }

  getDocumentosLigeros(fechaInicio?, fechaFin?){    
    let datos ={
      idCarpeta:this.carpeta.id,
      idEmpleados:[this.globalService.usuario.id],
      idOrigen: this.idOrigen,
      fechaInicio,
      fechaFin
    }
    this.apiService.getDocumentosDirectorioSimple(datos).subscribe((c:any)=>{
      if(c.ok){
        this.documentos = [...c.documentos] 
      }
    })
  }

  getDocumentosLigerosOrigen(fechaInicio?, fechaFin?){    

    let datos ={
      idCarpeta:this.carpeta.id,
      idEmpleados:[this.globalService.usuario.id],
      idOrigen: this.idOrigen,
      fechaInicio,
      fechaFin
    }
    this.apiService.getDocumentosDirectorioSimpleYOrigen(datos).subscribe((c:any)=>{
      if(c.ok){
        this.documentos = c.documentos      
      }
    })
  }

  getDocumento(id, verODescargar){
    
    this.apiService.getDocumento(id>1000000?id -1000000:id).subscribe(async(c:any)=>{
      if(c.ok){
        this.documentoSelect = c.documento
        let url = (<any>await this.apiService.getPublicUrl(id>1000000?id -1000000:id).toPromise()).ruta
        console.log('URL', url)
        this.verDoc(url);
    }    
   })
  } 

  async verDoc(url?){
    const modal = await this.modalAux.create({
      component: ImgComponent,
      componentProps: {
        img:this.documentoSelect,      
        url: !!url?url:null  
      }
    });
    await modal.present();
  }
  descargarDoc(){
    var a = document.createElement("a"); //Create <a>
    a.href = `data:${this.documentoSelect.extension};base64,${this.documentoSelect.base}`; //Image Base64 Goes here
    a.download = `${this.documentoSelect.nombre}`; //File name Here
    a.click(); //Downloaded file
    this.documentoSelect = null;
    
  }
  loadDoc(event){    
    this.fichero = event.target.files[0];
    this.ficheroNombre = this.fichero.name;
    if(Array.isArray(this.tipo)){
      this.presentMultiTipo()
    }else{
      this.tipoSelect= this.tipo;
      //this.subirDoc();
      this.presentAlerNombreArchivo()
    }
    //Reinicio los inputs por si quiero seleccionar la misma imagen de nuevo
    this.input1.value = null;
    this.input2.value = null;
  }

  async presentAlerNombreArchivo() {
    const alert = await this.alertController.create({
      header: `Ahora puede renombrar la imagen`,
      subHeader: this.fichero.name,
      inputs: [
        {
          name: 'nombre',
          type: 'text',
          placeholder: this.fichero.name
        }        
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            this.subirDoc()
          }
        }, {
          text: 'Confirmar',
          handler: (e) => {
            if(e.nombre > ''){              
              this.ficheroNombre= `${e.nombre}${this.fichero.name.substring(this.fichero.name.indexOf('.'), this.fichero.name.length)}`
              this.ficheroNombre = this.ficheroNombre.replace(/ /g, '_')
            }
            this.subirDoc()
          }
        }
      ]
    });

    await alert.present();
  }

  subirDocImg(){
      this.compressImage.compress(this.fichero).subscribe(data => {
        this.fichero = data
        },
        error => console.log(error),
    );
  
    setTimeout(() => {
      this.subirDoc1(this.fichero);
    }, 700);
  }

  subirDoc1(fichero){
    return new Promise((resolve,reject)=>{
      let formData = new FormData()
      let control = false;
      let datos = JSON.stringify(
        {
          tipo:this.carpeta.nombreCarpeta.toUpperCase(), 
          idOrigen:this.idOrigen,  
          idEmpresa:this.globalService.empresaSeleccionada.id, 
          idEmpleado: this.globalService.usuario.id, 
          extension: fichero.mimetype,
          nombreDoc: this.ficheroNombre,
          idCarpeta: this.carpeta.id,
          fechaSubida: new Date()
        })
      formData.append('file', fichero, this.ficheroNombre)
      formData.append('datos',datos )
      let suscripcionSubida = this.apiService.setDocumento(formData).subscribe((c:any)=>{        
        control = true;
        
        if(!!c.ok) {
          resolve(c.doc)
          // this.uiServe.alertaInformativa('Documento subido correctamente');
          this.ionViewDidEnter()
        }     
        else {
        // this.uiServe.alertaInformativa('No se ha podido subir el documento')  
        reject()
        }
        
      })
      setTimeout(() => {
        if(!control) suscripcionSubida.unsubscribe()
        reject()
      }, 1000*10);
    })
  }

  async subirDoc(){

    //if(this.fichero.type == 'image/jpeg')this.fichero = await this.compressImage.compress(this.fichero).toPromise()     

    const c:any = await this.apiService.newSubirDoc(this.carpeta, this.fichero, this.ficheroNombre,this.idOrigen,0)
    console.log(c);
    
    if(c.ok){
      this.presentToast(`Documento subido`)
      this.ionViewDidEnter()    
    }else{
      if(!!c.msg) this.presentToast(c.msg)
      if(!!c.error) console.warn(c.error)
    }
  }
  async presentAlertFiltroFechas() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Filtrar por fechas',
      inputs: [
        {
          name: 'fechaInicio',
          type: 'date',
          placeholder: 'Fecha de inicio'
        },
        {
          name: 'fechaFin',
          type: 'date',          
          placeholder: 'fechaFin'
        }
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            
          }
        }, {
          text: 'Buscar',
          handler: (data) => {
            this.filtroFecha = true;
            this.getDocumentosLigeros(new Date(data.fechaInicio),new Date(data.fechaFin))
          }
        }
      ]
    });

    await alert.present();
  }
  reiniciaFiltro(){
    this.filtroFecha = false;
    this.getDocumentosLigeros()    
  }
  cambiaFiltros(event){
    if (!!this.filtroFecha) {
      this.reiniciaFiltro()
    }
  }

  async presentMultiTipo() {
    let inputs=[];
    for await (const t of this.tipo) {            
      inputs.push({
        name: t,
        type: 'radio',
        label: t,
        value: t,
        handler: () => {
        }
      })
    }

    const alert = await this.alertController.create({
      subHeader: 'Seleccionar tipo',
      message: 'Establezca de que tipo será el documento',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
          }
        }, {
          text: 'Confirmar',
          handler: (data) => {
            this.tipoSelect = data;
            //this.subirDoc();
            this.presentAlerNombreArchivo()
          }
        }
      ], 
      inputs:inputs
    });



    

    await alert.present();

    const { role } = await alert.onDidDismiss();
  }

  async abrirSelectDocs(event: any,archivo:any,archivo1:any){
    this.input1 = archivo;
    this.input2 = archivo1;
    const popover = await this.popoverCtrl.create({
      component: AdjuntosComponent,
      event: event,
      mode: "ios",
      backdropDismiss: true,
    });
    await popover.present();
    const { data } = await popover.onWillDismiss();
    console.log(data);
    
    if(data.abrir == 'camara'){
      archivo.click();
    }else {
      archivo1.click();
    }
    
  }
  convertBase64ToBlob(b64Data, contentType): Blob {
    contentType = contentType || '';
    const sliceSize = 512;
    b64Data = b64Data.replace(/^[^,]+,/, '');
    b64Data = b64Data.replace(/\s/g, '');
    const byteCharacters = window.atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
         const slice = byteCharacters.slice(offset, offset + sliceSize);
         const byteNumbers = new Array(slice.length);
         for (let i = 0; i < slice.length; i++) {
             byteNumbers[i] = slice.charCodeAt(i);
         }
         const byteArray = new Uint8Array(byteNumbers);
         byteArrays.push(byteArray);
    }
   return new Blob(byteArrays, {type: contentType});
}
eliminaDocumento(id){
  this.apiService.eliminarDoc(id).subscribe((c:any)=>{
    if(c.ok){
      this.documentos.splice(this.documentos.findIndex(f=> f.id == id), 1)
      if(!!c.msg){
        this.presentToast(c.msg)
      }else this.presentToast(`Archivo eliminado correctamente`)
    }else{
      if(!!c.msg) this.presentToast(c.msg)
      if(!!c.error) console.warn(c.error)
    }
  })
}
async presentToast(message) {
  const toast = await this.toast.create({
    message,
    duration: 3000,
  });
  toast.present();
}
async presentLoading(message) {
  this.loading = await this.loadingController.create({    
    message
  });
  await this.loading.present();
}
}
