import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { LoadingController, ModalController } from '@ionic/angular';
import { Plugins } from '@capacitor/core';
const {Browser} = Plugins;

@Component({
  selector: 'app-img',
  templateUrl: './img.component.html',
  styleUrls: ['./img.component.scss'],
})
export class ImgComponent implements OnInit {

  @Input() img:any;
  @Input('url') url:string;

  base:string;
  loading:any;
  constructor(public modal:ModalController,
    private sanitizer: DomSanitizer,
    private loadingController:LoadingController
) { }

  ngOnInit() {}

  async ionViewWillEnter() {
    this.base = !!this.url?this.url:`data:${this.img.extension};base64,${this.img.base}`  
    if( this.img.extension.includes('pdf')) {
      this.loading = await this.loadingController.create()
      this.loading.present()
    }
  }
  getPdf(){
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.base)
  }
  pdfCargado(){
    this.loading.dismiss()
  }
  capturaError(){
    !!this.loading && this.loading.dismiss()
  }
}
