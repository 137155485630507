import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtroSolicitudes'
})
export class FiltroSolicitudes implements PipeTransform {

  transform( arreglo: any[],texto:string ): number {
   if(texto == ''){
    return arreglo.filter( key=> key.tipo != 'INC').length;
   }
   texto=texto.toUpperCase();
   return arreglo.filter( key=> key.tipo == texto).length;
  }
}