import { Component, ViewChild } from '@angular/core';
import { IonDatetime, ModalController } from '@ionic/angular';
import { UiService } from 'src/app/api/ui.service';

@Component({
  selector: 'app-modal-solicitud',
  templateUrl: './modal-solicitud.component.html',
  styleUrls: ['./modal-solicitud.component.scss'],
})
export class ModalSolicitudComponent {

  @ViewChild('horaInicio') horaInicio: IonDatetime = null;
  @ViewChild('horaFin') horaFin: IonDatetime = null;

  constructor(private modalController: ModalController,public uiServe:UiService) { }

  confirmar() {
    this.modalController.dismiss({ ok: true, horaInicio: this.horaInicio.value, horaFin: this.horaFin.value })
  }
  cancelar() {
    this.modalController.dismiss({ ok: false })
  }
}
