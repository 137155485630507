import { ChangeDetectorRef, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MenuController, Platform, PopoverController } from '@ionic/angular';
import {  Capacitor, Plugins } from '@capacitor/core';
import { StatusBarStyle, Style } from '@capacitor/status-bar';
import { GlobalService } from './services/global.service';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { UiService } from './api/ui.service';
import { environment } from 'src/environments/environment';
import { PushService } from './api/push.service';
import * as moment from 'moment';
import { AnalyticsService } from './services/analytics.service';
import { AccionesHorarioComponent } from './components/acciones-horario/acciones-horario.component';
import { AlarmasService } from './pages/alarmas/alarmas.service';
import { LoginService } from './login/login.service';
import { ApiService } from './api/api.service';
import { Preferences } from '@capacitor/preferences';
const { Geolocation, LocalNotifications,StatusBar,  } = Plugins;
const Storage = Preferences
import { Device } from '@capacitor/device'
import { App } from '@capacitor/app';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

  @ViewChild('menu') menu: any;
  urlArchivos: string = environment.urlArchivos;
  mostrar: boolean = false;
  version: string;
  verGestion: boolean = false;
  mostrarNots:  boolean = false;
  mostrarTareas: boolean = false;
  mostrarJornada: boolean = false;
  mostrarNovs: boolean = false;
  plataforma:string = null;
  modelo:string = null;

  constructor(
    private platform: Platform,
    public globalService: GlobalService,
    private router: Router,
    private menuController: MenuController,
    public uiServe: UiService,
    private pushService: PushService,
    private analyticsService: AnalyticsService,
    public apiService:ApiService,
    private popoverCtrl: PopoverController,
    private alarmaService:AlarmasService,
    private loginService:LoginService,
    public changeRef: ChangeDetectorRef
  ) {
    this.initializeApp();
   
  }

  @HostListener("window:resize", ["$event"])
  async onResize() {
    let info = await Device.getInfo();
    this.plataforma = info.platform;
    this.modelo = info.model;
  }

  async initializeApp() {
    let info = await Device.getInfo();
    this.onResize();
    await this.apiService.comprobarRed(this.changeRef);
    this.platform.resume.subscribe(async () => {
     this.loginService.renovarInfoConToken();
     
    });
    this.setUpStatusBar();
    this.setScreenTrack();
    this.pushService.configuracionInicial();
    //SplashScreen.hide();
    if(info.platform=='web'){return}
    const permiso =await LocalNotifications.requestPermission();
    if(permiso.granted){
      this.alarmaService.chanel = {
        id: '12',
        name: 'nivinAlarmas',
        importance: 4,
        visibility: 1,
        vibration: true,
        sound:'recordatorio2'
      };
      if(Capacitor.isNativePlatform()){
        const chanels = await LocalNotifications.listChannels();
        if(chanels.channels.length> 0){
          await LocalNotifications.deleteChannel({description: "Default",
          id: "default",
          importance: 3,
          lightColor: "#000000",
          lights: false,
          name: "Default",
          sound: "android.resource://com.nivin.com/2131623939",
          vibration: false,
          })
        }
         //await LocalNotifications.createChannel(this.alarmaService.chanel);
      }

    }
  
  }
  private setUpStatusBar() {
    if (!this.platform.is('hybrid')) {
      return;
    }
    const style = this.platform.is('ios') ? StatusBarStyle.Light : StatusBarStyle.Dark;
    StatusBar.setStyle({ style });
    StatusBar.setBackgroundColor({ color: '#1579a8' });
  }
  private setScreenTrack() {
    if (!this.platform.is('hybrid')) {
      return;
    }

    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd) {
        this.analyticsService.setScreenName(event.urlAfterRedirects);
      }
    });
  }

  async ngOnInit() {
    moment.locale('es');
  }
 
  ngAfterViewInit() {
    setTimeout(() => {
      Storage.get({ key: 'token' }).then((data) => {
        this.uiServe.menu = this.menu;
        this.uiServe.items = document.getElementsByClassName('claseItem');
        if (data.value == null) {
          return;
        }
        this.menu.el.style.setProperty('--background', `linear-gradient(to right,${this.uiServe.devolverCodigoColor(this.uiServe.colorAplicacion)},
         ${this.uiServe.devolverCodigoSecundario(this.uiServe.colorAplicacionSecond)})`);
        for (let i = 0; i < this.uiServe.items.length; i++) {
          const item: any = this.uiServe.items[i];
          item.style.setProperty('--background', `linear-gradient(to right,${this.uiServe.devolverCodigoColor(this.uiServe.colorAplicacion)},
         ${this.uiServe.devolverCodigoSecundario(this.uiServe.colorAplicacionSecond)})`);
        }
      });
    }, 1000);
  }

  getIniciales(usuarioNombre: string) {
    const nombreSplitted = usuarioNombre.split(' ');
    let iniciales: string = "";
    let cont: number = 0;
    nombreSplitted.map((split) => {
      if (cont < 2) {
        iniciales += split.charAt(0).toUpperCase();
      }
      cont++;
    });
    return iniciales;
  }

  

  irCalendario() {
    this.menuController.toggle();
    this.router.navigateByUrl(`pages/calendario`);
  }
  irNotificaciones() {
    this.menuController.toggle();
    this.router.navigateByUrl(`pages/notificaciones`);
  }
  irInformes() {
    this.menuController.toggle();
    this.router.navigateByUrl(`pages/informes`);
  }
  irListadoPermisos(){
    this.menuController.toggle();
    this.router.navigateByUrl(`pages/listado-permisos`);
  }
  irGestionDocumental(){
    this.menuController.toggle();
    this.router.navigateByUrl(`pages/gestion-documental`);
  }
  async mostrarPop(evento) {
    //  this.backButtonServe.subscription.unsubscribe();
      const popover = await this.popoverCtrl.create({
        component: AccionesHorarioComponent,
        event: evento,
        mode: "ios",
        backdropDismiss: true,
        componentProps:{
          menu:this.menu
        }
      });
      await popover.present();
  }
  irContactos() {
    this.menuController.toggle();
    this.router.navigateByUrl(`pages/contactos`);
    this.menuController
  }
  irProyectos() {
    this.menuController.toggle();
    this.router.navigateByUrl(`pages/proyectos`);
  }
  irExcepciones() {
    this.menuController.toggle();
    this.router.navigateByUrl(`pages/excepciones`);
  }

  irProrrogas() {
    this.menuController.toggle();
    this.router.navigateByUrl(`pages/prorrogas`);
  }

  irCambioTurno() {
    this.menuController.toggle();
    this.router.navigateByUrl(`pages/cambio-turno`);
  }

  irProyectosDetalle() {
    this.menuController.toggle();
    this.router.navigateByUrl(`pages/proyectos-detalle`);
  }

  irIncidencias() {
    this.menuController.toggle();
    this.router.navigateByUrl(`pages/incidencia`);
  }

  irNotis(){
    this.menuController.toggle();
    this.router.navigateByUrl(`pages/notificaciones`);
  }

  async irEstadisticas() {
    this.menuController.toggle();
    this.router.navigateByUrl(`pages/estadisticas`);
  }

  async irPerfil() {
    this.menuController.toggle();
    this.router.navigateByUrl(`pages/perfil`);
  }

  async irEmpresa() {
    this.menuController.toggle();
    this.router.navigateByUrl(`pages/empresa`);
  }

  irInicio() {
    this.menuController.toggle();
    this.router.navigateByUrl(`pages/home`);
  }

  irAjustes() {
    this.menuController.toggle();
    this.router.navigateByUrl('pages/ajustes');
  }

  alertNingunIncidencias() {
    this.uiServe.alertaInformativa("Ningún administrador tiene activado el servicio de recepción de las incidencias generadas por los usuarios.<br/><br />Ve a Empresa > Usuarios para adjudicarlo.");
  }

  mostrarSubmenu() {
    this.mostrar = !this.mostrar;
  }

  mostrarSubmenuNots() {
    this.mostrarNots = !this.mostrarNots;
  }

  irGastos() {
    this.menuController.toggle();
    this.router.navigateByUrl(`pages/gastos`);
  }

  irPlanificacion() {
    this.menuController.toggle();
    this.router.navigateByUrl('pages/planificacion');
  }

  irHorario(){
    this.menuController.toggle();
    this.router.navigateByUrl('pages/horario');
  }

  irCalendarioTareas(){
    this.menuController.toggle();
    this.router.navigateByUrl('pages/calendario-tareas');
  }

  irEstadisticasTareas(){
    this.menuController.toggle();
    this.router.navigateByUrl('pages/estadisticas-tareas');
  }

  
  irVacaciones(){
    this.menuController.toggle();
    this.router.navigateByUrl('pages/vacaciones');
  }

  irSolicitudes(){
    this.menuController.toggle();
    this.router.navigateByUrl('pages/solicitudes');
  }

  irTeletrabajo(){
    this.menuController.toggle();
    this.router.navigateByUrl('pages/teletrabajo');
  }


  comprobarPermisos(permiso){
    
    
    return this.globalService.permisos.queVer.includes(permiso);
  }

  mostrarSubmenuTareas(){
    this.mostrarTareas = !this.mostrarTareas;
  }

  mostrarSubmenuJornada(){
    this.mostrarJornada = !this.mostrarJornada;
  }

  mostrarNovedades(){
    //this.mostrarNovs = !this.mostrarNovs;
      this.menuController.toggle();
      this.router.navigateByUrl('pages/tour-novedades');
  }


}
