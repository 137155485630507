import { Injectable } from '@angular/core';
import { UiService } from '../api/ui.service';
import { Capacitor, Plugins } from '@capacitor/core';
import { Router } from '@angular/router';
import { GlobalService } from './global.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import { PushService } from '../api/push.service';
import { Preferences } from '@capacitor/preferences';
const  Storage  = Preferences;

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  apiRest: String = environment.url;
  plataformas: string = '';

  constructor(private uiService: UiService,
    private router: Router,
    private http: HttpClient,
    private globalService: GlobalService,
    private uiServe: UiService,
    private platform: Platform,
    private pushService: PushService) {
    this.plataformas = this.platform.platforms().toString();
  }

  async getError(error: any): Promise<boolean> {

    switch (error.status) {
      case 401: {
        return await this.renovarToken401();
      }
      case 500:
      case 0: {
        this.uiService.presentToast('Error del servidor. Consulta con el administrador, por favor', "top", 2500);
        return false;
      }
      default:
        return false;
    }
  }

  renovarToken401(): Promise<boolean> {
    return Promise.all([
      Storage.get({ key: 'usuario' }),
      Storage.get({ key: 'empresaSeleccionada' })
    ]).then((values) => {
      let usuario = JSON.parse(values[0].value);
      let empresaSeleccionada = JSON.parse(values[1].value);
      return new Promise(async (resolve) => {
        let body: any = { email: usuario.email, idUsuarioEmail: usuario.id, idEmpresa: empresaSeleccionada.id };
        if (Capacitor.isNativePlatform() && !this.platform.is('pwa') && !this.platform.is('mobileweb')) {
          const idOneSignal = await this.pushService.getUserIdOneSignal();
          //const datosDispositivo: any = await this.pushService.recogerModeloDispositivo(idOneSignal)
          body = {
            email: usuario.email,
            idUsuarioEmail: usuario.id,
            idEmpresa: empresaSeleccionada.id,
            idOneSignal: idOneSignal,
            idSesion: idOneSignal,
            //device: datosDispositivo.device_model,
            plataformas: this.plataformas,
            nativo: Capacitor.isNativePlatform()
          };
        }
        this.http.post(`${this.apiRest}/renovarToken401`, body, { headers: { 'Content-Type': 'application/json' } }).subscribe((data: any) => {

          if (data.ok) {
            Storage.set({ key: 'token', value: 'Bearer ' + data.access_token });
            Storage.set({ key: 'usuario', value: JSON.stringify(data.usuario) });
            Storage.set({ key: 'empresas', value: JSON.stringify(data.empresas) });
            this.globalService.token = 'Bearer ' + data.access_token;
            this.globalService.usuario = data.usuario;
            this.globalService.empresas = data.empresas;
            Storage.get({ key: 'empresaSeleccionada' }).then((dataStorage) => {
              for (let i = 0; i < data.empresas.length; i++) {
                const empresa = data.empresas[i];
                if (empresa.idEmpresa === JSON.parse(dataStorage.value).idEmpresa) {
                  Storage.set({ key: 'empresaSeleccionada', value: JSON.stringify(empresa) });
                  this.globalService.empresaSeleccionada = empresa;
                  this.uiServe.tituloTiempo = this.globalService.empresaSeleccionada.trabajarConTiempoAsignado ? 'previsto' : 'total';
                  this.uiServe.colorAplicacion = empresa.color;
                  this.uiServe.secondColor(empresa.color);
                  this.uiServe.setearEstiloMenu(this.uiServe.menu, this.uiServe.items);
                }
              }
            });
            resolve(true);
          } else {
            Storage.clear();
            this.router.navigateByUrl('login');
            this.uiService.presentToast('No se ha podido verificar la sesion, inicia sesion de nuevo por favor', "top", 2500);
            resolve(false);
          }
        }, () => {
          Storage.clear();
          this.router.navigateByUrl('login');
          this.uiService.presentToast('Error del servidor. Consulta con el administrador, por favor', "top", 2500);
          resolve(false);
        });
      })
    })
  }
}
