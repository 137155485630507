import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UiService } from 'src/app/api/ui.service';

@Component({
  selector: 'app-horas-validadas',
  templateUrl: './horas-validadas.component.html',
  styleUrls: ['./horas-validadas.component.scss'],
})
export class HorasValidadasComponent implements OnInit {
  @Input('tarea') tarea:any = null;
  horasDedicadas:Date = null;
  horasDedicadasString:string = null;
  horasAsignadasString:string = null;
  horasAsignadas: Date = null;
  constructor(private modalCtrl:ModalController,public uiServe:UiService,private datePipe:DatePipe) { }

  ngOnInit() {
      this.horasDedicadas = new Date(Date.now());
      this.horasDedicadas.setHours(0);
      this.horasDedicadas.setMinutes(this.tarea.minutosDedicados);

      this.horasAsignadas = new Date(Date.now());
      this.horasAsignadas.setHours(0);
      this.horasAsignadas.setMinutes(this.tarea.minutosAsignados);
      this.horasDedicadasString=this.horasDedicadas.toISOString();
      this.horasAsignadasString = this.datePipe.transform(this.horasAsignadas,'HH:mm');
  }
  cambiaHora(){
    if (this.datePipe.transform(this.horasDedicadasString, 'HH:mm') > this.horasAsignadasString){
      this.horasDedicadas = new Date(Date.now());
      this.horasDedicadas.setHours(0);
      this.horasDedicadas.setMinutes(this.tarea.minutosDedicados);
      this.horasDedicadasString=this.horasDedicadas.toISOString();
    }
  }

  cerrar(){
    this.modalCtrl.dismiss();
  }

  editarHoras(){}

}
