import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UiService } from 'src/app/api/ui.service';

@Component({
  selector: 'app-resumen-validacion',
  templateUrl: './resumen-validacion.component.html',
  styleUrls: ['./resumen-validacion.component.scss'],
})
export class ResumenValidacionComponent implements OnInit {
  @Input('validaciones') validaciones:any[]=[];
  constructor(public uiServe:UiService,private modalCtrl:ModalController) { }

  ngOnInit() {
    this.validaciones = this.validaciones.map(key=>{
      key.fecha = new Date(key.fecha);
      return key;
    })
  }
  cerrar(){
    this.modalCtrl.dismiss();
  }

}
