import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Capacitor,  Plugins } from '@capacitor/core';
import { environment } from 'src/environments/environment';
import { ErrorService } from 'src/app/services/error.service';
import { GlobalService } from 'src/app/services/global.service';
import { Preferences } from '@capacitor/preferences';
const Storage = Preferences
const { LocalNotifications } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class AlarmasService {

  apiRest: string = environment.url;
  chanel:any;
  
  constructor(private http: HttpClient,
    private errorService: ErrorService,
    private globalService: GlobalService) { }

  async ponerEncabezados() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this.globalService.token,
    });
  }

  async eliminarAlarmas() {
    try {      
      const notis = await LocalNotifications.getPending();
      notis.notifications = notis.notifications.filter(key=>key.id.length < 3)
      if (notis.notifications.length > 0)
      await LocalNotifications.cancel(notis);
    } catch (error) {
      
    }
  }

  async eliminarAlarmaAutomatica(id) {
    const notis = await LocalNotifications.getPending();    
    const idEliminar = notis.notifications.find(key => key.id.length > 3);
    if (!!idEliminar) {
      await LocalNotifications.cancel({ notifications: [{ id: idEliminar.id }] });
    }
  }

   ordenarArray(data) {
    
  
    const fecha = new Date()
    const dia = fecha.getDay();
    switch (dia) {
      case 1:
        data.alarmas[0].alarmas.map(key => {
          key.posicion = 0;
        });
        data.alarmas[1].alarmas.map(key => {
          key.posicion = 1;
        });
        data.alarmas[2].alarmas.map(key => {
          key.posicion = 2;
        });
        data.alarmas[3].alarmas.map(key => {
          key.posicion = 3;
        });
        data.alarmas[4].alarmas.map(key => {
          key.posicion = 4;
        });
        data.alarmas[5].alarmas.map(key => {
          key.posicion = 5;
        });
        data.alarmas[6].alarmas.map(key => {
          key.posicion = 6;
        });
        return data.alarmas[0].alarmas.concat(data.alarmas[1].alarmas).concat(data.alarmas[2].alarmas)
          .concat(data.alarmas[3].alarmas).concat(data.alarmas[4].alarmas).concat(data.alarmas[5].alarmas).concat(data.alarmas[6].alarmas);
      case 2:
        data.alarmas[1].alarmas.map(key => {
          key.posicion = 0;
        });
        data.alarmas[2].alarmas.map(key => {
          key.posicion = 1;
        });
        data.alarmas[3].alarmas.map(key => {
          key.posicion = 2;
        });
        data.alarmas[4].alarmas.map(key => {
          key.posicion = 3;
        });
        data.alarmas[5].alarmas.map(key => {
          key.posicion = 4;
        });
        data.alarmas[6].alarmas.map(key => {
          key.posicion = 5;
        });
        data.alarmas[0].alarmas.map(key => {
          key.posicion = 6;
        });
        return data.alarmas[1].alarmas.concat(data.alarmas[2].alarmas).concat(data.alarmas[3].alarmas)
          .concat(data.alarmas[4].alarmas).concat(data.alarmas[5].alarmas).concat(data.alarmas[6].alarmas)
          .concat(data.alarmas[0].alarmas);
      case 3:
        data.alarmas[2].alarmas.map(key => {
          key.posicion = 0;
        });
        data.alarmas[3].alarmas.map(key => {
          key.posicion = 1;
        });
        data.alarmas[4].alarmas.map(key => {
          key.posicion = 2;
        });
        data.alarmas[5].alarmas.map(key => {
          key.posicion = 3;
        });
        data.alarmas[6].alarmas.map(key => {
          key.posicion = 4;
        });
        data.alarmas[0].alarmas.map(key => {
          key.posicion = 5;
        });
        data.alarmas[1].alarmas.map(key => {
          key.posicion = 6;
        });
        return data.alarmas[2].alarmas.concat(data.alarmas[3].alarmas).concat(data.alarmas[4].alarmas)
          .concat(data.alarmas[5].alarmas).concat(data.alarmas[6].alarmas).concat(data.alarmas[0].alarmas)
          .concat(data.alarmas[1].alarmas);
      case 4:
        data.alarmas[3].alarmas.map(key => {
          key.posicion = 0;
        });
        data.alarmas[4].alarmas.map(key => {
          key.posicion = 1;
        });
        data.alarmas[5].alarmas.map(key => {
          key.posicion = 2;
        });
        data.alarmas[6].alarmas.map(key => {
          key.posicion = 3;
        });
        data.alarmas[0].alarmas.map(key => {
          key.posicion = 4;
        });
        data.alarmas[1].alarmas.map(key => {
          key.posicion = 5;
        });
        data.alarmas[2].alarmas.map(key => {
          key.posicion = 6;
        });
        return data.alarmas[3].alarmas.concat(data.alarmas[4].alarmas).concat(data.alarmas[5].alarmas)
          .concat(data.alarmas[6].alarmas).concat(data.alarmas[0].alarmas).concat(data.alarmas[1].alarmas)
          .concat(data.alarmas[2].alarmas);
      case 5:
        data.alarmas[4].alarmas.map(key => {
          key.posicion = 0;
        });
        data.alarmas[5].alarmas.map(key => {
          key.posicion = 1;
        });
        data.alarmas[6].alarmas.map(key => {
          key.posicion = 2;
        });
        data.alarmas[0].alarmas.map(key => {
          key.posicion = 3;
        });
        data.alarmas[1].alarmas.map(key => {
          key.posicion = 4;
        });
        data.alarmas[2].alarmas.map(key => {
          key.posicion = 5;
        });
        data.alarmas[3].alarmas.map(key => {
          key.posicion = 6;
        });
        return data.alarmas[4].alarmas.concat(data.alarmas[5].alarmas).concat(data.alarmas[6].alarmas)
          .concat(data.alarmas[0].alarmas).concat(data.alarmas[1].alarmas).concat(data.alarmas[2].alarmas)
          .concat(data.alarmas[3].alarmas);
      case 6:
        data.alarmas[5].alarmas.map(key => {
          key.posicion = 0;
        });
        data.alarmas[6].alarmas.map(key => {
          key.posicion = 1;
        });
        data.alarmas[0].alarmas.map(key => {
          key.posicion = 2;
        });
        data.alarmas[1].alarmas.map(key => {
          key.posicion = 3;
        });
        data.alarmas[2].alarmas.map(key => {
          key.posicion = 4;
        });
        data.alarmas[3].alarmas.map(key => {
          key.posicion = 5;
        });
        data.alarmas[4].alarmas.map(key => {
          key.posicion = 6;
        });
        return data.alarmas[5].alarmas.concat(data.alarmas[6].alarmas).concat(data.alarmas[0].alarmas)
          .concat(data.alarmas[1].alarmas).concat(data.alarmas[2].alarmas).concat(data.alarmas[3].alarmas)
          .concat(data.alarmas[4].alarmas);
      case 7:
        data.alarmas[6].alarmas.map(key => {
          key.posicion = 0;
        });
        data.alarmas[0].alarmas.map(key => {
          key.posicion = 1;
        });
        data.alarmas[1].alarmas.map(key => {
          key.posicion = 2;
        });
        data.alarmas[2].alarmas.map(key => {
          key.posicion = 3;
        });
        data.alarmas[3].alarmas.map(key => {
          key.posicion = 4;
        });
        data.alarmas[4].alarmas.map(key => {
          key.posicion = 5;
        });
        data.alarmas[5].alarmas.map(key => {
          key.posicion = 6;
        });
        return data.alarmas[6].alarmas.concat(data.alarmas[0].alarmas).concat(data.alarmas[1].alarmas)
          .concat(data.alarmas[2].alarmas).concat(data.alarmas[3].alarmas).concat(data.alarmas[4].alarmas)
          .concat(data.alarmas[5].alarmas);
    }
  }

  async crearAlarmasDeTodaLaSemana(alarmas: any[]) { 
    if(Capacitor.isNativePlatform()){
      const notifications: any[] = [];
      let contador = 0;
      if(!this.chanel){
        this.chanel = {
          id: '12',
          name: 'nivinAlarmas',
          importance: 4,
          visibility: 1,
          vibration: true,
          sound:'recordatorio2'
        };
      }
      const chanels = await LocalNotifications.listChannels();
      if(chanels.channels.length == 0) await LocalNotifications.createChannel(this.chanel);
      if (this.globalService.empresaSeleccionada.alarmasActivas) {
        const diaHora = this.recogerDia();
        for (const item of alarmas) {
          contador++;
          const fecha = new Date(Date.now());
          if (item.posicion != 0) {
            fecha.setDate(fecha.getDate() + item.posicion);
          }
  
          const hora = item.hora.split(':')[0];
          const minutos = item.hora.split(':')[1];
          const cadena = `${hora}:${minutos}`;
          if (item.diaSemana == diaHora.dia && cadena < diaHora.hora) {
            fecha.setHours(hora);
            fecha.setMinutes(minutos);
            fecha.setSeconds(0);
            if (fecha > new Date(Date.now())) {
  
              let noti: any = {
                title: item.recordatorio,
                body: 'Nivin te recuerda que tienes que fichar',
                id: contador,
                schedule: { at: fecha },
                attachments: null,
                actionTypeId: "",
                extra: null,
                channelId: '12',
                
              }
  
              notifications.push(noti)
  
            }
          } else {
            fecha.setHours(hora);
            fecha.setMinutes(minutos);
            fecha.setSeconds(0);
            if (fecha > new Date(Date.now())) {
              let noti: any = {
                title: item.recordatorio,
                body: this.montarTexto(item.recordatorio),
                id: contador,
                schedule: { at: fecha },
                sound: this.globalService.sonido,
                attachments: null,
                actionTypeId: "",
                extra: null,
                channelId: '12'
              }
              notifications.push(noti)
            }
          }
        }
        return await LocalNotifications.schedule({ notifications });
      }
    }


  }

  montarTexto(texto){
    switch (texto) {
      case 'Entrada':
        return `Nivin te recuerda que debes iniciar jornada`;
        case 'Salida':
        return `Nivin te recuerda que debes finalizar jornada`;
      default:
        return `Nivin te recuerda que tienes que fichar ${texto}`;
    }
  }

  recogerDia() {
    const fecha = new Date(Date.now());
    const dia = fecha.getDay();
    const hora = fecha.getHours();
    const minutos = fecha.getMinutes();
    const cadena = `${hora}:${minutos}`
    return { dia, hora: cadena };
  }


  obtenerAlarmas(): Promise<{ ok: true, alarmas: any[] }> {
    return new Promise(async (resolve, reject) => {
      this.http.get(`${this.apiRest}/alarmas/obtenerAlarmas`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  crearAlarma(alarmaInfo: any) {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${this.apiRest}/alarmas/crearAlarma`, alarmaInfo, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  modificarAlarma(id: number, alarmaMod: any) {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${this.apiRest}/alarmas/modificarAlarma/${id}`, alarmaMod, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  eliminarAlarma(id: number) {
    return new Promise(async (resolve, reject) => {
      this.http.get(`${this.apiRest}/alarmas/eliminarAlarma/${id}`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  setDuracionAlarmaEmpleado(id: number,duracion) {
    return new Promise(async (resolve, reject) => {
      this.http.get(`${this.apiRest}/alarmas/setDuracionAlarmaEmpleado/${duracion}/${id}`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  obtenerTiposFichajeDuracionEmpleado() {
    return new Promise(async (resolve, reject) => {
      this.http.get(`${this.apiRest}/alarmas/obtenerTiposFichajeDuracionEmpleado`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  cambiarEstadoAlarma(id: number) {
    return new Promise(async (resolve, reject) => {
      this.http.get(`${this.apiRest}/alarmas/cambiarEstadoAlarma/${id}`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  cambiarEstadoAlarmasActivas() {
    return new Promise(async (resolve, reject) => {
      this.http.get(`${this.apiRest}/usuarios/cambiarEstadoAlarmasActivas`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  cambiarRecordatoriosAutomaticos() {
    return new Promise(async (resolve, reject) => {
      this.http.get(`${this.apiRest}/usuarios/cambiarRecordatoriosAutomaticos`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

  cambiarTiempoAntelacionRecoAuto(tiempo: number): Promise<{ ok: boolean, usuario: any, tiposFichajeConDuracion: any[] }> {
    return new Promise(async (resolve, reject) => {
      this.http.get(`${this.apiRest}/usuarios/cambiarTiempoAntelacionRecoAuto/${tiempo}`, { headers: await this.ponerEncabezados() })
        .subscribe((resp: any) => {
          resolve(resp);
        }, (error) => {
          this.errorService.getError(error).then((tryAgain: boolean) => {
            reject(tryAgain);
          });
        });
    })
  }

}
