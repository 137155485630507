import { Injectable } from "@angular/core";
import { OpenNativeSettings } from "@ionic-native/open-native-settings/ngx";
import { AlertController, ToastController,Platform } from "@ionic/angular";
import * as moment from "moment";

@Injectable({
  providedIn: "root",
})
export class UiService {
  colorAplicacion: string = "primary";
  menu: any;
  itemsCalendar: any;
  items: any;
  avatares: any;
  modal: any = null;
  modalOverModal: any = null;
  tituloTiempo: string = "";
  tareas:any[]=[];

  colorAplicacionSecond: string = "secondary";
  constructor(
    private alertController: AlertController,
    private toastController: ToastController,
    private platform:Platform,
    private openNativeSettings:OpenNativeSettings
  ) {}

  async alertaInformativa(message: string) {
    const alert = await this.alertController.create({
      message,
      mode: "ios",
      buttons: [
        { text: "Ok", cssClass: `alert-button-${this.colorAplicacion}` },
      ],
    });

    await alert.present();
  }

  cerrarAlert() {
    this.alertController.dismiss();
  }

  async presentToast(
    message: string,
    position: "top" | "bottom" | "middle",
    duration: number
  ) {
    const toast = await this.toastController.create({
      message,
      position: position,
      duration,
    });
    toast.present();
  }

  devolverCodigoColor(color) {
    switch (color) {
      case "verde":
        return "#02af9e";
      case "verdeAlt":
        return "#2f6f79";
      case "dark":
        return "#222428";
      case "medium":
        return "#92949c";
      case "warning":
        return "#ffc409";
      case "taronja":
        return "#ff8800";
      case "danger":
        return "#ff1200";
      case "primary":
        return "#3dc2ff";
      case "success":
        return "#2dd36f";
      case "fucsia":
        return "#e198df";

      default:
        break;
    }
  }

  devolverCodigoSecundario(color) {
    switch (color) {
      case "verde":
        return "#02af9e";
      case "verdeAlt":
        return "#2f6f79";
      case "dark":
        return "#222428";
      case "medium":
        return "#92949c";
      case "warningAlt":
        return "#d6a400";
      case "taronjaAlt":
        return "#d67200";
      case "dangerAlt":
        return "#b80c00";
      case "secondary":
        return "#1579a8";
      case "successAlt":
        return "#108940";
      case "fucsiaAlt":
        return "#944791";

      default:
        break;
    }
  }

  secondColor(color) {
    switch (color) {
      case "verde":
        return (this.colorAplicacionSecond = "verdeAlt");
      case "verdeAlt":
        return (this.colorAplicacionSecond = "verde");
      case "dark":
        return (this.colorAplicacionSecond = "medium");
      case "medium":
        return (this.colorAplicacionSecond = "dark");
      case "warning":
        return (this.colorAplicacionSecond = "warningAlt");
      case "taronja":
        return (this.colorAplicacionSecond = "taronjaAlt");
      case "danger":
        return (this.colorAplicacionSecond = "dangerAlt");
      case "primary":
        return (this.colorAplicacionSecond = "secondary");
      case "success":
        return (this.colorAplicacionSecond = "successAlt");
      case "fucsia":
        return (this.colorAplicacionSecond = "fucsiaAlt");

      default:
        break;
    }
  }

  claseObligatoria(color) {
    switch (color) {
      case "verde":
        return "oblig-verde";

      case "dark":
        return "oblig-dark";
      case "medium":
        return "oblig-medium";
      case "warning":
        return "oblig-warning";
      case "taronja":
        return "oblig-taronja";

      case "primary":
        return "oblig-primary";
      case "success":
        return "oblig-success";
      case "fucsia":
        return "oblig-fucsia";

      case "secondary":
        return "oblig-secondary";

      default:
        return "oblig-primary";
    }
  }

  setearEstiloMenu(menu, items) {
    if (menu == undefined) {
      return;
    }
    if (menu.el == undefined) {
      menu.style.cssText = `--offset-top:0px; --offset-bottom:0px; --background:linear-gradient(to right,${this.devolverCodigoColor(
        this.colorAplicacion
      )}, ${this.devolverCodigoSecundario(this.colorAplicacionSecond)});`;
      return;
    }
    menu.el.style.setProperty(
      "--background",
      `linear-gradient(to right,${this.devolverCodigoColor(
        this.colorAplicacion
      )},
       ${this.devolverCodigoSecundario(this.colorAplicacionSecond)})`
    );
    for (let i = 0; i < items.length; i++) {
      const item: any = items[i];
      item.style.setProperty(
        "--background",
        `linear-gradient(to right,${this.devolverCodigoColor(
          this.colorAplicacion
        )},
       ${this.devolverCodigoSecundario(this.colorAplicacionSecond)})`
      );
    }
  }

  devolverInicialSemanaIndex(fecha:Date) {
    return moment(fecha).format('dddd').substr(0,1).toUpperCase() +moment(fecha).format('dddd').substr(1,2) ;
  }

  devolverInicialSemana(dia: string) {
    switch (dia) {
      case "Lun":
        return "L";
      case "Mar":
        return "M";
      case "Mié":
        return "X";
      case "Jue":
        return "J";
      case "Vie":
        return "V";
      case "Sáb":
        return "S";
      case "Dom":
        return "D";

      default:
        break;
    }
  }

  devolverInicialSemanaNumber(dia: number) {
    switch (dia) {
      case 1:
        return "L";
      case 2:
        return "M";
      case 3:
        return "X";
      case 4:
        return "J";
      case 5:
        return "V";
      case 6:
        return "S";
      case 0:
        return "D";

      default:
        break;
    }
  }

  devolverDiaSemanaNumber(dia: number) {
    switch (dia) {
      case 1:
        return "Lunes";
      case 2:
        return "Martes";
      case 3:
        return "Mier.";
      case 4:
        return "Jueves";
      case 5:
        return "Viernes";
      case 6:
        return "Sábado";
      case 0:
        return "Domingo";

      default:
        break;
    }
  }

  devolverInicialMes(mes: number) {
    switch (mes) {
      case 1:
        return "Ene.";
      case 2:
        return "Feb.";
      case 3:
        return "Mar.";
      case 4:
        return "Abr.";
      case 5:
        return "May.";
      case 6:
        return "Jun.";
      case 7:
        return "Jul.";
      case 8:
        return "Ago.";
      case 9:
        return "Sep.";
      case 10:
        return "Oct.";
      case 11:
        return "Nov.";
      case 12:
        return "Dic.";

      default:
        break;
    }
  }


  aplicarEstiloMenu(){
    this.menu.el.style.setProperty(
      "--background",
      `linear-gradient(to right,${this.devolverCodigoColor(
        this.colorAplicacion
      )},
       ${this.devolverCodigoSecundario(
         this.colorAplicacionSecond
       )})`
    );
    for (let i = 0; i < this.items.length; i++) {
      const item: any = this.items[i];
      item.style.setProperty(
        "--background",
        `linear-gradient(to right,${this.devolverCodigoColor(
          this.colorAplicacion
        )},
       ${this.devolverCodigoSecundario(
         this.colorAplicacionSecond
       )})`
      );
    }
  }

  async abrirAjustes() {
    if (this.platform.is("android")) {

          const alert = await this.alertController.create({
            cssClass: "my-custom-class",
            header: "Ir ajustes",
            message: `Debe conceder permiso a Nivin para utilizar su ubicación, dirijase a ajustes->aplicaciones->NiviN->permisos`,
            buttons: [
              {
                text: "Cancelar",
                role: "cancel",
                cssClass: "secondary",
                handler: () => {
                  this.alertController.dismiss();
                },
              },
              {
                text: "Ir",
                handler: () => {
                  this.openNativeSettings.open("application_details").then(val => {});
                },
              },
            ],
          });
          await alert.present();
    }
  }

  minsToHHmm(mins: any): string {
    if (mins == -1) return "-";
    if (mins == 0) {
      return `0`;
    }
    if (mins < 0) {
      mins = Math.abs(mins);
      let HH: number = Math.trunc(mins / 60);
      let mm: number = mins % 60;
      return `-${HH < 10 ? `0${HH}` : HH}:${mm < 10 ? `0${mm}` : mm} `;
    }
    let HH: number = Math.trunc(mins / 60);
    let mm: number = mins % 60;
    return `${HH < 10 ? `0${HH}` : HH}:${mm < 10 ? `0${mm}` : mm} `;
  }
}
