import { Component, HostListener, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AlertController, ModalController, Platform } from '@ionic/angular';
import { UiService } from 'src/app/api/ui.service';
import { BackButtonService } from 'src/app/api/back-button.service';
import { GlobalService } from 'src/app/services/global.service';
import { ApiService } from 'src/app/api/api.service';

@Component({
  selector: 'app-modal-departamento',
  templateUrl: './modal-departamento.page.html',
  styleUrls: ['./modal-departamento.page.scss'],
})
export class ModalDepartamentoPage implements OnInit {

  @Input() departamento: any = null;
  textoNombreDepartamento: string;
  formDepartamento = new FormGroup({
    nombre: new FormControl(null, Validators.required)
  });
  formOriginal: any;
  formNuevo: any;
  formNuevoMod: boolean = true;

  constructor(private apiService: ApiService,
    private modalController: ModalController,
    public uiServe: UiService,
    private alertController: AlertController,
    private backButtonServe: BackButtonService,
    private platform: Platform,
    private globalService: GlobalService) { }

  async ngOnInit() {

    const modalState = {
      modal: true,
      desc: 'fake state for our modal'
    };
    history.pushState(modalState, null);

    if (this.departamento != null) {
      this.textoNombreDepartamento = "Departamento";
      this.formDepartamento.patchValue(this.departamento);
      this.formOriginal = this.formDepartamento.value;
      this.detectarCambioEnForm();
    } else {
      this.textoNombreDepartamento = "Añadir departamento";
      this.formOriginal = this.formDepartamento.value;
      this.detectarCambioEnForm();
    }
  }
  colorObligatorio() {
    return this.uiServe.claseObligatoria(this.uiServe.colorAplicacion);
  }

  ionViewDidEnter() {
    this.backButtonServe.subscription = this.platform.backButton.subscribeWithPriority(5, () => {
      this.modalController.dismiss();
      this.backButtonServe.subscription.unsubscribe();
    });
  }

  detectarCambioEnForm() {
    this.formDepartamento.valueChanges.subscribe(() => {
      this.formNuevo = this.formDepartamento.value;
      for (const clave in this.formNuevo) {
        this.formNuevo[clave].length == 0 ? this.formNuevo[clave] = null : this.formNuevo[clave]
      }
      this.formNuevoMod = this.compareObjects(this.formNuevo, this.formOriginal);
    });
  }

  compareObjects(obj1: any, obj2: any) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  async guardarModificarDepartamento(form: FormGroup) {
    if (this.departamento != null) {
      let nuevoDepartamento = {
        id: this.departamento.id,
        nombre: form.value.nombre
      }
      if(!this.apiService.connected)return this.uiServe.alertaInformativa(`No tiene conexión a Internet, intentelo más tarde`);
      this.apiService.modificarDepartamento(nuevoDepartamento).then((data) => {
        this.uiServe.alertaInformativa('Departamento guardado correctamente');
        this.formNuevoMod = true;
        this.formOriginal = form.value;
      }, (tryAgain) => {
        if (tryAgain) {
          this.guardarModificarDepartamento(form);
        } else {
          this.uiServe.alertaInformativa('No se ha podido guardar el departamento, intentalo de nuevo mas tarde.');
        }
      });
    } else {
      if(!this.apiService.connected)return this.uiServe.alertaInformativa(`No tiene conexión a Internet, intentelo más tarde`);
      this.apiService.crearDepartamento({idEmpresa: this.globalService.empresaSeleccionada.id,nombre: form.value.nombre}).then((data) => {
        this.uiServe.alertaInformativa('Departamento guardado correctamente.');
        this.modalController.dismiss({ id: data.departamento.id, nombre: data.departamento.nombre });
      },(tryAgain) => {
        if (tryAgain) {
          this.guardarModificarDepartamento(form);
        } else {
          this.modalController.dismiss();
          this.uiServe.alertaInformativa('No se ha podido crear el departamento, intentalo de nuevo mas tarde.');
        }
      });
    }
  }

  ngOnDestroy() {
    if (window.history.state.modal) {history.back()}
  }

  @HostListener('window:popstate', ['$event'])
  cerrar() {
    if (this.formNuevoMod) {
      this.modalController.dismiss();
    } else {
      this.cerrarConAlerta();
    }
  }

  async cerrarConAlerta() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: '¿Guardar cambios?',
      message: `Has realizado cambios en el departamento, ¿deseas guardarlos?`,
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            this.modalController.dismiss();
          }
        }, {
          text: 'Sí',
          handler: () => {
            this.guardarModificarDepartamento(this.formDepartamento);
          }
        }
      ]
    });
    await alert.present();
  }

}
